/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { WysiwygText } from './WysiwygText';
import { SectionHeading } from './SectionHeading';
import { container, subContainer } from '../composableStyles/layout';
import {
  fluidHeading45,
  fluidHeading55,
  fluidHeading110,
} from '../composableStyles/text';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { marginBottom } from '../composableStyles/marginBottom';
import { heroRevealAnimation } from '../composableStyles/heroRevealAnimation';

const {
  surtitleReveal,
  titleReveal,
} = heroRevealAnimation;

const textStyling = {
  regular: theme => fluidHeading45(theme),
  blog: theme => fluidHeading55(theme),
  large: theme => fluidHeading110(theme),
};

// Note: surtitle is a bit of a hack to handle the date for blogs
// it can't really be used for other things due to constrained styling

export const HeroText = ({
  title, surtitle, text, size, spaceBelow,
}) => {
  const theme = useTheme();
  const isBlog = size === 'blog';
  const numCols = isBlog ? 18 : 22;

  const innerMaxWidthStyles = isBlog
    ? css`max-width: 100%;`
    : css`
      max-width: ${theme.layout.maxWidthCols(16)};

      ${theme.bp.wide} {
        max-width: ${(theme.layout.maxWidthColsPx(17) / theme.layout.maxWidthColsPx(24)) * 100}%;
      }
    `;

  const subtitleWideLeft = (100 - (
    (theme.layout.maxWidthColsPx(numCols) / theme.layout.maxWidthColsPx(24)) * 100
  )) / 2;

  return (
    <header
      css={[
        marginBottom(theme, spaceBelow),
        css`
          padding-top: ${theme.spacer(6)};

          ${theme.bp.tablet} {
            padding-top: ${theme.spacer(8.75)};
          }
        `,
      ]}
    >
      <h1
        css={[
          container(theme),
        ]}
      >
        <SectionHeading
          level="span"
          css={[
            css`
              margin-bottom: ${theme.scaledVerticalSpacing(50)};
            `,
            surtitleReveal,
          ]}
        >
          {title}
          {surtitle && (
            <span
              css={css`
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                color: ${theme.colors.grey};
                animation
                
                ${theme.bp.min(500)} {
                  right: auto;
                  left: ${theme.pxToRem(110)}rem;
                }

                ${theme.bp.tablet} {
                  left: max(${theme.pxToRem(171)}rem, ${subtitleWideLeft}%);
                }
              `}
            >
              {surtitle}
            </span>
          )}
        </SectionHeading>
        <WysiwygText
          {...{ text }}
          css={[
            subContainer(theme, numCols),
            css`
              color: ${theme.colors.black};
              text-wrap: balance;
  
              & > * {
               margin-bottom: 0;
               ${innerMaxWidthStyles}
              }
  
              & a {
                background-position: 0 1.09em;
                color: ${theme.colors.black};
              }
            `,
            textStyling[size](theme),
            titleReveal,
          ]}
        />
      </h1>
    </header>
  );
};

HeroText.propTypes = {
  title: PropTypes.string,
  surtitle: PropTypes.string,
  text: PropTypes.node,
  size: PropTypes.oneOf(Object.keys(textStyling)),
  spaceBelow: PropTypes.string,
};

HeroText.defaultProps = {
  title: '',
  surtitle: '',
  text: '',
  size: Object.keys(textStyling)[0],
  spaceBelow: spaceBelowDefaultValue,
};
