/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { fluidHeading130 } from '../composableStyles/text';
import { HomePageHeroHeadline } from './HomePageHeroHeadline';
import {
  ENTER_STATE, EXIT_STATE, HEADLINE_ONE_ENTER_STEP, HEADLINE_ONE_EXIT_STEP, INITIAL_STATE,
} from '../utilities/constants';

export const HomePageHeroHeadlines = ({
  headlineOne, headlineTwo, step, ...otherProps
}) => {
  const theme = useTheme();

  let headlineOneAnimationState = INITIAL_STATE;

  if (step === HEADLINE_ONE_ENTER_STEP) {
    headlineOneAnimationState = ENTER_STATE;
  } else if (step > HEADLINE_ONE_ENTER_STEP) {
    headlineOneAnimationState = EXIT_STATE;
  }

  return (
    <h1
      css={[
        css`
          color: ${theme.colors.white};
          position: relative;
        `,
        fluidHeading130(theme),
      ]}
      {...otherProps}
    >
      <HomePageHeroHeadline
        headline={headlineOne}
        animatingState={headlineOneAnimationState}
        css={css`        
          --headline-enter-delay: 0ms;

          display: block;
        `}
      />
      <HomePageHeroHeadline
        headline={headlineTwo}
        animatingState={step >= HEADLINE_ONE_EXIT_STEP ? ENTER_STATE : INITIAL_STATE}
        css={css`
          --headline-enter-delay: var(--headline-duration);

          position: absolute;
          top: 0;
          left: 0;
          display: block;
        `}
      />
    </h1>
  );
};

HomePageHeroHeadlines.propTypes = {
  headlineOne: PropTypes.string.isRequired,
  headlineTwo: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
};
