/** @jsx jsx */
import {
  jsx, css, useTheme, keyframes,
} from '@emotion/react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Dialog } from '@reach/dialog';
import { VWLink } from './VWLink';
import { container } from '../composableStyles/layout';
import { SocialLinks } from './SocialLinks';
import { ContactLinks } from './ContactLinks';
import { HeaderPrimaryNav } from './HeaderPrimaryNav';
import { fluidHeading14, widestScaleFactor } from '../composableStyles/text';
import { defaultLinkStyles } from '../composableStyles/defaultLinkStyles';
import { fluidScale } from '../composableStyles/fluidScale';
import {
  WIDEST_BP, WIDE_BP, desktopDesignWidthPx, gridPX,
} from '../composableStyles/theme';
import { HeaderBanner } from './HeaderBanner';
import { pseudoDecoration } from '../composableStyles/pseudoDecoration';
import { easeOutQuart } from '../composableStyles/easings';
import { fadeIn, fadeOut } from '../composableStyles/keyframes';

export const timingMs = {
  grey: {
    open: {
      duration: 450,
      delay: 0,
    },
    close: {
      duration: 200,
      delay: 250,
    },
  },
  white: {
    open: {
      duration: 450,
      delay: 200,
    },
    close: {
      duration: 200,
      delay: 150,
    },
  },
  primaryContent: {
    open: {
      duration: 350,
      delay: 450,
    },
    close: {
      duration: 250,
      delay: 0,
    },
  },
  secondaryContent: {
    open: {
      duration: 350,
      delay: 575,
    },
    close: {
      duration: 250,
      delay: 0,
    },
  },
};

const clipPathOpen = keyframes`
  from {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }

  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
`;

const clipPathClose = keyframes`
  from {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  
  to {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
`;

const dropIn = keyframes`
  from {
    transform: translateY(-40px);
  }

  to {
    transform: translateY(0);
  }
`;

const dropOut = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-40px);
  }
`;

const StyledContactSection = styled.section`
  &:last-of-type {
    flex: 0 0 33.5%;
  }
  
  ${props => props.theme.bp.tablet} {
    margin-top: ${props => props.theme.spacer(3)};

    &:first-of-type {
      ${props => props.theme.bp.tablet} {
        margin-top: 0;
      }
    }
  }
`;

const StyledContactHeading = styled.h2`
  margin-bottom: ${props => props.theme.spacer(0.4)};
  ${props => fluidHeading14(props.theme)}
`;

const StyledContactLi = styled.li`
  margin-bottom: ${props => props.theme.spacer(0.25)};
`;

const StyledContactLink = styled(VWLink)`
  color: ${props => props.theme.colors.greyDark};
  letter-spacing: ${0.2 / 13}em;
  line-height: ${22 / 13};
  ${fluidScale({
    minPx: 13, maxPx: 13 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  ${props => defaultLinkStyles(props.theme, true)}
`;

export const HeaderMenuDialog = ({
  isOpen, isClosing, handleMenuClick, onDismiss, ...otherProps
}) => {
  const theme = useTheme();

  const greyBackgroundAnimation = isClosing
    ? css`animation: ${clipPathClose} ${timingMs.grey.close.duration}ms ease-in ${timingMs.grey.close.delay}ms 1 both;`
    : css`animation: ${clipPathOpen} ${timingMs.grey.open.duration}ms ${easeOutQuart} ${timingMs.grey.open.delay}ms 1 both;`;
  const whiteBackgroundAnimation = isClosing
    ? css`animation: ${slideUp} ${timingMs.white.close.duration}ms ease-in ${timingMs.white.close.delay}ms 1 both;`
    : css`animation: ${slideDown} ${timingMs.white.open.duration}ms ${easeOutQuart} ${timingMs.white.open.delay}ms 1 both;`;
  const primaryContentAnimation = isClosing
    ? css`
        animation: ${fadeOut} ${timingMs.primaryContent.close.duration}ms linear ${timingMs.primaryContent.close.delay}ms 1 both, 
          ${dropOut} ${timingMs.primaryContent.close.duration}ms ease-in ${timingMs.primaryContent.close.delay}ms 1 both;
      `
    : css`
        animation: ${fadeIn} ${timingMs.primaryContent.open.duration}ms linear ${timingMs.primaryContent.open.delay}ms 1 both,
          ${dropIn} ${timingMs.primaryContent.open.duration}ms ${easeOutQuart} ${timingMs.primaryContent.open.delay}ms 1 both;
      `;
  const secondaryContentAnimation = isClosing
    ? css`
        animation: ${fadeOut} ${timingMs.secondaryContent.close.duration}ms linear ${timingMs.secondaryContent.close.delay}ms 1 both,
          ${dropOut} ${timingMs.secondaryContent.close.duration}ms ease-in ${timingMs.secondaryContent.close.delay}ms 1 both;
      `
    : css`
        animation: ${fadeIn} ${timingMs.secondaryContent.open.duration}ms linear ${timingMs.secondaryContent.open.delay}ms 1 both,
          ${dropIn} ${timingMs.secondaryContent.open.duration}ms ${easeOutQuart} ${timingMs.secondaryContent.open.delay}ms 1 both;
      `;

  return (
    <Dialog
      {...{ isOpen, onDismiss }}
      css={css`
        position: fixed;
        z-index: ${theme.zIndex.header.banner};
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${theme.colors.greyMurray};
        ${greyBackgroundAnimation}

        &::after {
          ${pseudoDecoration()}
          z-index: ${theme.zIndex.header.dialog + 2};
          color: ${theme.colors.white};
          pointer-events: none;
          ${whiteBackgroundAnimation}
        }
      `}
      {...otherProps}
    >
      <div
        css={[
          container(theme, 16),
          css`
            position: relative;
            z-index:  ${theme.zIndex.header.dialog + 3};
            pointer-events: all;
            height: 100%;
            overflow: auto;
          `,
        ]}
      >
        <div
          css={[
            css`
            min-height: 100%;
            padding-top: ${theme.spacer(4.5)};
            padding-bottom: ${theme.spacer(2.5)};
            padding-left: ${theme.pxToRem(29 - 15)}rem;
            padding-right: ${theme.pxToRem(29 - 15)}rem;
            pointer-events: all;

            ${theme.bp.min(350)} {
              padding-top: ${theme.pxToRem(122)}rem;
            }

            ${theme.bp.tablet} {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-top: ${theme.spacer(4)};
              padding-bottom: ${theme.spacer(4)};
              padding-left: ${theme.spacer(3)};
              padding-right: ${theme.spacer(3)};
            }

            ${theme.bp.desktop} {
              padding-left: 0;
              padding-right: 0;
              }
          `,
          ]}
        >
          <HeaderPrimaryNav
            {...{ handleMenuClick }}
            css={primaryContentAnimation}
          />
          <address
            css={[
              css`
                display: flex;
                justify-content: space-between;
                margin-top: ${theme.pxToRem(57)}rem;

                ${theme.bp.tablet} {
                  display: block;
                  margin-top: -10%;
                }
              `,
              secondaryContentAnimation,
            ]}
          >
            <ContactLinks
              StyledSection={StyledContactSection}
              StyledHeading={StyledContactHeading}
              StyledLi={StyledContactLi}
              StyledLink={StyledContactLink}
            />
            <SocialLinks
              StyledSection={StyledContactSection}
              StyledHeading={StyledContactHeading}
              StyledLi={StyledContactLi}
              StyledLink={StyledContactLink}
            />
          </address>
        </div>
      </div>
      <HeaderBanner
        {...{ handleMenuClick }}
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          z-index:  ${theme.zIndex.header.dialog + 3};

          ${theme.bp.wide} {
            left: ${(gridPX.padding.desktop / desktopDesignWidthPx) * 100}%;
          }
        `}
        isCloseButton
      />
    </Dialog>
  );
};

HeaderMenuDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isClosing: PropTypes.bool.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};
