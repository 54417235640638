/** @jsx jsx */
import {
  jsx, css, keyframes, useTheme,
} from '@emotion/react';
import PropTypes from 'prop-types';
import { INTRO_ENTER_STEP } from '../utilities/constants';
import { easeOutQuart } from '../composableStyles/easings';
import { BulletButton } from './BulletButton';
import { vwLinkPropTypes } from './VWLink';

const initialOffset = '40px';

const buttonVertical = keyframes`
  from {
    transform: translateY(${initialOffset});
  }

  to {
    transform: translateY(0);
  }
`;

const buttonOpacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`;

export const HomePageHeroButton = ({
  button, step, ...otherProps
}) => {
  const theme = useTheme();

  const animation = step >= INTRO_ENTER_STEP
    ? css`
      animation: ${buttonVertical} var(--button-duration) ${easeOutQuart} var(--button-delay) 1 both,
        ${buttonOpacity} var(--button-duration) linear var(--button-delay) 1 both;
    `
    : null;

  return (
    <div
      css={[
        css`
          margin-top: ${20 / 17}em;
          opacity: 0;
          transform: translateY(0);
          
          ${theme.bp.tablet} {
            margin-top: ${23 / 18}em;
          }
        `,
        animation,
      ]}
      {...otherProps}
    >
      <BulletButton
        {...button}
        a11yText="about VentureWeb"
        css={css`
          &,
          &:hover {
            color: ${theme.colors.white};
          }
        `}
      />
    </div>
  );
};

HomePageHeroButton.propTypes = {
  button: PropTypes.oneOfType([
    PropTypes.shape(vwLinkPropTypes),
    PropTypes.bool,
  ]).isRequired,
  step: PropTypes.number.isRequired,
};
