import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';
import { VWLink } from './VWLink';
import { fluidHeading80 } from '../composableStyles/text';
import { defaultLinkStyles } from '../composableStyles/defaultLinkStyles';

const StyledNavLink = styled(VWLink)`
  color: ${props => props.theme.colors.black};
  ${props => fluidHeading80(props.theme)}
  ${props => defaultLinkStyles(props.theme)}
`;

const StyledNavLi = styled.li`
  &:not(:last-of-type) {
    margin-bottom: ${props => props.theme.spacer(1)};
    
    ${props => props.theme.bp.min(350)} {
      margin-bottom: ${props => props.theme.spacer(1.5)};
    }
  }
`;

export const HeaderPrimaryNav = ({ handleMenuClick, ...otherProps }) => {
  const queryData = useStaticQuery(graphql`
    {
      prismicPrimaryMenu {
        _previewable
        data {
          menu_items_group {
            link {
              url
            }
            label
          }
        }
      }
    }
  `);

  const { data } = useMergePrismicPreviewData(queryData);

  const linksData = data.prismicPrimaryMenu.data.menu_items_group;

  const links = linksData.map(({ link: { url }, label }) => ({ url, label }));

  return (
    <ul {...otherProps}>
      {links.map(({ label, url }) => (
        <StyledNavLi key={url}>
          <StyledNavLink
            {...{ url }}
            onClick={handleMenuClick}
          >
            {label}
          </StyledNavLink>
        </StyledNavLi>
      ))}
    </ul>
  );
};

HeaderPrimaryNav.propTypes = {
  handleMenuClick: PropTypes.func.isRequired,
};
