/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { VWImage } from './VWImage';
import { BulletButton } from './BulletButton';
import { fluidHeading28, text13 } from '../composableStyles/text';
import { VWLink } from './VWLink';
import { cardRevealAnimation } from '../composableStyles/cardRevealAnimation';
import { useComponentReveal } from '../hooks/useComponentReveal';
import { easeOutQuart } from '../composableStyles/easings';

export const BlogListCard = ({
  image,
  title,
  surtitle,
  url,
}) => {
  const theme = useTheme();
  const { ref, isRevealed } = useComponentReveal();

  const {
    imageReveal,
  } = cardRevealAnimation(isRevealed);

  const shrinkPercent = 2;
  const clipLower = `${shrinkPercent}%`;
  const clipUpper = `${100 - shrinkPercent}%`;
  const clipPath = `polygon(${clipLower} ${clipLower}, ${clipUpper} ${clipLower}, ${clipUpper} ${clipUpper}, ${clipLower} ${clipUpper})`;

  return (
    <li
      {...{ ref }}
      css={[
        css`
          margin-bottom: ${theme.scaledVerticalSpacing(70)};
          width: 100%;
        `,
        imageReveal,
      ]}
    >
      <VWLink
        {...{ url }}
        aria-label={`${RichText.asText(title)}, ${surtitle}`}
        css={css`
          --title-text-decoration-color: ${theme.colors.pink}00;
          pointer-events: none;

          ${theme.bp.tablet} {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          &:focus-visible {
            --card-image-scale: 1.1;
            --card-image-scale-transition: transform 300ms ${easeOutQuart} 0ms;
            --title-text-decoration-color: ${theme.colors.pink}FF;
            
            ${theme.bp.desktop} {
              --card-image-clip-path: ${clipPath};
              --card-image-clip-transition: clip-path 300ms ${easeOutQuart} 0ms;
            }
          }
        `}
      >
        <div
          css={[
            css`
              pointer-events: all;
              display: block;
              margin-bottom: ${theme.spacer()};
              transition: var(--card-image-clip-transition);
              clip-path: var(--card-image-clip-path);

              ${theme.bp.tablet} {
                flex: 0 0 59%;
                margin-bottom: 0;
              }
  
              & [data-main-image] {
                transition: var(--card-image-scale-transition);
                transform: translateZ(0) scale(var(--card-image-scale));
              }

              @media (hover: hover) {
                &:hover {
                  --card-image-scale: 1.1;
                  --card-image-scale-transition: transform 300ms ${easeOutQuart} 0ms;
                  
                  ${theme.bp.desktop} {
                    --card-image-clip-path: ${clipPath};
                    --card-image-clip-transition: clip-path 300ms ${easeOutQuart} 0ms;
                  }
                }
              }
            `,
          ]}
        >
          <VWImage {...{ image }} alt="" />
        </div>
        <div
          css={css`
            pointer-events: all;

            ${theme.bp.tablet} {
              flex: 0 0 35%;
            }
          `}
        >
          <span
            css={css`
              ${text13(theme)}
              display: block;
              color: ${theme.colors.grey};
              margin-bottom: ${theme.spacer(0.5)};

              ${theme.bp.tablet} {
                margin-bottom: ${theme.spacer()};
              }
            `}
          >
            {surtitle}
          </span>
          <h3
            css={[
              css`
                color: ${theme.colors.black};
                margin-bottom: ${10 / 22}em;
                text-decoration: underline;
                text-underline-offset: 0.28em;
                text-decoration-color: var(--title-text-decoration-color);
                transition: text-decoration-color 200ms ease;

                ${theme.bp.tablet} {
                  margin-bottom: ${20 / 28}em;
                }
              `,
              fluidHeading28(theme),
            ]}
          >
            {RichText.asText(title)}
          </h3>
          <BulletButton
            label="Read this"
            isFaux
          />
        </div>
      </VWLink>
    </li>
  );
};

BlogListCard.propTypes = {
  image: PropTypes.shape({}).isRequired,
  title: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  surtitle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
