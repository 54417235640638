/* eslint react/prop-types: off */
import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { SliceZone } from '../components/SliceZone';
import { HeroText } from '../components/HeroText';
import { HeroHalfImage } from '../components/HeroHalfImage';
import { htmlSerializer } from '../utilities/htmlSerializer';
import { mapDecorationValues } from '../utilities/mapDecorationValues';

const UpdatesList = ({ data }) => {
  if (!data) return null;
  const {
    prismicUpdatesPage: {
      data: {
        surtitle: { text: title },
        title: { richText },
        hero_style: heroStyle,
        main_hero_image: image,
        space_below: spaceBelow,
        dotted_background: dottedBackground,
        body,
      },
    },
    allPrismicNewsPost: { edges },
  } = data;

  const HeroComponent = heroStyle === 'Text with Image' ? HeroHalfImage : HeroText;

  const blogListProps = edges.map(
    ({
      node: {
        url,
        data: {
          title: { richText: textNodes },
          post_date: surtitle,
          main_hero_image: {
            thumbnails: { card },
            alt,
          },
        },
      },
    }) => ({
      url,
      surtitle,
      title: textNodes,
      image: { ...card, alt },
    }),
  );

  const bodyWithNewsPosts = body.map(item => {
    const { slice_type: sliceType } = item;
    if (sliceType !== 'full_updates_list') {
      return item;
    }

    return {
      ...item,
      items: blogListProps,
    };
  });

  return (
    <Layout>
      <SEO {...{ title }} />
      <HeroComponent
        {...{ title, image, spaceBelow }}
        text={<RichText render={richText} {...{ htmlSerializer }} />}
        size={heroStyle === 'Large Text' ? 'large' : 'regular'}
        decoration={mapDecorationValues(dottedBackground)}
      />
      <SliceZone sliceZone={bodyWithNewsPosts} />
    </Layout>
  );
};

export const query = graphql`
  query UpdatesPageQuery($limit: Int!, $skip: Int!, $slug: String!) {
    prismicUpdatesPage(uid: { eq: $slug }) {
      _previewable
      data {
        surtitle {
          text
        }
        title {
          richText
        }
        hero_style
        main_hero_image {
          alt
          gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        }
        space_below
        dotted_background
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...UpdatesPageDataBodyFeatureTextBlock
          ...UpdatesPageDataBodyRichText
          ... on PrismicUpdatesPageDataBodyFullUpdatesList {
            primary {
              background_colour
              dotted_background
              space_below
            }
          }
        }
      }
    }
    allPrismicNewsPost(
      skip: $skip
      limit: $limit
      sort: { fields: data___post_date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          _previewable
          data {
            title {
              richText
            }
            post_date(formatString: "YYYY-MM-DD")
            main_hero_image {
              thumbnails {
                card {
                  gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                }
              }
            }
          }
          url
        }
      }
    }
  }
`;

UpdatesList.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default withPrismicPreview(UpdatesList);
