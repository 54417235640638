/** @jsx jsx */
import {
  css, jsx, useTheme,
} from '@emotion/react';
import PropTypes from 'prop-types';
import { ContentListThreeRowCard } from './ContentListThreeRowCard';

export const ContentListThreeRow = ({ items }) => {
  const theme = useTheme();

  return (
    <ul
      css={css`
        ${theme.bp.tablet} {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: ${theme.spacer(-1)};

          ${theme.bp.tablet} {
            margin-bottom: ${theme.spacer(-5.5)};
          }
        }
      `}
    >
      {items.map((cardProps, i) => <ContentListThreeRowCard key={i} {...cardProps} />)}
    </ul>
  );
};

ContentListThreeRow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({}),
      title: PropTypes.string,
      surtitle: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
};

ContentListThreeRow.defaultProps = {
  items: [],
};
