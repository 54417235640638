/** @jsx jsx */
import React from 'react';
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { RichText, Elements } from 'prismic-reactjs';
import { SectionHeader, sectionHeaderPropTypes } from './SectionHeader';
import {
  componentThemeOptions,
  componentBackground,
} from '../composableStyles/backgrounds';
import { container } from '../composableStyles/layout';
import { fluidHeading22 } from '../composableStyles/text';
import { bulletStyles } from '../composableStyles/bulletList';
import { marginBottom } from '../composableStyles/marginBottom';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';

export const ddHtmlSerializer = theme => {
  return (type, element, content, children, key) => {
    switch (type) {
      case Elements.list:
        return <React.Fragment key={key}>{children}</React.Fragment>;
      case Elements.listItem:
        return (
          <dd
            key={key}
            css={css`
              color: ${theme.colors.greyMurray};
              ${bulletStyles(theme)}
            `}
          >
            {children}
          </dd>
        );

      default:
        return null;
    }
  };
};

export const ServicesList = ({
  header,
  componentTheme,
  services,
  spaceBelow,
}) => {
  const theme = useTheme();
  return (
    <section
      {...addNavColorAttribute(componentTheme)}
      css={[
        componentBackground(theme, componentTheme),
        marginBottom(theme, spaceBelow),
      ]}
    >
      <SectionHeader {...header} {...{ componentTheme }} />
      <dl
        css={[
          container(theme, 22),
          css`
            ${theme.bp.tablet} {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            }
          `,
        ]}
      >
        {services.map((service, i) => (
          <div
            key={i}
            css={css`
              padding-top: ${theme.spacer(2)};
              padding-bottom: ${theme.spacer(2)};
              border-bottom: 1px solid ${theme.colors.greyDark};

              &:first-of-type {
                border-top: 1px solid ${theme.colors.greyDark};
              }

              ${theme.bp.tablet} {
                flex: 0 0 45%;
                padding-top: ${theme.spacer(2.75)};
                padding-bottom: ${theme.spacer(2.75)};

                &:nth-of-type(2) {
                  border-top: 1px solid ${theme.colors.greyDark};
                }
              }
            `}
          >
            <dt
              css={css`
                ${fluidHeading22(theme)}
                color: ${theme.colors.white};
                margin-bottom: ${theme.spacer(1.25)};
              `}
            >
              {service.label}
            </dt>
            <RichText
              render={service.items}
              htmlSerializer={ddHtmlSerializer(theme)}
            />
          </div>
        ))}
      </dl>
    </section>
  );
};

ServicesList.propTypes = {
  header: PropTypes.shape(sectionHeaderPropTypes),
  componentTheme: PropTypes.oneOf(componentThemeOptions),
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
        }),
      ),
    }),
  ),
  spaceBelow: PropTypes.string,
};

ServicesList.defaultProps = {
  header: {},
  componentTheme: componentThemeOptions[0],
  services: [],
  spaceBelow: spaceBelowDefaultValue,
};
