import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { mapBackgroundValues } from '../utilities/mapBackgroundValues';
import { ContentList } from '../components/ContentList';
import { mapDecorationValues } from '../utilities/mapDecorationValues';

export const RelatedPostsSlice = ({ slice }) => {
  const {
    block_title: section,
    title,
    button_label: label,
    button_link: { url: link },
    button_link_override: linkOverride,
    background_colour: backgroundColor,
    space_below: spaceBelow,
    dotted_background: decoration,
  } = slice.primary;

  const items = slice.items.map(
    ({
      related_news_post: {
        document: {
          data: {
            title: { text: cardTitle },
            post_date: surtitle,
            main_hero_image: {
              thumbnails: { card },
              alt,
            },
          },
          url: cardUrl,
        },
      },
    }) => {
      return {
        image: { ...card, alt },
        title: cardTitle,
        surtitle,
        url: cardUrl,
      };
    },
  );

  const url = linkOverride || link;

  // @TODO need to move decoration from component to cards, or vice versa
  return (
    <ContentList
      header={{ section, title }}
      layout="threeRow"
      button={url ? { url, label } : false}
      componentTheme={mapBackgroundValues(backgroundColor)}
      {...{ spaceBelow, items }}
      decoration={mapDecorationValues(decoration)}
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyRelatedPosts on PrismicLandingPageDataBodyRelatedPosts {
    primary {
      block_title
      title
      button_label
      button_link {
        url
      }
      button_link_override
      space_below
      background_colour
      dotted_background
    }
    items {
      related_news_post {
        document {
          ... on PrismicNewsPost {
            data {
              title {
                text
              }
              post_date
              main_hero_image {
                thumbnails {
                  card {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
            }
            url
          }
        }
      }
    }
  }
  fragment CaseStudyDataBodyRelatedPosts on PrismicCaseStudyDataBodyRelatedPosts {
    primary {
      block_title
      title
      button_label
      button_link {
        url
      }
      button_link_override
      space_below
      background_colour
      dotted_background
    }
    items {
      related_news_post {
        document {
          ... on PrismicNewsPost {
            data {
              title {
                text
              }
              post_date
              main_hero_image {
                thumbnails {
                  card {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
            }
            url
          }
        }
      }
    }
  }
  fragment NewsPostDataBodyRelatedPosts on PrismicNewsPostDataBodyRelatedPosts {
    primary {
      block_title
      title
      button_label
      button_link {
        url
      }
      button_link_override
      space_below
      background_colour
      dotted_background
    }
    items {
      related_news_post {
        document {
          ... on PrismicNewsPost {
            data {
              title {
                text
              }
              post_date
              main_hero_image {
                thumbnails {
                  card {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
            }
            url
          }
        }
      }
    }
  }
  fragment HomepageDataBodyRelatedPosts on PrismicHomepageDataBodyRelatedPosts {
    primary {
      block_title
      title
      button_label
      button_link {
        url
      }
      button_link_override
      space_below
      background_colour
      dotted_background
    }
    items {
      related_news_post {
        document {
          ... on PrismicNewsPost {
            data {
              title {
                text
              }
              post_date
              main_hero_image {
                thumbnails {
                  card {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
            }
            url
          }
        }
      }
    }
  }
`;

RelatedPostsSlice.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      block_title: PropTypes.string,
      title: PropTypes.string,
      button_label: PropTypes.string,
      button_link: PropTypes.shape({
        url: PropTypes.string,
      }),
      button_link_override: PropTypes.string,
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
      dotted_background: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
