/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { PillButton } from './PillButton';
import { vwLinkPropTypes } from './VWLink';
import { container } from '../composableStyles/layout';
import { labelLacksContext } from '../utilities/labelLacksContext';
import { DecorativeDots } from './DecorativeDots';
import { fluidHeading28 } from '../composableStyles/text';
import {
  decorationDefaultValue,
  decorationPropTypes,
} from '../utilities/componentPropTypes';
import { marginBottom } from '../composableStyles/marginBottom';
import { useComponentReveal } from '../hooks/useComponentReveal';
import { cardRevealAnimation } from '../composableStyles/cardRevealAnimation';

const decorationStyles = {
  topLeft: css`
    top: -80px;
    left: -90px;
  `,
  bottomLeft: css`
    bottom: -80px;
    left: -90px;
  `,
  topRight: css`
    top: -80px;
    right: -90px;
  `,
  bottomRight: css`
    bottom: -80px;
    right: -90px;
  `,
};

/*
  Image aspect ratio
  2000x1620 - from Murray's Image Templates screen
  aspect ratio set in prismic schema
*/

export const FiftyFifty = ({
  title,
  text,
  button,
  image,
  decoration,
  imgOnRight,
}) => {
  const theme = useTheme();
  const { alt } = image;
  const imgAlt = alt != null ? alt : '';
  const imageProps = getImage(image);
  const { ref, isRevealed } = useComponentReveal();

  const {
    imageReveal,
    surtitleReveal,
    titleReveal,
  } = cardRevealAnimation(isRevealed);

  return (
    <section
      {...{ ref }}
      css={[
        container(theme, 22),
        marginBottom(theme, '110px'),
        css`
          position: relative;

          ${theme.bp.tablet} {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        `,
      ]}
    >
      <div
        css={[
          css`
          margin-bottom: ${theme.spacer(1.5)};

          ${theme.bp.tablet} {
            margin-bottom: 0;
            flex: 0 0 58%;
            order: ${imgOnRight ? 2 : 1};
          }
        `,
          imageReveal,
        ]}
      >
        <GatsbyImage image={imageProps} alt={imgAlt} />
      </div>
      <div
        css={css`
          ${theme.bp.tablet} {
            flex: 0 0 35%;
            order: ${imgOnRight ? 1 : 2};
          }
        `}
      >
        <div>
          {title && (
            <h2
              css={[
                fluidHeading28(theme),
                css`
                  color: ${theme.colors.black};
                  margin-bottom: ${20 / 28}em;

                  ${theme.bp.tablet} {
                    margin-bottom: ${25 / 28}em;
                  }
                `,
                surtitleReveal,
              ]}
            >
              {title}
            </h2>
          )}
          {text && (
            <div
              css={[
                css`
                  margin-bottom: ${35 / 18}em;
                `,
                titleReveal,
              ]}
            >
              {text}
            </div>
          )}
          {button && button.label && button.url && (
            <PillButton
              {...button}
              a11yText={labelLacksContext(button.label) ? `about ${title}` : ''}
              css={titleReveal}
            />
          )}
        </div>
      </div>
      {decoration !== decorationDefaultValue && (
        <DecorativeDots
          css={css`
            position: absolute;
            z-index: -1;
            ${decorationStyles[decoration] || ''}
            display: none;

            ${theme.bp.tablet} {
              display: block;
            }
          `}
        />
      )}
    </section>
  );
};

FiftyFifty.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node,
  image: PropTypes.shape({
    alt: PropTypes.string,
    gatsbyImageData: PropTypes.shape({}),
  }).isRequired,
  button: PropTypes.oneOfType([
    PropTypes.shape(vwLinkPropTypes),
    PropTypes.bool,
  ]),
  decoration: decorationPropTypes,
  imgOnRight: PropTypes.bool,
};

FiftyFifty.defaultProps = {
  text: null,
  button: false,
  decoration: decorationDefaultValue,
  imgOnRight: false,
};
