/** @jsx jsx */
import { jsx, css, withTheme } from '@emotion/react';
import React from 'react';
import anime from 'animejs/lib/anime.es';
import PropTypes from 'prop-types';

const PATH_INITIAL = 'M54.8 91.9c-25.7 -2.1 -45 -16.1 -44.8 -37.7s18 -44.8 43.3 -44.2s42.1 19.4 42.3 41.9s-17.1 41.9 -40.8 40z';
const PATH_HOVER = 'M47.1 98.7c-26.3 0 -41.7 -18.4 -41.7 -44.3s15.3 -50.1 41.7 -50.1s53.4 24.1 53.4 50.1s-27.1 44.3 -53.4 44.3z';

class VWLogoSVG extends React.Component {
  constructor(props) {
    super(props);

    this.pathRef = React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    const { isHovered } = this.props;
    if (nextProps.isHovered !== isHovered) {
      anime.remove(this.pathRef.current);
      anime({
        targets: this.pathRef.current,
        d: [{ value: isHovered ? PATH_INITIAL : PATH_HOVER }],
        duration: 1000,
      });

      return false;
    }
    return true;
  }

  render() {
    const {
      theme, ...otherProps
    } = this.props;

    delete otherProps.isHovered;

    return (
      <svg
        focusable="false"
        aria-hidden="true"
        css={css`
          transition: all ${theme.transitions.link};
          pointer-events: none;
          fill: var(--vw-logo-background);
        `}
        viewBox="0 0 106 103"
        {...otherProps}
      >
        <path ref={this.pathRef} d={PATH_INITIAL} />
        <polygon
          css={css`fill: var(--vw-logo-color);`}
          points="26.2,65.9 28.4,65.9 28.4,37.3 26.2,37.3"
        />
        <polygon
          css={css`fill: var(--vw-logo-color);`}
          points="30.6,56.8 41.4,37.3 38.9,37.3 30.6,52.2"
        />
        <polygon
          css={css`fill: var(--vw-logo-color);`}
          points="30.6,65.9 46.5,37.3 44,37.3 30.6,61.3"
        />
        <polygon
          css={css`fill: var(--vw-logo-color);`}
          points="44.3,46.4 44.3,66 46.5,66 46.5,42.5 46.5,42.5"
        />
        <polygon
          css={css`fill: var(--vw-logo-color);`}
          points="48.7,56.8 59.6,37.3 57,37.3 48.7,52.2"
        />
        <polygon
          css={css`fill: var(--vw-logo-color);`}
          points="48.7,65.9 64.6,37.3 62.1,37.3 48.7,61.4"
        />
        <polygon
          css={css`fill: var(--vw-logo-color);`}
          points="62.4,46.5 62.4,66 64.6,66 64.6,42.5 64.6,42.5"
        />
        <polygon
          css={css`fill: var(--vw-logo-color);`}
          points="66.9,56.8 77.7,37.3 75.1,37.3 66.9,52.2"
        />
        <polygon
          css={css`fill: var(--vw-logo-color);`}
          points="66.9,66 82.8,37.3 80.2,37.3 66.9,61.4"
        />
      </svg>
    );
  }
}

export const ThemedVWLogoSVG = withTheme(VWLogoSVG);

VWLogoSVG.propTypes = {
  isHovered: PropTypes.bool,
};

VWLogoSVG.defaultProps = {
  isHovered: false,
};
