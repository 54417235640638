// Reference: https://prismic.io/docs/technologies/link-resolver-gatsby
exports.linkResolver = doc => {
  if (doc.type === 'news_post') {
    return `/field-notes/${doc.uid}`;
  }

  if (doc.type === 'case_study') {
    return `/work/${doc.uid}`;
  }

  if (doc.type === 'landing_page') {
    // handle pages nested in expertise
    // i.e. for pages nested under expertise/
    // assumes maximum nesting is 1 level
    if (doc.tags.includes('expertise')) {
      return `/expertise/${doc.uid}`;
    }

    return `/${doc.uid}`;
  }

  if (doc.type === 'updates_page') {
    return `/${doc.uid}`;
  }

  return '/';
};
