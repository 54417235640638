/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { DecorativeDots } from './DecorativeDots';
import { container } from '../composableStyles/layout';
import { ImageSliderWrapper } from './ImageSliderWrapper';
import { ImageGrid } from './ImageGrid';
import { vwImagePropTypes } from './VWImage';
import {
  spaceBelowDefaultValue,
  decorationDefaultValue,
  decorationPropTypes,
} from '../utilities/componentPropTypes';
import { marginBottom } from '../composableStyles/marginBottom';

const frameOptions = {
  full: () => css``,
  inset: theme => css`
    background-color: ${theme.colors.greyLightest};
    padding: 8% 10%;
  `,
};

const decorationStyles = {
  topLeft: css`
    top: -75px;
    left: -55px;
  `,
  bottomLeft: css`
    bottom: -80px;
    left: -90px;
  `,
  topRight: css`
    top: -80px;
    right: -90px;
  `,
  bottomRight: css`
    bottom: -50px;
    right: -130px;
  `,
};

// inset frame is padded by 108px v & 140px h space when width is 1340px
// h padding is 140 / 1340 * 100 ~10%
// v padding is 108 / 1340 * 100 ~8%

const SLIDER_DISPLAY_TYPE = 'slider';
const GRID_DISPLAY_TYPE = 'grid';

export const InlineImages = ({
  display,
  frame,
  images,
  decoration,
  spaceBelow,
  width,
  loading,
}) => {
  const theme = useTheme();

  if (!Array.isArray(images) || !images.length) {
    return null;
  }

  if (display === SLIDER_DISPLAY_TYPE && images.length > 1) {
    if (frame === 'full') {
      return (
        <ImageSliderWrapper {...{
          images,
          spaceBelow,
          loading,
          frame,
        }}
        />
      );
    }
    return (
      <div
        css={[
          container(theme, width),
          marginBottom(theme, spaceBelow),
        ]}
      >
        <div
          css={css`
            background-color: ${theme.colors.greyLightest};
            padding: calc(8% - 30px) 0;
            position: relative;
            z-index: 2;
          `}
        >
          <ImageSliderWrapper
            {...{
              images,
              loading,
              frame,
            }}
            spaceBelow="0px"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      css={[
        container(theme, width),
        marginBottom(theme, spaceBelow),
        css`
          position: relative;
        `,
      ]}
    >
      <div
        css={css`
          ${frameOptions[frame](theme)}
          position: relative;
          z-index: 2;
        `}
      >
        <ImageGrid
          {...{ images, loading }}
          withShadow={frame === 'inset'}
        />
      </div>
      {decoration !== decorationDefaultValue && (
        <DecorativeDots
          css={css`
            position: absolute;
            z-index: 1;
            ${decorationStyles[decoration] || ''}
            display: none;

            ${theme.bp.tablet} {
              display: block;
            }
          `}
        />
      )}
    </div>
  );
};

InlineImages.propTypes = {
  display: PropTypes.oneOf([SLIDER_DISPLAY_TYPE, GRID_DISPLAY_TYPE]),
  frame: PropTypes.oneOf(Object.keys(frameOptions)),
  images: PropTypes.arrayOf(PropTypes.shape(vwImagePropTypes)),
  decoration: decorationPropTypes,
  spaceBelow: PropTypes.string,
  width: PropTypes.oneOf([20, 24]),
  loading: PropTypes.oneOf(['lazy', 'eager']),
};

InlineImages.defaultProps = {
  display: GRID_DISPLAY_TYPE,
  frame: Object.keys(frameOptions)[0],
  images: [],
  decoration: decorationDefaultValue,
  spaceBelow: spaceBelowDefaultValue,
  width: 24,
  loading: 'lazy',
};
