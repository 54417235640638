import PropTypes from 'prop-types';
import { decorationValueMapping } from './mapDecorationValues';

const decorationValues = Object.values(decorationValueMapping);

export const decorationPropTypes = PropTypes.oneOf(decorationValues);
export const decorationDefaultValue = decorationValues[0];

export const spaceBelowDefaultValue = 'Use Default';

export const contentListCardPropTypes = PropTypes.shape({
  image: PropTypes.shape({}),
  imageMobile: PropTypes.shape({}),
  title: PropTypes.string,
  surtitle: PropTypes.string,
  url: PropTypes.string,
  isFullWidth: PropTypes.bool,
  decoration: decorationPropTypes,
});
