/* eslint-disable max-len */
/** @jsx jsx */
import { jsx, css, keyframes /* , useTheme */ } from '@emotion/react';
import PropTypes from 'prop-types';
import { ENTER_STATE, EXIT_STATE, INITIAL_STATE } from '../utilities/constants';
import { easeInQuart, easeOutQuart } from '../composableStyles/easings';

const textEnter = keyframes`
  from {
    transform: translateY(102%);
  }

  to {
    transform: translateY(0);
  }
`;

const textLeave = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-102%);
  }
`;

export const HomePageHeroHeadline = ({ headline, animatingState, ...otherProps }) => {
  // const theme = useTheme();
  const lines = headline.split(' ');

  if (!Array.isArray(lines) || !lines.length) {
    return null;
  }

  return (
    <span
      css={css`
        display: block;
      `}
      {...otherProps}
    >
      {lines.map((line, i) => {
        const delay = `calc(var(--headline-enter-delay) + (${i} * var(--headline-stagger)))`;

        let headlineAnimation;

        switch (animatingState) {
          case ENTER_STATE:
            headlineAnimation = css`
              animation: ${textEnter} var(--headline-duration) ${easeOutQuart} ${delay} 1 both;
            `;
            break;
          case EXIT_STATE:
            headlineAnimation = css`
              animation: ${textLeave} var(--headline-duration) ${easeInQuart} ${delay} 1 both;
            `;
            break;
          default:
            headlineAnimation = null;
        }

        return (
          <span
            key={i}
            css={css`
              display: block;
              overflow: hidden;

              &:first-of-type {
                margin-bottom: -0.3em; /* offset line-height */
              }
            `}
          >
            <span
              css={[
                css`
                  display: block;
                  transform: translateY(100%);
                `,
                headlineAnimation,
              ]}
            >
              {line}
            </span>
          </span>
        );
      })}
    </span>
  );
};

HomePageHeroHeadline.propTypes = {
  headline: PropTypes.string.isRequired,
  animatingState: PropTypes.oneOf([INITIAL_STATE, ENTER_STATE, EXIT_STATE]),
};

HomePageHeroHeadline.defaultProps = {
  animatingState: INITIAL_STATE,
};
