/* eslint react/prop-types: off */
import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { SliceZone } from '../components/SliceZone';
import { HeroText } from '../components/HeroText';
import { mapDecorationValues } from '../utilities/mapDecorationValues';
import { BlogHeroImage } from '../components/BlogHeroImage';

const NewsPostTemplate = ({ data }) => {
  if (!data) return null;
  const page = data.prismicNewsPost;
  const {
    title: { text },
    post_date: postDate,
    dotted_background: dottedBackground,
    main_hero_image: image,
  } = page.data;
  const title = 'Field Notes';

  return (
    <Layout>
      <SEO title={`${text} | ${title}`} />
      <HeroText
        {...{ title, text }}
        surtitle={postDate}
        size="blog"
        spaceBelow="50px"
      />
      <BlogHeroImage
        {...{ image }}
        decoration={mapDecorationValues(dottedBackground)}
        spaceBelow="70px"
      />
      <SliceZone sliceZone={page.data.body} />
    </Layout>
  );
};

export const query = graphql`
  query NewsPostQuery($id: String) {
    prismicNewsPost(id: { eq: $id }) {
      _previewable
      data {
        title {
          text
        }
        post_date
        main_hero_image {
          gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          thumbnails {
            mobile {
              gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
            }
          }
          alt
        }
        dotted_background
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...NewsPostDataBodyQuote
          ...NewsPostDataBodyFiftyFiftyPanels
          ...NewsPostDataBodyCaseStudyList
          ...NewsPostDataBodyServicesBlock
          ...NewsPostDataBodyTeamListBlock
          ...NewsPostDataBodyExpandablesListBlock
          ...NewsPostDataBodyFeatureTextBlock
          ...NewsPostDataBodyInlineImagesBlock
          ...NewsPostDataBodyRelatedPosts
          ...NewsPostDataBodyRichText
          ...NewsPostDataBodyLogos
        }
      }
    }
  }
`;

NewsPostTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default withPrismicPreview(NewsPostTemplate);
