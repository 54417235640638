import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { TeamList } from '../components/TeamList';
import { mapBackgroundValues } from '../utilities/mapBackgroundValues';

export const TeamListSlice = ({ slice }) => {
  const {
    primary: {
      background_colour: backgroundColor,
      space_below: spaceBelow,
      block_title: section,
      intro_text: { richText: introText },
      title,
    },
    items,
  } = slice;
  const text = RichText.asText(introText);
  const componentTheme = mapBackgroundValues(backgroundColor);
  const players = items.map(
    ({
      team_member_name: name,
      team_member_title: role,
      team_member_image: image,
    }) => ({
      name,
      role,
      image,
    }),
  );

  return (
    <TeamList
      header={{ title, section, text }}
      {...{ players, componentTheme, spaceBelow }}
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyTeamListBlock on PrismicLandingPageDataBodyTeamList {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      background_colour
      space_below
    }
    items {
      team_member_name
      team_member_title
      team_member_image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
    }
  }
  fragment CaseStudyDataBodyTeamListBlock on PrismicCaseStudyDataBodyTeamList {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      background_colour
      space_below
    }
    items {
      team_member_name
      team_member_title
      team_member_image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
    }
  }
  fragment NewsPostDataBodyTeamListBlock on PrismicNewsPostDataBodyTeamList {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      background_colour
      space_below
    }
    items {
      team_member_name
      team_member_title
      team_member_image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
    }
  }
  fragment HomepageDataBodyTeamListBlock on PrismicHomepageDataBodyTeamList {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      background_colour
      space_below
    }
    items {
      team_member_name
      team_member_title
      team_member_image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
    }
  }
`;

TeamListSlice.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string,
    primary: PropTypes.shape({
      block_title: PropTypes.string,
      title: PropTypes.string,
      intro_text: PropTypes.shape({
        richText: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
          }),
        ),
      }),
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        team_member_name: PropTypes.string,
        team_member_title: PropTypes.string,
        team_member_image: PropTypes.shape({
          gatsbyImageData: PropTypes.shape({
            images: PropTypes.shape({
              width: PropTypes.number,
              height: PropTypes.number,
              fallback: PropTypes.shape({
                src: PropTypes.string,
                srcSet: PropTypes.string,
              }),
            }),
          }),
          alt: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
};
