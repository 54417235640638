import * as React from 'react';
import PropTypes from 'prop-types';
import { QuoteSlice } from '../slices/QuoteSlice';
import { FiftyFiftyPanelsSlice } from '../slices/FiftyFiftyPanelsSlice';
import { CaseStudyListSlice } from '../slices/CaseStudyListSlice';
import { ServicesBlockSlice } from '../slices/ServicesBlockSlice';
import { TeamListSlice } from '../slices/TeamListSlice';
import { ExpandablesListSlice } from '../slices/ExpandablesListSlice';
import { FeatureTextSlice } from '../slices/FeatureTextSlice';
import { InlineImagesSlice } from '../slices/InlineImagesSlice';
import { RelatedPostsSlice } from '../slices/RelatedPostsSlice';
import { RichTextSlice } from '../slices/RichTextSlice';
import { LogosSlice } from '../slices/LogosSlice';
import { FullUpdatesListSlice } from '../slices/FullUpdatesListSlice';

export const SliceZone = ({ sliceZone }) => {
  const sliceComponents = {
    rich_text: RichTextSlice,
    quote: QuoteSlice,
    feature_text: FeatureTextSlice,
    logos: LogosSlice,
    inline_images: InlineImagesSlice,
    services_block: ServicesBlockSlice,
    related_posts: RelatedPostsSlice,
    case_study_list: CaseStudyListSlice,
    fifty_fifty_panels: FiftyFiftyPanelsSlice,
    team_list: TeamListSlice,
    expandables_list: ExpandablesListSlice,
    full_updates_list: FullUpdatesListSlice,
  };

  return (
    <>
      {sliceZone.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type];
        if (SliceComponent) {
          return <SliceComponent slice={slice} key={`slice-${index}`} />;
        }
        return null;
      })}
    </>
  );
};

SliceZone.propTypes = {
  sliceZone: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
