import * as React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';

import { linkResolver } from './src/utilities/linkResolver';
import HomeTemplate from './src/pages/index';
import LandingPageTemplate from './src/pages/{PrismicLandingPage.url}';
import CaseStudyTemplate from './src/pages/{PrismicCaseStudy.url}';
import NewsPostTemplate from './src/pages/{PrismicNewsPost.url}';
import UpdatesList from './src/templates/UpdatesList';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          homepage: HomeTemplate,
          landing_page: LandingPageTemplate,
          case_study: CaseStudyTemplate,
          news_post: NewsPostTemplate,
          updates_page: UpdatesList,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
