/** @jsx jsx */
import {
  css, jsx, keyframes, useTheme,
} from '@emotion/react';
import PropTypes from 'prop-types';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import MuxVideo from '@mux/mux-video-react';
import * as React from 'react';
import { aspectRatio } from '../composableStyles/aspectRatio';
import { pseudoDecoration } from '../composableStyles/pseudoDecoration';
import { VIDEO_ENTER_STEP } from '../utilities/constants';
import { easeOutQuart } from '../composableStyles/easings';
import { completelyCover } from '../composableStyles/completelyCover';
import { MediaQueryContext } from '../contexts/MediaQueryProvider';
import { VWImage } from './VWImage';
import { PauseSVG } from './PauseSVG';
import { PlaySVG } from './PlaySVG';
import { fadeIn } from '../composableStyles/keyframes';

const initialTranslateX = 102;

const videoEnter = keyframes`
  from {
    transform: translateX(${initialTranslateX}%);
  }

  to {
    transform: translateX(0);
  }
`;

export const HomePageHeroMedia = ({
  step, advanceAnimation, mobileImage, ...otherProps
}) => {
  const theme = useTheme();
  const { isTablet } = useContext(MediaQueryContext);
  const [videoCanPlay, setVideoCanPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const videoRef = useRef();

  const playVideo = () => {
    videoRef.current.play();
  };

  useEffect(() => {
    if (step === VIDEO_ENTER_STEP) {
      if (!isTablet) {
        setIsVisible(true);
        advanceAnimation();
      } else if (videoCanPlay) {
        playVideo();
        setIsVisible(true);
        advanceAnimation();
      }
    }
  }, [step, videoCanPlay]); // eslint-disable-line react-hooks/exhaustive-deps

  // handle the possible switch between mobile & tablet
  useEffect(() => {
    if (isTablet && videoCanPlay && step > VIDEO_ENTER_STEP) {
      playVideo();
    }
  }, [isTablet, videoCanPlay]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleVideoCanPlayThrough = () => {
    setVideoCanPlay(true);
  };

  const backgroundAnimation = isVisible
    ? css`
      &::after {
        animation: ${videoEnter} var( --video-background-duration) ${easeOutQuart} 0ms 1 both;
      }
    `
    : null;

  const videoAnimation = isVisible
    ? css`animation: ${videoEnter} var(--video-duration) ${easeOutQuart} var(--video-delay) 1 both;`
    : null;

  const buttonAnimation = isVisible
    ? css`animation: ${fadeIn} 600ms linear 3000ms 1 both;`
    : null;

  const handlePlayPauseClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const handlePlaying = () => { setIsPlaying(true); };
  const handlePause = () => { setIsPlaying(false); };

  return (
    <div
      css={[
        aspectRatio(360, 315),
        css`
          &::before {
            ${theme.bp.tablet} {
              padding-top: ${(660 / 937) * 100}%;
            }
          }
        
          &::after {
            ${pseudoDecoration()}
            z-index: var(--video-background-z-index);
            color: ${theme.colors.blackDark};
            transform: translateX(${initialTranslateX}%);
          }
        `,
        backgroundAnimation,
      ]}
      {...otherProps}
    >
      <div
        css={[
          css`
            z-index: var(--video-z-index);
            transform: translateX(${initialTranslateX}%);
            
            &::after {
              ${pseudoDecoration({
            left: '0',
            top: 'auto',
            width: '100%',
            height: '38%',
          })}
              z-index: var(--video-overlay-z-index);
              background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
              bottom: 0;

              ${theme.bp.tablet} {
                width: 76%;
                height: 100%;
                background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);
              }
            }
          `,
          videoAnimation,
        ]}
      >
        {isTablet && (
        <React.Fragment>
          <MuxVideo
            ref={videoRef}
            playbackId="K4fiaItVamcl00TcWD61802P91a7Gqqy1qBqAm5RiIKf8"
            loop
            muted
            onCanPlayThrough={handleVideoCanPlayThrough}
            onPlaying={handlePlaying}
            onPause={handlePause}
            metadata={{
              video_id: 'home-page-video',
              video_title: 'Squamish Adventures',
            }}
            css={[
              completelyCover,
              css`
                z-index: var(--video-z-index);
                object-fit: cover;
              `,
            ]}
          />
          <button
            type="button"
            onClick={handlePlayPauseClick}
            aria-label={`${isPlaying ? 'pause' : 'play'} the video`}
            css={[
              css`
                position: absolute;
                z-index: 10;
                bottom: ${theme.pxToRem(15)}rem;
                right: ${theme.pxToRem(15)}rem;
                display: block;
                color: ${theme.colors.white};
                opacity: 0;
                border-radius: 50%;
                line-height: 0;
                
                & circle {
                  fill: black;
                  fill-opacity: 0.1;
                  transition: fill-opacity 300ms linear; 
                }

                @media (hover: hover) {
                  &:hover {
                    & circle {
                      fill-opacity: 1;
                    }
                  }
                }
                
                &:focus-visible {
                 outline: solid 1px currentColor;
                 outline-offset: 3px;

                  & circle {
                    fill-opacity: 1;
                  }
                }
              `,
              buttonAnimation,
            ]}
          >
            {isPlaying ? <PauseSVG /> : <PlaySVG />}
          </button>
        </React.Fragment>
        )}
        {!isTablet && (
          <VWImage
            image={mobileImage}
            loading="eager"
            css={[
              completelyCover,
              css`
                z-index: var(--video-z-index);
              `,
            ]}
          />
        )}
      </div>
    </div>
  );
};

HomePageHeroMedia.propTypes = {
  step: PropTypes.number.isRequired,
  advanceAnimation: PropTypes.func.isRequired,
  mobileImage: PropTypes.shape({}).isRequired,
};
