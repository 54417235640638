/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import { fluidScale } from '../composableStyles/fluidScale';
import { widestScaleFactor } from '../composableStyles/text';
import { WIDEST_BP, WIDE_BP } from '../composableStyles/theme';

const acknowledgementText = theme => css`
  color: ${theme.colors.grey};
  margin-bottom: 0;
  text-align: left;
  letter-spacing: ${0.2 / 11}em;
  line-height: ${13 / 11};
  ${fluidScale({
    minPx: 11, maxPx: 11 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}

  ${theme.bp.tablet} {
    text-align: right;
  }
`;

export const FooterAcknowledgement = () => {
  const theme = useTheme();

  return (
    <p
      css={[
        acknowledgementText(theme),
      ]}
    >
      VentureWeb humbly acknowledges that we work, live and play on the traditional
      territory of the Skwxwú7mesh Úxwumixw (Squamish Nation).
    </p>
  );
};
