/* eslint react/prop-types: off */
import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { SliceZone } from '../components/SliceZone';
import { HeroText } from '../components/HeroText';
import { HeroHalfImage } from '../components/HeroHalfImage';
import { htmlSerializer } from '../utilities/htmlSerializer';
import { mapDecorationValues } from '../utilities/mapDecorationValues';

const parentTags = ['expertise'];

const LandingPageTemplate = ({ data }) => {
  if (!data) return null;
  const {
    surtitle: { text: title },
    title: { richText },
    hero_style: heroStyle,
    main_hero_image: image,
    space_below: spaceBelow,
    dotted_background: dottedBackground,
    body,
  } = data.prismicLandingPage.data;
  const { tags } = data.prismicLandingPage;

  const HeroComponent = heroStyle === 'Text with Image' ? HeroHalfImage : HeroText;

  // check if this page has a parent
  const hasParent = !!parentTags.find(parentTag => tags.some(tag => tag === parentTag));
  const seoTitle = hasParent
    ? title
    : `${RichText.asText(richText)} | ${title}`;

  return (
    <Layout>
      <SEO title={seoTitle} />
      <HeroComponent
        {...{ title, image, spaceBelow }}
        text={<RichText render={richText} {...{ htmlSerializer }} />}
        size={heroStyle === 'Large Text' ? 'large' : 'regular'}
        decoration={mapDecorationValues(dottedBackground)}
      />
      <SliceZone sliceZone={body} />
    </Layout>
  );
};

// useful reference -
export const query = graphql`
  query LandingPageQuery($id: String) {
    prismicLandingPage(id: { eq: $id }) {
      _previewable
      data {
        surtitle {
          text
        }
        title {
          richText
        }
        hero_style
        main_hero_image {
          gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          thumbnails {
            mobile {
              gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
            }
          }
          alt
        }
        space_below
        dotted_background
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...LandingPageDataBodyQuote
          ...LandingPageDataBodyFiftyFiftyPanels
          ...LandingPageDataBodyCaseStudyList
          ...LandingPageDataBodyServicesBlock
          ...LandingPageDataBodyTeamListBlock
          ...LandingPageDataBodyExpandablesListBlock
          ...LandingPageDataBodyFeatureTextBlock
          ...LandingPageDataBodyInlineImagesBlock
          ...LandingPageDataBodyRelatedPosts
          ...LandingPageDataBodyRichText
          ...LandingPageDataBodyLogos
        }
      }
      tags
    }
  }
`;

LandingPageTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default withPrismicPreview(LandingPageTemplate);
