import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { VisuallyHidden } from './VisuallyHidden';
import {
  INTERNAL_LINK_TYPE,
  EXTERNAL_LINK_TYPE,
  OTHER_LINK_TYPE,
} from '../utilities/constants';

const EXTERNAL_LINK_ATTRIBUTES = {
  target: '_blank',
  rel: 'noopener',
};

export const VWLink = ({
  url,
  label,
  type,
  children,
  a11yText,
  ...otherProps
}) => {
  const LinkTag = type === INTERNAL_LINK_TYPE ? Link : 'a';
  const linkProps = type === EXTERNAL_LINK_TYPE ? EXTERNAL_LINK_ATTRIBUTES : {};

  if (type === INTERNAL_LINK_TYPE) {
    linkProps.to = url;
  } else {
    linkProps.href = url;
  }

  return (
    <LinkTag {...linkProps} {...otherProps}>
      {children || label}
      {a11yText && <VisuallyHidden>{a11yText}</VisuallyHidden>}
    </LinkTag>
  );
};

export const vwLinkPropTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf([
    EXTERNAL_LINK_TYPE,
    INTERNAL_LINK_TYPE,
    OTHER_LINK_TYPE,
  ]),
  a11yText: PropTypes.string,
};

VWLink.propTypes = vwLinkPropTypes;

VWLink.defaultProps = {
  type: INTERNAL_LINK_TYPE,
  label: '',
  children: '',
  a11yText: '',
};
