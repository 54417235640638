import { css } from '@emotion/react';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';

const defaultVerticalSpace = '80px';

export const marginBottom = (theme, spaceBelow) => {
  const verticalSpace = spaceBelow !== spaceBelowDefaultValue ? spaceBelow : defaultVerticalSpace;
  const desktop = parseInt(verticalSpace, 10);

  return css`
    margin-bottom: ${theme.scaledVerticalSpacing(desktop)};
  `;
};
