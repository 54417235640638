/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionItem, AccordionButton } from '@reach/accordion';
import { container } from '../composableStyles/layout';
import { SectionHeader, sectionHeaderPropTypes } from './SectionHeader';
import {
  componentThemeOptions,
  componentBackground,
} from '../composableStyles/backgrounds';
import { marginBottom } from '../composableStyles/marginBottom';
import { fluidHeading22, textInherit } from '../composableStyles/text';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { pseudoDecoration } from '../composableStyles/pseudoDecoration';
import { MediaQueryContext } from '../contexts/MediaQueryProvider';
import { ExpanderListPanel } from './ExpanderListPanel';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';

export const ExpanderList = ({
  header, componentTheme, items, spaceBelow,
}) => {
  const { isTablet, isDesktop } = useContext(MediaQueryContext);
  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [collapsingAccordions, setCollapsingAccordions] = useState([]);
  const theme = useTheme();

  let numColumns = 1;
  if (isTablet) {
    numColumns = 2;
  }
  if (isDesktop) {
    numColumns = 3;
  }

  const itemsPerColumn = Math.ceil(items.length / numColumns);
  const isCollapsing = index => collapsingAccordions.includes(index);
  const isExpanded = index => expandedAccordions.includes(index);
  const isMinus = index => !isCollapsing(index) && isExpanded(index);

  // to achieve the desired layout split the items into two columns for tablet
  //  and 3 columns for desktop
  const columns = [];
  for (let i = 0; i < numColumns; i++) {
    columns.push(items.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn));
  }

  const handleChange = index => {
    // update the accordions state but allow time for the accordion to animate closed
    if (isExpanded(index)) {
      // collapse
      setCollapsingAccordions([...collapsingAccordions, index]);
    } else {
      // expand index, add expanded accordions to collapsing list
      setCollapsingAccordions(expandedAccordions);
      setExpandedAccordions([...expandedAccordions, index]);
    }
  };

  const removeAfterAnimation = index => {
    setExpandedAccordions(expandedAccordions.filter(i => i !== index));
    setCollapsingAccordions(collapsingAccordions.filter(i => i !== index));
  };

  return (
    <section
      {...addNavColorAttribute(componentTheme)}
      css={[
        componentBackground(theme, componentTheme),
        marginBottom(theme, spaceBelow),
      ]}
    >
      <SectionHeader {...header} {...{ componentTheme }} />
      {
        /* NOTE: key prop is used on Accordion component below to force a re-render when mq changes,
        without this it breaks when layout changes */
        items.length > 0 && (
          <Accordion
            key={`forceRender${numColumns}`}
            index={expandedAccordions}
            onChange={handleChange}
            css={[
              container(theme, 22),
              css`
                display: flex;

                ${theme.bp.tablet} {
                  justify-content: space-between;
                  margin-top: -10px;
                }
              `,
            ]}
          >
            {columns.map((column, i) => (
              <div
                key={i}
                css={css`
                  flex: 0 0 100%;

                  ${theme.bp.tablet} {
                    flex: 0 1 100%;
                    margin-right: 7.5%;

                    &:last-of-type {
                      margin-right: 0;
                    }
                  }

                  ${theme.bp.desktop} {
                    margin-right: 40;
                    flex: 0 1 320px;
                  }

                  ${theme.bp.wide} {
                    margin-right: 0;
                    flex: 0 1 26%;
                  }
                `}
              >
                {column.map(({ title, content }, j) => {
                  const index = itemsPerColumn * i + j;
                  return (
                    <AccordionItem
                      key={index}
                      css={css`
                        border-bottom: 1px solid ${theme.colors.greyMurray};
                        padding-top: ${theme.pxToRem(28)}rem;
                        padding-bottom: ${theme.pxToRem(28)}rem;

                        &:first-of-type {
                          border-top: 1px solid ${theme.colors.greyMurray};
                        }
                      `}
                    >
                      <h3 css={fluidHeading22(theme)}>
                        <AccordionButton
                          css={[
                            textInherit,
                            css`
                              color: ${theme.colors.black};
                              position: relative;
                              width: 100%;
                              text-align: left;

                              &::before,
                              &::after {
                                ${pseudoDecoration({
                              top: '50%',
                              left: 'auto',
                              width: `${12 / 22}em`,
                              height: `${2 / 22}em`,
                            })}
                                right: ${13 / 22}em;
                                transform: translateY(-50%);
                                color: ${theme.colors.pink};
                                transition: all 0.2s ease;
                              }

                              &::after {
                                transform: translateY(-50%)
                                  ${isMinus(index) ? '' : 'rotate(90deg)'};
                              }
                            `,
                          ]}
                        >
                          {title}
                        </AccordionButton>
                      </h3>
                      <ExpanderListPanel
                        isExpanded={isExpanded(index)}
                        isCollapsing={isCollapsing(index)}
                        {...{ index, removeAfterAnimation }}
                      >
                        {content}
                      </ExpanderListPanel>
                    </AccordionItem>
                  );
                })}
              </div>
            ))}
          </Accordion>
        )
      }
    </section>
  );
};

ExpanderList.propTypes = {
  header: PropTypes.shape(sectionHeaderPropTypes),
  componentTheme: PropTypes.oneOf(componentThemeOptions),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node,
    }),
  ),
  spaceBelow: PropTypes.string,
};

ExpanderList.defaultProps = {
  header: {},
  items: [],
  componentTheme: componentThemeOptions[0],
  spaceBelow: spaceBelowDefaultValue,
};
