import { css } from '@emotion/react';
import {
  WHITE_COMPONENT_THEME,
  LIGHT_COMPONENT_THEME,
  DARK_COMPONENT_THEME,
} from '../utilities/constants';

export const componentThemes = {
  [WHITE_COMPONENT_THEME]: {
    section: 'greyLighter',
    title: 'black',
    text: 'greyDark',
    background: 'white',
  },
  [LIGHT_COMPONENT_THEME]: {
    section: 'greyLighter',
    title: 'black',
    background: 'greyLightest',
  },
  [DARK_COMPONENT_THEME]: {
    section: 'greyLight',
    title: 'white',
    text: 'greyMurray',
    background: 'black',
    styles: theme => css`
      padding-top: ${theme.spacer(3.5)};
      padding-bottom: ${theme.spacer(3.5)};

      ${theme.bp.tablet} {
        padding-top: ${theme.spacer(7)};
        padding-bottom: ${theme.spacer(7)};
      }
    `,
  },
};

export const componentThemeOptions = Object.keys(componentThemes);

export const backgroundColor = (theme, componentTheme) => theme.colors[
  componentThemes[componentTheme].background
];

export const sectionColor = (theme, componentTheme) => theme.colors[
  componentThemes[componentTheme].section
];

export const titleColor = (theme, componentTheme) => theme.colors[
  componentThemes[componentTheme].title
];

export const textColor = (theme, componentTheme) => theme.colors[
  componentThemes[componentTheme].text
      || componentThemes[componentTheme].title
];

const componentStyles = (theme, componentTheme) => componentThemes[componentTheme].styles
  ? componentThemes[componentTheme].styles(theme)
  : '';

export const componentBackground = (theme, componentTheme) => css`
  background-color: ${backgroundColor(theme, componentTheme)};
  ${componentStyles(theme, componentTheme)}
`;
