/** @jsx jsx */
import { css, jsx, ThemeProvider } from '@emotion/react';
import PropTypes from 'prop-types';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import { Header } from './Header';
import { Footer } from './Footer';
import { theme } from '../composableStyles/theme';
import { GlobalStyles } from './GlobalStyles';
import { vh } from '../composableStyles/a11y';
import { fluidHeading45 } from '../composableStyles/text';
import { MediaQueryProvider } from '../contexts/MediaQueryProvider';

export const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <MediaQueryProvider>
      <GlobalStyles />
      <div
        css={css`
        min-height: 100vh;
        display: flex;
        flex-direction: column;
      `}
      >
        <SkipNavLink
          css={[
            css`
            ${vh}

            &:focus {
              top: 10px;
              left: 10px;
              width: auto;
              height: auto;
              padding: 10px 20px;
              background-color: white;
              clip: auto;
              clip-path: none;
              white-space: normal;
              z-index: 100001;
              color: ${theme.colors.black};
              text-decoration: underline;
              text-decoration-color: ${theme.colors.pink};
              text-underline-offset: 0.22em;

              ${theme.bp.tablet} {
                top: 20px;
                left: 20px;
              }
            }
          `,
            fluidHeading45(theme),
          ]}
        />
        <Header />
        <SkipNavContent />
        <main>{children}</main>
        <Footer />
      </div>
    </MediaQueryProvider>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
