/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { SectionHeading } from './SectionHeading';
import { WysiwygText } from './WysiwygText';
import {
  componentThemeOptions,
  sectionColor,
  titleColor,
  textColor,
} from '../composableStyles/backgrounds';
import { fluidHeading34 } from '../composableStyles/text';
import { container, subContainer } from '../composableStyles/layout';

export const SectionHeader = ({
  section,
  title,
  text,
  componentTheme,
  ...otherProps
}) => {
  const theme = useTheme();

  return (
    <header
      {...otherProps}
      css={[
        css`
          display: block;
          margin-bottom: ${theme.scaledVerticalSpacing(60)};
        `,
        container(theme, 24),
      ]}
    >
      {section && (
        <SectionHeading color={sectionColor(theme, componentTheme)}>
          {section}
        </SectionHeading>
      )}
      <div css={subContainer(theme, 22)}>
        {title && (
          <p
            css={css`
              ${fluidHeading34(theme)}
              color: ${titleColor(theme, componentTheme)};
              margin-top: ${theme.spacer(2.5)};
              max-width: ${774 / 34}em;
            `}
          >
            {title}
          </p>
        )}
        {text && (
          <WysiwygText
            {...{ text }}
            css={css`
              color: ${textColor(theme, componentTheme)};
              max-width: ${774 / 18}em;
            `}
          />
        )}
      </div>
    </header>
  );
};

export const sectionHeaderPropTypes = {
  section: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

SectionHeader.propTypes = {
  ...sectionHeaderPropTypes,
  componentTheme: PropTypes.oneOf(componentThemeOptions),
};

SectionHeader.defaultProps = {
  componentTheme: componentThemeOptions[0],
};
