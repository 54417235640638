/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { VWLink } from './VWLink';

export const LinksList = ({
  heading,
  links,
  StyledSection,
  StyledHeading,
  // StyledUl,
  StyledLi,
  StyledLink,
}) => (
  <StyledSection>
    <StyledHeading>{heading}</StyledHeading>
    <ul>
      {links.map(link => (
        <StyledLi key={link.label}>
          <StyledLink {...link} />
        </StyledLi>
      ))}
    </ul>
  </StyledSection>
);

LinksList.propTypes = {
  heading: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  StyledSection: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  StyledHeading: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types,
  // StyledUl: PropTypes.func,
  StyledLi: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  StyledLink: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

LinksList.defaultProps = {
  links: [],
  // StyledSection: (<section />),
  // StyledHeading: (<h2 />), // eslint-disable-line jsx-a11y/heading-has-content
  // StyledUl: (<ul />),
  // StyledLi: (<li />),
  StyledLink: VWLink,
};
