/** @jsx jsx */
import {
  css, jsx, useTheme,
} from '@emotion/react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { VWLink } from './VWLink';
import { VWImage } from './VWImage';
import { fluidText13, fluidCardHeading16 } from '../composableStyles/text';
import { aspectRatio } from '../composableStyles/aspectRatio';
import { useComponentReveal } from '../hooks/useComponentReveal';
import { cardRevealAnimation } from '../composableStyles/cardRevealAnimation';
import { MediaQueryContext } from '../contexts/MediaQueryProvider';
import { easeOutQuart } from '../composableStyles/easings';

export const ContentListThreeRowCard = ({
  image,
  title,
  surtitle,
  url,
}) => {
  const theme = useTheme();
  const { isTablet } = useContext(MediaQueryContext);
  const { ref, isRevealed } = useComponentReveal();

  const {
    imageReveal,
    surtitleReveal,
    titleReveal,
  } = cardRevealAnimation(isRevealed);

  const paddingPercent = 2.7;
  const clipLower = `${paddingPercent}%`;
  const clipUpper = `${100 - paddingPercent}%`;
  const clipPath = `polygon(${clipLower} ${clipLower}, ${clipUpper} ${clipLower}, ${clipUpper} ${clipUpper}, ${clipLower} ${clipUpper})`;

  return (
    <li
      {...{ ref }}
      css={[
        css`
          margin-bottom: ${theme.scaledVerticalSpacingM(20)};

          &:last-of-type {
            margin-bottom: 0;
          }

          ${theme.bp.tablet} {
            flex-basis: 30%;
            margin-bottom: ${theme.spacer(5.5)};
          }
        `,
      ]}
    >
      <VWLink
        {...{ url }}
        css={css`
          --title-text-decoration-color: ${theme.colors.pink}00;
          display: flex;
          align-items: center;
          justify-content: space-between;

          ${theme.bp.tablet} {
            display: block;
          }

          @media (hover: hover) {
            &:hover {
              --card-image-scale: 1.1;
              --card-image-scale-transition: transform 300ms ${easeOutQuart} 0ms;
              
              ${theme.bp.desktop} {
                --card-image-clip-path: ${clipPath};
                --card-image-clip-transition: clip-path 300ms ${easeOutQuart} 0ms;
              }
            }
          }
          
          &:focus-visible {
            --title-text-decoration-color: ${theme.colors.pink}FF;
            --card-image-scale: 1.1;
            --card-image-scale-transition: transform 300ms ${easeOutQuart} 0ms;
            
            ${theme.bp.desktop} {
              --card-image-clip-path: ${clipPath};
              --card-image-clip-transition: clip-path 300ms ${easeOutQuart} 0ms;
            }
          }
        `}
      >
        <div
          css={[
            aspectRatio(144, 96),
            css`
              flex: 0 0 41.7%;
              align-self: flex-start;
              transition: var(--card-image-clip-transition);
              clip-path: var(--card-image-clip-path);
  
              & [data-main-image] {
                transition: var(--card-image-scale-transition);
                transform: translateZ(0) scale(var(--card-image-scale));
              }
            `,
            imageReveal,
          ]}
        >
          <VWImage {...{ image }} alt={title} />
        </div>
        <div
          css={css`
            flex: 0 0 53.9%;
          `}
        >
          {surtitle && (
            <span
              css={[
                fluidText13(theme),
                css`
                  display: block;
                  margin-bottom: ${5 / 13}em;
                  color: ${theme.colors.grey};

                  ${theme.bp.tablet} {
                    margin-top: ${theme.spacer(1.25)};
                    margin-bottom: 0;
                  }

                  ${theme.bp.desktop} {
                    padding-left: ${paddingPercent}%;
                    padding-right: ${paddingPercent * 2}%;  
                  }
                `,
                isTablet ? surtitleReveal : imageReveal,
              ]}
            >
              {surtitle}
            </span>
          )}
          <span
            css={[
              css`
                display: block;
                color: ${theme.colors.black};
                text-decoration: underline;
                text-underline-offset: 0.32em;
                text-decoration-thickness: ${1.8 / 16}em;
                text-decoration-color: var(--title-text-decoration-color);
                transition: text-decoration-color 200ms ease;

                ${theme.bp.tablet} {
                  margin-top: ${6 / 16}em;
                }

                ${theme.bp.desktop} {
                  padding-left: ${paddingPercent}%;
                  padding-right: ${paddingPercent * 2}%;  
                }
              `,
              fluidCardHeading16(theme),
              isTablet ? titleReveal : imageReveal,
            ]}
          >
            {title}
          </span>
        </div>
      </VWLink>
    </li>
  );
};

ContentListThreeRowCard.propTypes = {
  image: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  surtitle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
