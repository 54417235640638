/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { ChevronSVG } from './ChevronSVG';
import { fluidText13 } from '../composableStyles/text';

const promptWidthPx = 91;
const promptHeightPx = 85;

const clipPathInitial = 'M50.55788,83.69067C25.61623,86.75312,3.13267,70.794.33945,48.04506S15.50108,4.37179,40.44272,1.30934,87.86793,14.206,90.66115,36.95495,75.49953,80.62822,50.55788,83.69067Z';
const clipPathDragging = 'M50.05212,79.5716C27.60464,82.32781,7.36943,67.9646,4.85553,47.49055S18.501,8.18461,40.94848,5.42841s42.68268,11.607,45.19658,32.081S72.4996,76.8154,50.05212,79.5716Z';

// Based on https://codesandbox.io/s/2b4sd?file=/src/App.js:1529-1714
export const ImageSliderDragPrompt = ({
  x, y, isVisible, isDragging,
}) => {
  const theme = useTheme();
  const dragRef = useRef();

  const promptWidth = theme.pxToRem(promptWidthPx);
  const promptHeight = theme.pxToRem(promptHeightPx);

  const correctedPosition = {
    x: x - (promptWidthPx / 2),
    y: y - (promptHeightPx / 2),
  };

  return (
    <div
      css={css`
        pointer-events: none;
        position: absolute;
        z-index: 5001;
        top: 0;
        left: 0;
        transform: translate(${correctedPosition.x}px, ${correctedPosition.y}px);
      `}
    >
      <div
        ref={dragRef}
        aria-hidden="true"
        css={[
          css`
            width: ${promptWidth}rem;
            height: ${promptHeight}rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${theme.colors.black}${isDragging ? 'fc' : 'e6'};
            color: ${theme.colors.pink};
            clip-path: path('${isDragging ? clipPathDragging : clipPathInitial}');
            transform: scale(${isVisible ? 1 : 0});
            opacity: ${isVisible ? 1 : 0};
            transition: background-color 100ms linear, 
              clip-path 100ms linear,
              transform 100ms ease-out,
              opacity 100ms linear;
          `,
          fluidText13(theme),
        ]}
      >
        <ChevronSVG
          height={11}
          direction="left"
          css={css`
            transition: transform 100ms linear;
            transform: translateX(${isDragging ? '2px' : '0'});
          `}
        />
        <span
          css={css`
            margin: 0 ${5 / 13}em;
            color: ${theme.colors.white}
          `}
        >
          Drag
        </span>
        <ChevronSVG
          height={11}
          direction="right"
          css={css`
            transition: transform 100ms linear;
            transform: translateX(${isDragging ? '-3px' : '0'});
          `}
        />
      </div>
    </div>
  );
};

ImageSliderDragPrompt.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isDragging: PropTypes.bool.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};
