/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { LIGHT_NAV_COLOR, NAV_COLOR_ATTRIBUTE_NAME } from '../utilities/constants';
import { useWindowSize } from '../hooks/useWindowSize';
import { HeaderBanner } from './HeaderBanner';

export const HeaderNav = props => {
  const theme = useTheme();
  const [isDark, setIsDark] = useState(true);
  const { height } = useWindowSize();
  const navRef = useRef();

  useEffect(() => {
    let darkThemedElements;
    let observer;

    if (height && navRef.current) {
      // add intersection observers
      // eslint-disable-next-line no-undef
      darkThemedElements = [...document.querySelectorAll(`[${NAV_COLOR_ATTRIBUTE_NAME}="${LIGHT_NAV_COLOR}"]`)];

      const { top, bottom } = navRef.current.getBoundingClientRect();

      const options = {
        rootMargin: `${-1 * top}px 0px ${bottom - height}px 0px`,
        threshold: [0.0],
      };

      // eslint-disable-next-line no-undef
      observer = new IntersectionObserver(
        entries => {
          if (entries.find(({ isIntersecting }) => isIntersecting)) {
            setIsDark(false);
          } else {
            setIsDark(true);
          }
        },
        options,
      );

      darkThemedElements.forEach(elem => {
        observer.observe(elem);
      });
    }

    return () => {
      if (observer && Array.isArray(darkThemedElements)) {
        // remove intersection observers
        darkThemedElements.forEach(elem => {
          observer.unobserve(elem);
        });
      }
    };
  }, [height]); // eslint-disable-line react-hooks/exhaustive-deps

  const colorCustomProperties = css`
    --vw-logo-color: ${theme.colors[isDark ? 'white' : 'black']};
    --vw-logo-background: ${theme.colors[isDark ? 'black' : 'white']};
  `;

  return (
    <HeaderBanner
      ref={navRef}
      isNav
      {...props}
      css={colorCustomProperties}
    />
  );
};
