/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { vh } from '../composableStyles/a11y';

export const VisuallyHidden = ({ TagName, children }) => (
  <TagName css={vh}>{children}</TagName>
);

VisuallyHidden.propTypes = {
  TagName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

VisuallyHidden.defaultProps = {
  TagName: 'span',
};
