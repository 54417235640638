/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { container } from '../composableStyles/layout';
import { DecorativeDots } from './DecorativeDots';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { vwLinkPropTypes } from './VWLink';
import { marginBottom } from '../composableStyles/marginBottom';
import { HomePageHeroMedia } from './HomePageHeroMedia';
import { TABLET_BP, WIDEST_BP } from '../composableStyles/theme';
import { HomePageHeroHeadlines } from './HomePageHeroHeadlines';
import { DARK_COMPONENT_THEME, DOTS_ENTER_STEP, VIDEO_ENTER_STEP } from '../utilities/constants';
import { HomePageHeroIntro } from './HomePageHeroIntro';
import { HomePageHeroButton } from './HomePageHeroButton';
import { HomePageHeroScrollPrompt } from './HomePageHeroScrollPrompt';
import { MediaQueryContext } from '../contexts/MediaQueryProvider';
import { fluidScale } from '../composableStyles/fluidScale';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';

/*
  Mobile:
  image aspect ratio: 360x315
  Adjusted aspect ratio to fit hero on screen
  gradient overlay: 360x120 aligned to bottom -
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);

  Desktop:
  video aspect ratio 937*660
  video width is 937 when screen is 1440,
  width is 65%
  height is 660 / 1440 => 45% of screen width
  gradient overlay: 714x660, aligned to left
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%);

*/

const initialDelay = 400;

const headlineDuration = 280;
const headlineReadDuration = 1050;
const headlineStagger = 120;

const videoBackgroundDuration = 600;
const videoDelay = 470;
const videoDuration = 400;
const dotsDuration = 500;
const dotsDelay = 650;

const introDuration = 600;
const buttonDelay = 800;
const buttonDuration = 600;
const scrollPromptDelay = buttonDelay + buttonDuration;
const scrollPromptDuration = 590;

const stepTimings = [
  initialDelay, // wait -> h one enter
  headlineDuration + headlineStagger + headlineReadDuration, // wait -> h one exit / h two enter
  710, // wait -> video slides in (background, video & dots) (wait for video to be able to play)
  0, // dummy value for video step
  dotsDelay, // wait -> Dots
  450, // wait -> intro & button content reveal (these are chained of one step change)
];

const cssCustomProperties = css`
  --headline-duration: ${headlineDuration}ms;
  --headline-reading: ${headlineReadDuration}ms;
  --headline-stagger: ${headlineStagger}ms;
  --dots-delay: ${dotsDelay}ms;
  --button-delay: ${buttonDelay}ms;
  --scroll-prompt-delay: ${scrollPromptDelay}ms;
  --video-background-duration: ${videoBackgroundDuration}ms;
  --video-delay: ${videoDelay}ms;
  --video-duration: ${videoDuration}ms;
  --intro-duration: ${introDuration}ms;
  --button-duration: ${buttonDuration}ms;
  --scroll-prompt-duration: ${scrollPromptDuration}ms;
  --dots-duration: ${dotsDuration}ms;

  --video-background-z-index: 5;
  --video-z-index: 6;
  --video-overlay-z-index: 7;
  --dots-z-index: 9;
  --content-z-index: 10;
`;

export const HomePageHero = ({
  headlineOne,
  headlineTwo,
  intro,
  button,
  spaceBelow,
  mobileImage,
}) => {
  const theme = useTheme();
  const { isTablet } = useContext(MediaQueryContext);
  const [step, setStep] = useState(0);
  const widestWidthRem = `${theme.pxToRem(WIDEST_BP)}rem`;

  const advanceAnimation = () => {
    setStep(step + 1);
  };

  useEffect(() => {
    let timerId;

    if (step < stepTimings.length && step !== VIDEO_ENTER_STEP) {
      timerId = setTimeout(
        advanceAnimation,
        stepTimings[step],
      );
    }

    return () => clearTimeout(timerId);
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <header
      {...addNavColorAttribute(DARK_COMPONENT_THEME)}
      css={[
        css`
          position: relative;
          width: 100%;
          background-color: ${theme.colors.black};
          padding-left: var(--container-grid-padding);
          padding-top: ${theme.pxToRem(90)}rem;
          padding-bottom: ${theme.pxToRem(20)}rem;  
          min-height: calc(100vh - ${theme.pxToRem(32)}rem);
          
          ${theme.bp.tablet} {
            padding-left: 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: ${theme.pxToRem(120)}rem;
            padding-bottom: ${theme.pxToRem(120)}rem;  
          }
        `,
        marginBottom(theme, spaceBelow),
        cssCustomProperties,
      ]}
    >
      <HomePageHeroMedia
        {...{ step, advanceAnimation, mobileImage }}
        css={css`
          ${theme.bp.tablet} {
            flex: 0 0 65%;
            padding-left: 0;
          }

          ${theme.bp.wide} {
            /* 
            flex-basis: 56%;
            margin-right: 14%; 
            */
            flex-basis: 70%;
            margin-right: 0;
          }
          
          ${theme.bp.widest} {
            /* 
            flex-basis: ${theme.pxToRem(WIDEST_BP * 0.56)}rem;
            margin-right: calc(((100% - ${widestWidthRem}) / 2) + (0.14 * ${widestWidthRem})); 
            */
            flex-basis: 101rem;
            margin-right: calc(((100% - ${widestWidthRem}) / 2) + 4rem;
          }
        `}
      />
      <div
        css={css`
          position: relative;
          z-index: var(--content-z-index);
          color: ${theme.colors.white};

          ${theme.bp.tablet} {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
          }
        `}
      >
        <div
          css={[
            container(theme, 22),
            css`
            ${fluidScale({
              minPx: 36,
              maxPx: 76,
              minMqPx: 320,
              maxMqPx: TABLET_BP,
              cssPropertyName: '--hero-content-offset',
            })}
              transform: translateY(calc(-1 * var(--hero-content-offset)));
              
              ${theme.bp.tablet} {
                transform: translateY(0);
              }
            `,
          ]}
        >
          <HomePageHeroHeadlines {...{ headlineOne, headlineTwo, step }} />
          {intro && <HomePageHeroIntro {...{ intro, step }} />}
          {button && <HomePageHeroButton {...{ button, step }} />}
          {isTablet && <HomePageHeroScrollPrompt {...{ step }} />}
        </div>
      </div>
      {step >= DOTS_ENTER_STEP && isTablet && (
        <DecorativeDots
          dotsX={5}
          dotsY={9}
          css={css`
              position: absolute;
              z-index: var(--dots-z-index);
              right: var(--container-grid-padding);
              top: calc(50% + ${65 * (660 / 937) * 0.5}vw);
              transform: translateY(-66%);
              
              ${theme.bp.wide} {
                top: calc(50% + ${56 * (660 / 937) * 0.5}vw);
                right: 18%;
              }
              
              ${theme.bp.widest} {
                top: calc(50% + (${(660 / 937) * 0.5} * ${theme.pxToRem(WIDEST_BP * 0.56)}rem));
                right: calc(((100% - ${widestWidthRem}) / 2) + (0.18 * ${widestWidthRem}));
              }
              
              & svg {
                fill: ${theme.colors.white};
              }
            `}
          isVisible
        />
      )}
    </header>
  );
};

HomePageHero.propTypes = {
  headlineOne: PropTypes.string,
  headlineTwo: PropTypes.string,
  intro: PropTypes.node,
  button: PropTypes.oneOfType([
    PropTypes.shape(vwLinkPropTypes),
    PropTypes.bool,
  ]),
  spaceBelow: PropTypes.string,
  mobileImage: PropTypes.shape({}),
};

HomePageHero.defaultProps = {
  headlineOne: '',
  headlineTwo: '',
  intro: '',
  button: false,
  spaceBelow: spaceBelowDefaultValue,
  mobileImage: null,
};
