import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { InlineImages } from '../components/InlineImages';
import { mapDecorationValues } from '../utilities/mapDecorationValues';
import { mapBackgroundValues } from '../utilities/mapBackgroundValues';

const displayProps = {
  grid: 'grid',
  slider: 'slider',
};

const frameValues = {
  full: 'full',
  inset: 'inset',
};

const widthValues = {
  '24 Column (Default)': 24,
  '20 Column (News Post)': 20,
};

const getPropKeys = (propValues, lookup) => {
  const fnIsValid = ([, value]) => {
    const lookupLower = lookup.toLowerCase();

    return value === lookupLower;
  };
  const prop = Object.entries(propValues).find(fnIsValid);
  if (!prop) {
    return null;
  }

  const [propKey] = prop;

  return propKey;
};

export const InlineImagesSlice = ({ slice }) => {
  const {
    primary: {
      background_colour: bgColour,
      space_below: spaceBelow,
      dotted_background: dottedBackground,
      display: displayValue,
      frame: frameValue,
      width: widthValue,
    },
    items,
  } = slice;
  const componentTheme = mapBackgroundValues(bgColour);
  const display = getPropKeys(displayProps, displayValue);
  const frame = getPropKeys(frameValues, frameValue);
  const decoration = mapDecorationValues(dottedBackground);
  const images = items.map(({ image }) => image);
  const width = widthValues[widthValue];

  return (
    <InlineImages
      {...{
        display,
        frame,
        decoration,
        componentTheme,
        spaceBelow,
        images,
        width,
      }}
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyInlineImagesBlock on PrismicLandingPageDataBodyInlineImages {
    primary {
      background_colour
      display
      width
      dotted_background
      frame
      space_below
    }
    items {
      image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
    }
  }
  fragment CaseStudyDataBodyInlineImagesBlock on PrismicCaseStudyDataBodyInlineImages {
    primary {
      background_colour
      display
      width
      dotted_background
      frame
      space_below
    }
    items {
      image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
    }
  }
  fragment NewsPostDataBodyInlineImagesBlock on PrismicNewsPostDataBodyInlineImages {
    primary {
      background_colour
      display
      width
      dotted_background
      frame
      space_below
    }
    items {
      image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
    }
  }
  fragment HomepageDataBodyInlineImagesBlock on PrismicHomepageDataBodyInlineImages {
    primary {
      background_colour
      display
      width
      dotted_background
      frame
      space_below
    }
    items {
      image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
    }
  }
`;

InlineImagesSlice.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      background_colour: PropTypes.string,
      display: PropTypes.string,
      dotted_background: PropTypes.string,
      frame: PropTypes.string,
      space_below: PropTypes.string,
      width: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          gatsbyImageData: PropTypes.shape({
            images: PropTypes.shape({
              width: PropTypes.number,
              height: PropTypes.number,
              fallback: PropTypes.shape({
                src: PropTypes.string,
                srcSet: PropTypes.string,
              }),
            }),
          }),
          alt: PropTypes.string,
        }),
      }),
    ),
  }).isRequired,
};
