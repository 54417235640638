import React from 'react';
import { css, Global, withTheme } from '@emotion/react';
import { miniReset } from '../composableStyles/miniReset';
import { fluidText18, tabletScaleFactor, widestScaleFactor } from '../composableStyles/text';
import { fluidScale } from '../composableStyles/fluidScale';
import {
  DESKTOP_BP, TABLET_BP, WIDEST_BP, WIDE_BP, MOBILE_BP,
} from '../composableStyles/theme';
import { fullGridAsAProportionOfScreen, proportionOfGrid } from '../composableStyles/layout';
// import { easeInQuart } from '../composableStyles/easings';

const makeGlobalStyles = theme => css`
  @font-face {
    font-family: "Neometric";
    font-style: normal;
    font-weight: ${theme.font.weight.black};
    font-display: block;
    src: url("/fonts/Neometric-Black.woff2") format("woff2");
  }

  @font-face {
    font-family: "Neometric";
    font-style: normal;
    font-weight: ${theme.font.weight.extraBold};
    font-display: block;
    src: url("/fonts/Neometric-ExtraBold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Neometric";
    font-style: normal;
    font-weight: ${theme.font.weight.bold};
    font-display: block;
    src: url("/fonts/Neometric-Bold.woff2") format("woff2");
  }

  :root {
    /* reach variables disable warning re. not including reach styles https://reach.tech/styling/ */
    --reach-skip-nav: 1;
    --reach-accordion: 1;
    --reach-dialog: 1;

    /* Default colors for the vw logo svg */
    --vw-logo-color: ${theme.colors.white};
    --vw-logo-background: ${theme.colors.black};

    /* Default card hover properties */
    --card-image-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    --card-image-clip-transition: clip-path 400ms ease-in-out 0ms;
    --card-image-scale: 1;
    --card-image-scale-transition: transform 400ms ease-in-out 0ms;
    
    /* Container grid padding - for use with theme.layout.container  */
    ${fluidScale({
    minPx: 15,
    maxPx: 30,
    minMqPx: MOBILE_BP,
    maxMqPx: TABLET_BP,
    cssPropertyName: '--container-grid-padding',
  })}

    ${theme.bp.tablet} {
      ${fluidScale({
    minPx: 30,
    maxPx: 50,
    minMqPx: TABLET_BP,
    maxMqPx: DESKTOP_BP,
    cssPropertyName: '--container-grid-padding',
  })}
    }

    ${theme.bp.wide} {
      --container-grid-padding: 0;
    }
    /* End Container grid padding  */

    /* Scaled vertical spacing  */    
    ${fluidScale({
    minPx: 0.6,
    maxPx: 0.6 * tabletScaleFactor,
    minMqPx: MOBILE_BP,
    maxMqPx: TABLET_BP,
    cssPropertyName: '--vertical-space-unit',
  })}
  
      ${theme.bp.tablet} {
        ${fluidScale({
    minPx: 0.8,
    maxPx: 1,
    minMqPx: TABLET_BP,
    maxMqPx: WIDE_BP,
    cssPropertyName: '--vertical-space-unit',
  })}
      }

    ${theme.bp.wide} {
      ${fluidScale({
    minPx: 1,
    maxPx: 1 * widestScaleFactor,
    minMqPx: WIDE_BP,
    maxMqPx: WIDEST_BP,
    cssPropertyName: '--vertical-space-unit',
  })}
}
  /* End Scaled vertical spacing  */

  /* Left/Right spacing to align with 22 col container */
  --container-grid-22-align: var(--container-grid-padding);

  ${theme.bp.desktop} {
    --container-grid-22-align: max(
      var(--container-grid-padding), 
      (100% - ${theme.layout.maxWidthColsPx(22)}px) / 2
    );
  }
  
  ${theme.bp.wide} {
    --container-grid-22-align: ${(1 - fullGridAsAProportionOfScreen * proportionOfGrid(theme, 22)) * 50}%;
  }

  ${theme.bp.widest} {
    --container-grid-22-align: calc((100% - ${theme.pxToRem(WIDEST_BP * fullGridAsAProportionOfScreen * proportionOfGrid(theme, 22))}rem)/2);
  }
  /* End Left/Right spacing to align with 22 col container */
}

  body {
    color: ${theme.colors.greyDark};
    background-color: ${theme.colors.white};
    -webkit-font-smoothing: antialiased !important;
    font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    ${fluidText18(theme)}    
  }

  fieldset {
    border: none;
  }

  p {
    margin-bottom: 1em;
  }

  ul {
    line-height: 1;
  }

  *:focus {
    outline: 0;
  } /* sad */

  a {
    text-decoration: none;
    transition: color ${theme.transitions.link};
    color: ${theme.colors.pink};

    @media (hover: hover) {
      &:hover {
        color: ${theme.colors.pink};
      }
    }
  }

  address {
    font-style: normal;
  }

  hr {
    width: 100%;
    height: 1px;
    margin: ${theme.spacer()} 0;
    background-color: ${theme.colors.greyDark};
  }

  button,
  input[type="submit"] {
    -webkit-appearance: none; /* avoid iOS Safari button styling */
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    transition: all ${theme.transitions.link} ease-out;

    @media (hover: hover) {
      &:not([disabled]):hover {
        cursor: pointer;
      }
    }
  }
`;

export const GlobalStyles = withTheme(({ theme }) => (
  <Global styles={[miniReset, makeGlobalStyles(theme)]} />
));
