import { css } from '@emotion/react';
import {
  fluidHeading34, fluidHeading28, fluidHeading22, fluidHeading20, fluidHeading18,
} from './text';

export const textEditor = theme => css`
  h2, h3 {
    color: ${theme.colors.black};
  }

  h2 {
    ${fluidHeading28(theme)}
    margin-bottom: .9em;

    &.bigger {
      ${fluidHeading34(theme)}
    }

    &.smaller {
      ${fluidHeading22(theme)}
    }
  }

  h3 {
    ${fluidHeading20(theme)}
    margin-bottom: .75em;

    &.bigger {
      ${fluidHeading22(theme)}
    }

    &.smaller {
      ${fluidHeading18(theme)}
    }
  }

  * + h2,
  * + h3 {
    margin-top: ${theme.spacer(2.5)};
  }

  a {
    color: ${theme.colors.greyDark};
    background-image: linear-gradient(
      ${theme.colors.pink},
      ${theme.colors.pink}
    );
    background-position: 0 1.16em;
    background-repeat: repeat-x;
    background-size: 1px 100%;
    transition: all 0.2s ease;

    @media (hover: hover) {
      &:hover {
        background-position-y: 0;
      }
    }

    &:focus-visible {
      background-position-y: 0;
    }
  }

  h2,
  h3 {
    & a {
      text-underline-offset: 0.05em;
    }
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;
