/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import Flickity from 'react-flickity-component';
import 'flickity/dist/flickity.min.css';
import PropTypes from 'prop-types';
import { VWImage } from './VWImage';

const dotSizePx = 6;

const flickityStyles = theme => css`
  & .flickity-viewport {
    cursor: none !important;
  }

  & .flickity-page-dots {
    display: flex;
    justify-content: center;
    bottom: calc((100vw - (2 * var(--container-grid-padding))) * -0.08);

    ${theme.bp.wide} {
      bottom: -8vw;
    }
    
    ${theme.bp.widest} {
      bottom: ${theme.pxToRem(-192)}rem;
    }
  }

  & .flickity-page-dots .dot {
    width: ${dotSizePx / 18}em;
    height: ${dotSizePx / 18}em;
    background-color: ${theme.colors.greyLighter};
    margin: 0 ${dotSizePx / 18}em;
  }
  
  & .flickity-page-dots .dot.is-selected {
    background-color: ${theme.colors.black};
  }
`;

/*
  Image specs from design

  landscape image(2400x1680):
    mobile:
    desktop:

  portrait image(1280x1680):
    mobile:
    desktop:
*/

const ImageSlider = ({
  images,
  handleMouseMove,
  handleMouseDown,
  handleMouseUp,
  loading,
  isInset,
}) => {
  const theme = useTheme();

  const options = {
    cellAlign: isInset ? 'center' : 'left',
    prevNextButtons: false,
    pageDots: isInset,
    contain: !isInset,
    freeScroll: !isInset,
    freeScrollFriction: 0.04, // Default 0.075
    on: {
      pointerMove: handleMouseMove,
      pointerDown: handleMouseDown,
      pointerUp: handleMouseUp,
    },
  };

  const slideStyles = isInset
    ? css`
      width: 80%;
      margin-right: 7%;
      ${theme.shadow.image.default}
      margin-top: 30px;
      margin-bottom: 30px;
      
      & > div {
        max-width: none !important;
      }
      
      & img {
        height: auto;
        width: 100%;
      }
    `
    : css`
      width: auto;
      height: 49vw;
      margin-right: ${19 / 18}em;

      &:first-of-type {
        padding-left: var(--container-grid-22-align);
        
        & img {
          left: auto;
        }
      }
    
      &:last-of-type {
        padding-right: var(--container-grid-22-align);
        margin-right: 0;

        & img {
          right: auto;
        }
      }

      & > div {
        max-width: none !important;
      }

      & img {
        height: 49vw;
        width: auto;
      }
    `;

  return (
    <Flickity
      {...{ options }}
      css={flickityStyles(theme)}
    >
      {
        images.map((image, i) => {
          return (
            <VWImage
              key={i} // eslint-disable-line react/no-array-index-key
              {...{ image, loading }}
              css={slideStyles}
            />
          );
        })
      }
    </Flickity>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleMouseMove: PropTypes.func,
  handleMouseDown: PropTypes.func,
  handleMouseUp: PropTypes.func,
  loading: PropTypes.string,
  isInset: PropTypes.bool.isRequired,
};

ImageSlider.defaultProps = {
  handleMouseMove: null,
  handleMouseDown: null,
  handleMouseUp: null,
  loading: null,
};

ImageSlider.displayName = 'ImageSlider';

export { ImageSlider };
