import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

// based on https://usehooks.com/useWindowSize/
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const isBrowser = typeof window !== 'undefined';

  useEffect(() => {
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth, // eslint-disable-line no-undef
        height: window.innerHeight, // eslint-disable-line no-undef
      });
    };

    const debouncedResize = debounce(
      handleResize,
      400,
      {
        leading: true,
        trailing: true,
      },
    );

    if (isBrowser) {
      // Add event listener
      window.addEventListener('resize', debouncedResize); // eslint-disable-line no-undef

      // Call handler right away so state gets updated with initial window size
      handleResize();
    }

    // Remove event listener on cleanup
    return () => {
      if (isBrowser) {
        window.removeEventListener('resize', debouncedResize); // eslint-disable-line no-undef
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return windowSize;
};
