/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import { PillButton } from './PillButton';
import { container } from '../composableStyles/layout';

const PageNotFound = () => {
  const theme = useTheme();

  return (
    <div
      css={[
        container(theme, 22),
        css`
          margin-bottom: ${theme.pxToRem(120)}rem;

          ${theme.bp.tablet} {
            margin-bottom: ${theme.pxToRem(200)}rem;
          }
        `,
      ]}
    >
      <p
        css={css`
          margin-bottom: ${theme.pxToRem(55)}rem;
          max-width: 80ch;
        `}
      >
        We commend your thirst for exploring beyond the boundaries but sometimes
        it&apos;s best to accept you are lost, turn 180&deg; and head back in-bounds.
      </p>
      <PillButton
        label="Return Home"
        url="/"
      />
    </div>
  );
};

export { PageNotFound };
