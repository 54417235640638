/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { VWLink } from './VWLink';
import { VWImage } from './VWImage';
import { DecorativeDots } from './DecorativeDots';
import { fluidText13, fluidHeading22 } from '../composableStyles/text';
import {
  decorationDefaultValue, decorationPropTypes,
} from '../utilities/componentPropTypes';
import { cardRevealAnimation } from '../composableStyles/cardRevealAnimation';
import { useComponentReveal } from '../hooks/useComponentReveal';
import { VWArtDirectedImage } from './VWArtDirectedImage';
import { TABLET_BP } from '../composableStyles/theme';
import { easeOutQuart } from '../composableStyles/easings';

const decorationStyles = {
  topLeft: css`
    top: -80px;
    left: -90px;
  `,
  bottomLeft: css`
    bottom: -80px;
    left: -90px;
  `,
  topRight: css`
    top: -126px;
    right: -200px;
  `,
  bottomRight: css`
    bottom: -5px;
    right: -127px;
  `,
};

export const ContentListTwoRowCard = ({
  image,
  imageMobile,
  title,
  surtitle,
  url,
  isFullWidth,
  decoration,
  ...otherProps
}) => {
  const theme = useTheme();
  const { ref, isRevealed } = useComponentReveal();
  const {
    imageReveal,
    surtitleReveal,
    titleReveal,
  } = cardRevealAnimation(isRevealed);

  // useMemo used to prevent image flickering when isRevealed updates
  const cardImage = useMemo(
    () => {
      if (imageMobile) {
        const images = [
          {
            aspectRatio: `${imageMobile.gatsbyImageData.width} / ${imageMobile.gatsbyImageData.height}`,
            image: imageMobile.gatsbyImageData,
            media: null,
          },
          {
            aspectRatio: `${image.gatsbyImageData.width} / ${image.gatsbyImageData.height}`,
            image: image && image.gatsbyImageData,
            media: `(min-width: ${TABLET_BP}px)`,
          },
        ];

        return <VWArtDirectedImage {...{ images }} />;
      }
      return <VWImage {...{ image }} />;
    },
    [imageMobile, image],
  );

  const paddingPercentBase = 2;
  const paddingPercent = isFullWidth ? (45 / 100) * paddingPercentBase : paddingPercentBase;
  const clipLower = `${paddingPercent}%`;
  const clipUpper = `${100 - paddingPercent}%`;
  const clipPath = `polygon(${clipLower} ${clipLower}, ${clipUpper} ${clipLower}, ${clipUpper} ${clipUpper}, ${clipLower} ${clipUpper})`;

  return (
    <li
      {...{ ref }}
      css={css`
        margin-bottom: ${50 / 18}em;
        position: relative;

        &:last-of-type {
          margin-bottom: 0;
        }

        ${theme.bp.tablet} {
          flex-basis: ${isFullWidth ? '100%' : '45%'};
          margin-bottom: 0;
        }
      `}
      {...otherProps}
    >
      <VWLink
        {...{ url }}
        css={css`
          display: block;
          --title-text-decoration-color: ${theme.colors.pink}00;

          @media (hover: hover) {
            &:hover {
              --card-image-scale: 1.1;
              --card-image-scale-transition: transform 600ms ${easeOutQuart} 0ms;
              
              ${theme.bp.tablet} {
                --card-image-clip-path: ${clipPath};
                --card-image-clip-transition: clip-path 600ms ${easeOutQuart} 0ms;
              }
            }
          }

          :focus-visible {
            --title-text-decoration-color: ${theme.colors.pink}FF;
            --card-image-scale: 1.1;
            --card-image-scale-transition: transform 600ms ${easeOutQuart} 0ms;

            ${theme.bp.tablet} {
                --card-image-clip-path: ${clipPath};
                --card-image-clip-transition: clip-path 600ms ${easeOutQuart} 0ms;
              }
          }
        `}
      >
        <div
          css={[
            imageReveal,
            css`
              transition: var(--card-image-clip-transition);
              clip-path: var(--card-image-clip-path);

              & [data-main-image] {
                transition: var(--card-image-scale-transition);
                transform: translateZ(0) scale(var(--card-image-scale));
              }
            `,
          ]}
        >
          {cardImage}
        </div>
        {surtitle && (
          <span
            css={[
              fluidText13(theme),
              css`
                display: block;
                margin-top: ${25 / 13}em;
                color: ${theme.colors.grey};

                ${theme.bp.tablet} {
                  padding-left: ${paddingPercent}%;
                  padding-right: ${paddingPercent * 2}%;  
                }
              `,
              surtitleReveal,
            ]}
          >
            {surtitle}
          </span>
        )}
        <span
          css={[
            css`
              display: block;
              margin-top: ${10 / 22}em;
              color: ${theme.colors.black};
              max-width: ${536 / 22}em;
              text-decoration: underline;
              text-underline-offset: 0.32em;
              text-decoration-color: var(--title-text-decoration-color);
              transition: text-decoration-color 200ms ease;

              ${theme.bp.tablet} {
                padding-left: ${paddingPercent}%;
                padding-right: ${paddingPercent * 2}%;
              }
            `,
            fluidHeading22(theme),
            titleReveal,
          ]}
        >
          {title}
        </span>
      </VWLink>
      {decoration !== decorationDefaultValue && (
        <DecorativeDots
          css={css`
            position: absolute;
            z-index: 1;
            ${decorationStyles[decoration] || ''}
            display: none;

            ${theme.bp.tablet} {
              display: block;
            }
          `}
        />
      )}
    </li>
  );
};

ContentListTwoRowCard.propTypes = {
  image: PropTypes.shape({
    gatsbyImageData: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }).isRequired,
  imageMobile: PropTypes.shape({
    gatsbyImageData: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }),
  title: PropTypes.string.isRequired,
  surtitle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isFullWidth: PropTypes.bool.isRequired,
  decoration: decorationPropTypes.isRequired,
};

ContentListTwoRowCard.defaultProps = {
  imageMobile: null,
};
