import React from 'react';
import { Elements } from 'prismic-reactjs';
import { linkResolver } from './linkResolver';

// -- Function to add unique key to props
const propsWithUniqueKey = (props, key) => {
  return Object.assign(props || {}, { key });
};

// -- HTML Serializer, based on https://prismic.io/docs/technologies/html-serializer-reactjs
// mainly used to provide different heading sizes unlinked from semantic elements
export const htmlSerializer = (type, element, content, children, key) => {
  const props = {};

  switch (type) {
    case Elements.heading1: // Heading 1
      props.className = 'bigger';
      return React.createElement(
        'h2',
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading2: // Heading 2
      // no classname as this is default styling
      return React.createElement(
        'h2',
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading3: // Heading 3
      props.className = 'smaller';
      return React.createElement(
        'h2',
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading4: // Heading 4
      props.className = 'bigger';
      return React.createElement(
        'h3',
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading5: // Heading 5
      // no classname as this is default styling
      return React.createElement(
        'h3',
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.heading6: // Heading 6
      props.className = 'smaller';
      return React.createElement(
        'h3',
        propsWithUniqueKey(props, key),
        children,
      );

    case Elements.hyperlink: // Links
      if (element.data.target) {
        props.target = element.data.target;
        props.rel = 'noopener';
      }
      props.href = element.data.url || linkResolver(element.data);
      return React.createElement('a', propsWithUniqueKey(props, key), children);

    default:
      // Always include a default that returns null
      return null;
  }
};
