import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { LinksList } from './LinksList';
import { EXTERNAL_LINK_TYPE } from '../utilities/constants';

export const ContactLinks = props => {
  const queryData = useStaticQuery(graphql`
    {
      prismicContactDetails {
        _previewable
        data {
          email
          phone_number
        }
      }
    }
  `);
  const { data } = useMergePrismicPreviewData(queryData);

  const { email, phone_number: phone } = data.prismicContactDetails.data;

  const links = [
    {
      label: email,
      url: `mailto:${email}`,
      type: EXTERNAL_LINK_TYPE,
    },
    {
      label: phone,
      url: `tel:${phone.replaceAll('.', '')}`,
      type: EXTERNAL_LINK_TYPE,
    },
  ];

  return <LinksList heading="Say Hello" {...{ links }} {...props} />;
};
