import React from 'react';
import PropTypes from 'prop-types';
import { BlogList } from '../components/BlogList';

export const FullUpdatesListSlice = ({ slice }) => {
  const {
    primary: { space_below: spaceBelow },
    items,
  } = slice;

  return <BlogList {...{ items, spaceBelow }} />;
};

FullUpdatesListSlice.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
      dotted_background: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
