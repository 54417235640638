/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import { VWLink } from './VWLink';
import { fluidScale } from '../composableStyles/fluidScale';
import { defaultLinkStyles } from '../composableStyles/defaultLinkStyles';
import { widestScaleFactor } from '../composableStyles/text';
import { WIDEST_BP, WIDE_BP } from '../composableStyles/theme';

const legalLinks = [
  {
    label: 'Careers',
    url: '/careers',
  },
  {
    label: 'Privacy',
    url: '/privacy-policy',
  },
];

const legalText = theme => css`
  color: ${theme.colors.grey};
  letter-spacing: ${0.2 / 11}em;
  line-height: ${13 / 11};
  ${fluidScale({
    minPx: 11, maxPx: 11 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}

  ${theme.bp.tablet} {
    text-align: right;
  }
`;

const navLink = theme => css`
  ${defaultLinkStyles(theme, true)}
`;

export const FooterLegal = () => {
  const theme = useTheme();

  const mq500 = theme.bp.min(500);

  return (
    <div
      css={css`
        ${mq500} {
          display: flex;
          align-items: baseline;
          justify-content: flex-end;
        }
      `}
    >
      <small
        css={[
          legalText(theme),
          css`
            display: block;
            margin-bottom: ${theme.spacer(0.5)};
            margin-right: auto;

            ${theme.bp.tablet} {
              margin-right: ${theme.pxToRem(40)}rem;
            }
          `,
        ]}
      >
        &copy;
        {' '}
        {new Date().getFullYear()}
        {' '}
        VentureWeb Design Ltd. All Rights Reserved.
      </small>
      <ul
        css={css`
          display: flex;

          ${theme.bp.tablet} {
            justify-content: flex-end;
          }
        `}
      >
        {legalLinks.map(({ label, url }) => (
          <li
            key={label}
            css={css`
              margin-left: ${theme.spacer(1.5)};
              margin-bottom: ${theme.spacer(0.5)};

              &:first-of-type {
                margin-left: 0;
              }
            `}
          >
            <VWLink
              {...{ label, url }}
              css={[legalText(theme), navLink(theme)]}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
