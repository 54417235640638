/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { container } from '../composableStyles/layout';
import { SectionHeader, sectionHeaderPropTypes } from './SectionHeader';
import {
  componentThemeOptions,
  componentBackground,
} from '../composableStyles/backgrounds';
import { marginBottom } from '../composableStyles/marginBottom';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';
import { TeamListCard } from './TeamListCard';

export const TeamList = ({
  header, componentTheme, players, spaceBelow,
}) => {
  const theme = useTheme();

  return (
    <section
      {...addNavColorAttribute(componentTheme)}
      css={[
        componentBackground(theme, componentTheme),
        marginBottom(theme, spaceBelow),
      ]}
    >
      <SectionHeader {...header} {...{ componentTheme }} />
      {players.length > 0 && (
        <ul
          css={css`
            ${container(theme, 22)}
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(136px, 1fr));
            row-gap: 40px;
            column-gap: 18px;
            
            ${theme.bp.tablet} {
              row-gap: 58px;
              grid-template-columns: repeat(auto-fill, minmax(234px, 1fr));
            }
            
            ${theme.bp.wide} {
              grid-template-columns: repeat(4, 1fr);
              column-gap: ${18 / 18}em;
              row-gap: ${58 / 18}em;
            }
          `}
        >
          {players.map((cardProps, i) => (
            <TeamListCard key={i} {...cardProps} />
          ))}
        </ul>
      )}
    </section>
  );
};

TeamList.propTypes = {
  header: PropTypes.shape(sectionHeaderPropTypes),
  componentTheme: PropTypes.oneOf(componentThemeOptions),
  players: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      role: PropTypes.string,
      image: PropTypes.shape({
        gatsbyImageData: PropTypes.shape({
          images: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
            fallback: PropTypes.shape({
              src: PropTypes.string,
              srcSet: PropTypes.string,
            }),
          }),
        }),
        alt: PropTypes.string,
      }),
    }),
  ),
  spaceBelow: PropTypes.string,
};

TeamList.defaultProps = {
  header: {},
  players: [],
  componentTheme: componentThemeOptions[0],
  spaceBelow: spaceBelowDefaultValue,
};
