/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

export const VWImage = ({
  image, url, alt, ...otherProps
}) => {
  const imageData = getImage(image);

  // @TODO review image styling and aspect ratio management / object-fit
  // might be in css but can set as props
  if (imageData) {
    const { alt: altFromImage } = image;
    return (
      <GatsbyImage
        image={imageData}
        alt={altFromImage || alt}
        css={css`
          width: 100%;
          height: auto;
        `}
        {...otherProps}
      />
    );
  }

  // fallback to url if passed @TODO this can likely be removed later
  if (url) {
    return (
      <img
        css={css`
          width: 100%;
          height: auto;
        `}
        src={url}
        {...{ alt }}
        {...otherProps}
      />
    );
  }

  return null;
};

export const vwImagePropTypes = {
  image: PropTypes.shape({
    gatsbyImageData: PropTypes.shape({
      images: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        fallback: PropTypes.shape({
          src: PropTypes.string,
          srcSet: PropTypes.string,
        }),
      }),
    }),
    alt: PropTypes.string,
  }),
  url: PropTypes.string,
  alt: PropTypes.string,
};

VWImage.propTypes = vwImagePropTypes;

VWImage.defaultProps = {
  image: {},
  url: '',
  alt: '',
};
