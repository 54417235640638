import {
  keyframes,
} from '@emotion/react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const cardSlideUp = keyframes`
  from {
    transform: translateY(40px);
  }
  
  to {
    transform: translateY(0);
  }
`;

const heroSlideUp = keyframes`
  from {
    transform: translateY(40px);
  }
  
  to {
    transform: translateY(0);
  }
`;

export {
  fadeIn,
  fadeOut,
  cardSlideUp,
  heroSlideUp,
};
