/** @jsx jsx */
import * as React from 'react';
import { jsx, css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { HeaderNav } from './HeaderNav';
import { HeaderMenuDialog, timingMs } from './HeaderMenuDialog';

// @TODO Test / handle rapid multiple clicks...
// @TODO Cross browser / device testing

const totalCloseDuration = timingMs.grey.close.delay + timingMs.grey.close.duration;

// eslint-disable-next-line no-undef
const getScrollbarWidth = () => window.innerWidth - document.documentElement.clientWidth;

export const Header = () => {
  const theme = useTheme();
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const openMenu = () => {
    setScrollbarWidth(getScrollbarWidth());

    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsClosing(true);

    setTimeout(
      () => {
        setIsOpen(false);
        setIsClosing(false);
        setScrollbarWidth(0);
      },
      totalCloseDuration,
    );
  };

  const handleMenuClick = () => {
    if (isOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const onDismiss = () => {
    closeMenu();
  };

  return (
    <React.Fragment>
      <header
        css={css`
          --scrollbar-width-for-offset: ${theme.pxToRem(scrollbarWidth)}rem;

          position: fixed;
          top: 0;
          left: 0;
          z-index: ${theme.zIndex.header.banner};
          width: 100%;
          height: auto;
          background-color: transparent;
          pointer-events: none;
        `}
      >
        <HeaderNav {...{ handleMenuClick }} />
      </header>
      <HeaderMenuDialog
        {...{
          isOpen, isClosing, handleMenuClick, onDismiss,
        }}
        css={css`
          --scrollbar-width-for-offset: ${theme.pxToRem(scrollbarWidth)}rem;
        `}
      />
    </React.Fragment>
  );
};
