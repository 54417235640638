/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { WysiwygText } from './WysiwygText';
import { VWImage } from './VWImage';
import { SectionHeading } from './SectionHeading';
import { container } from '../composableStyles/layout';
import { fluidHeading90 } from '../composableStyles/text';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { marginBottom } from '../composableStyles/marginBottom';
import { aspectRatio } from '../composableStyles/aspectRatio';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';
import { DARK_COMPONENT_THEME, WHITE_COMPONENT_THEME } from '../utilities/constants';
import { VWArtDirectedImage } from './VWArtDirectedImage';
import { TABLET_BP } from '../composableStyles/theme';
import { heroRevealAnimation } from '../composableStyles/heroRevealAnimation';

const {
  surtitleReveal,
  titleReveal,
} = heroRevealAnimation;

/*
  Image
    Desktop asset: 2880x1440
    Mobile asset: 1440x1520
*/

const textColors = {
  Dark: 'black',
  Light: 'white',
};

const textColorKeys = Object.keys(textColors);

export const HeroFullImage = ({
  title,
  text,
  image,
  spaceBelow,
  textColor,
}) => {
  const theme = useTheme();
  const color = theme.colors[textColors[textColor]];

  const main = image;
  const mobile = image && image.thumbnails && image.thumbnails.mobile;

  const images = !!mobile && [
    {
      aspectRatio: `${mobile.gatsbyImageData.width} / ${mobile.gatsbyImageData.height}`,
      image: mobile && mobile.gatsbyImageData,
      media: null,
    },
    {
      aspectRatio: `${main.gatsbyImageData.width} / ${main.gatsbyImageData.height}`,
      image: main && main.gatsbyImageData,
      media: `(min-width: ${TABLET_BP}px)`,
    },
  ];

  const imageProps = {
    loading: 'eager',
    css: css`
      height: 100%;
      z-index: 1;
    `,
    alt: title,
  };

  return (
    <header
      {...addNavColorAttribute(textColor === 'Dark' ? WHITE_COMPONENT_THEME : DARK_COMPONENT_THEME)}
      css={[
        marginBottom(theme, spaceBelow),
        css`
          position: relative;
        `,
      ]}
    >
      <div
        css={[
          aspectRatio(1440, 1520),
          css`
            width: 100%;

            &::before {
              padding-top: min(${(1520 / 1440) * 100}%, (100vh - 32px));

              ${theme.bp.tablet} {
                padding-top: min(${(1440 / 2880) * 100}%, (100vh - 32px));
              }
            }
          `,
        ]}
      >
        {
          images
            ? <VWArtDirectedImage {...{ images }} {...imageProps} />
            : <VWImage image={main} {...imageProps} />
        }
      </div>
      <h1
        css={css`
          position: absolute;
          z-index: 3;
          width: 100%;
          bottom: 0px;
          padding-bottom: ${theme.scaledVerticalSpacing(85)};
        `}
      >
        <div
          css={[
            container(theme),
            css`
              margin-bottom: ${theme.scaledVerticalSpacing(11)};
            `,
            surtitleReveal,
          ]}
        >
          <SectionHeading
            level="span"
            {...{ color }}
          >
            {title}
          </SectionHeading>
        </div>
        <WysiwygText
          {...{ text }}
          css={[
            container(theme, 22),
            fluidHeading90(theme),
            css`
              ${(theme)}
              color: ${color};

              & > * {
                max-width: ${theme.layout.maxWidthCols(15)};
                margin-bottom: 0;
              }
            `,
            titleReveal,
          ]}
        />
      </h1>
    </header>
  );
};

HeroFullImage.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.shape({
    thumbnails: PropTypes.shape({
      mobile: PropTypes.shape({}),
    }),
  }).isRequired,
  spaceBelow: PropTypes.string,
  textColor: PropTypes.oneOf(textColorKeys),
};

HeroFullImage.defaultProps = {
  spaceBelow: spaceBelowDefaultValue,
  textColor: textColorKeys[0],
};
