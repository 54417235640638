import { css } from '@emotion/react';
import { easeOutQuart } from './easings';
import { fadeIn, cardSlideUp } from './keyframes';

const imageDelay = 0;
const surtitleDelay = 200;
const titleDelay = 250;

const imageDuration = 600;
const surtitleDuration = 600;
const titleDuration = 600;

const initialStyles = css` 
  opacity: 0;
  transform: translateY(40px);
`;

export const cardRevealAnimation = isRevealed => {
  return {
    imageReveal: isRevealed
      ? css`
        animation: ${cardSlideUp} ${imageDuration}ms ${easeOutQuart} ${imageDelay}ms 1 both,
          ${fadeIn} ${imageDuration}ms linear ${imageDelay}ms 1 both;
      `
      : initialStyles,
    surtitleReveal: isRevealed
      ? css`
        animation: ${cardSlideUp} ${surtitleDuration}ms ${easeOutQuart} ${surtitleDelay}ms 1 both,
          ${fadeIn} ${surtitleDuration}ms linear ${surtitleDelay}ms 1 both;
      `
      : initialStyles,
    titleReveal: isRevealed
      ? css`
        animation: ${cardSlideUp} ${titleDuration}ms ${easeOutQuart} ${titleDelay}ms 1 both,
          ${fadeIn} ${titleDuration}ms linear ${titleDelay}ms 1 both;
      `
      : initialStyles,

  };
};
