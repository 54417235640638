import { css } from '@emotion/react';

// https://chriskirknielsen.com/blog/modern-fluid-typography-with-clamp/
export const fluidScale = ({
  minPx,
  maxPx,
  minMqPx = 320,
  maxMqPx = 1440,
  cssPropertyName = 'font-size',
}) => {
  const factor = (1 / (maxMqPx - minMqPx)) * (maxPx - minPx);
  const minValue = `${(minPx > maxPx ? maxPx : minPx) / 16}rem`;
  const maxValue = `${(minPx > maxPx ? minPx : maxPx) / 16}rem`;
  const calcValue = `${minValue} - ${
    (minMqPx / 16) * factor
  }rem + 100vw * ${factor}`;

  return css`
    ${cssPropertyName}: ${minValue};

    @supports (font-size: clamp(1rem, 1rem, 1rem)) {
      ${cssPropertyName}: clamp(${minValue}, ${calcValue}, ${maxValue});
    }
  `;
};
