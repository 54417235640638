/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { WysiwygText } from './WysiwygText';
import { container } from '../composableStyles/layout';
import {
  fluidHeading36, fluidText13, tabletScaleFactor, widestScaleFactor,
} from '../composableStyles/text';
import {
  componentThemeOptions,
  componentBackground,
} from '../composableStyles/backgrounds';
import { marginBottom } from '../composableStyles/marginBottom';
import { DecorativeDots } from './DecorativeDots';
import {
  decorationDefaultValue,
  decorationPropTypes,
  spaceBelowDefaultValue,
} from '../utilities/componentPropTypes';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';
import { fluidScale } from '../composableStyles/fluidScale';
import {
  DESKTOP_BP, MOBILE_BP, TABLET_BP, WIDEST_BP, WIDE_BP,
} from '../composableStyles/theme';

const decorationStyles = {
  topLeft: css`
    top: -80px;
    left: -90px;
  `,
  bottomLeft: css`
    bottom: -80px;
    left: -90px;
  `,
  topRight: css`
    top: -80px;
    right: -90px;
  `,
  bottomRight: css`
    bottom: -80px;
    right: -90px;
  `,
};

export const QuoteBlock = ({
  text,
  source,
  componentTheme,
  decoration,
  spaceBelow,
}) => {
  const theme = useTheme();

  return (
    <blockquote
      {...addNavColorAttribute(componentTheme)}
      css={[
        container(theme, 18),
        componentBackground(theme, componentTheme),
        marginBottom(theme, spaceBelow),
        css`
          position: relative;
          padding-left: max(var(--container-grid-padding) + 0.5% - 10px, var(--container-grid-padding));
          ${fluidScale({
          minPx: 24, maxPx: 24 * tabletScaleFactor, minMqPx: MOBILE_BP, maxMqPx: TABLET_BP, cssPropertyName: 'padding-top',
        })}

          ${theme.bp.tablet} {
            ${fluidScale({
          minPx: 44, maxPx: 50, minMqPx: TABLET_BP, maxMqPx: DESKTOP_BP, cssPropertyName: 'padding-top',
        })}
          }
          
          ${theme.bp.wide} {
            max-width: ${(theme.layout.maxWidthColsPx(18) / theme.layout.maxWidthColsPx(24)) * 100}%;
            ${fluidScale({
          minPx: 50, maxPx: 50 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP, cssPropertyName: 'padding-top',
        })}
          }

          &::before {
            content: " ";
            position: absolute;
            z-index: 1;
            top: 0;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 118 100'%3E%3Cpath fill='%23EBEAE9' d='M117.337838 100V51.9607843H95.1554054v-.6535948c0-17.3202614 10.2635136-28.4313725 22.8445946-31.6993464V0C92.8378378 2.94117647 69 18.3006536 69 56.8627451V100h48.337838zm-69.3244152 0V51.9607843h-22.033557v-.6535948c0-17.3202614 10.5234899-28.4313725 23.0201342-31.6993464V0C23.6778523 2.94117647 0 18.3006536 0 56.8627451V100h48.0134228z'/%3E%3C/svg%3E");
            background-size: 100%;
            ${fluidScale({
          minPx: 10, maxPx: 20, minMqPx: MOBILE_BP, maxMqPx: TABLET_BP, cssPropertyName: 'left',
        })}
            ${fluidScale({
          minPx: 59, maxPx: 59 * tabletScaleFactor, minMqPx: MOBILE_BP, maxMqPx: TABLET_BP, cssPropertyName: 'width',
        })}
            ${fluidScale({
          minPx: 50, maxPx: 50 * tabletScaleFactor, minMqPx: MOBILE_BP, maxMqPx: TABLET_BP, cssPropertyName: 'height',
        })}

            ${theme.bp.tablet} {
              top: 0;
              ${fluidScale({
          minPx: 15, maxPx: 25, minMqPx: TABLET_BP, maxMqPx: DESKTOP_BP, cssPropertyName: 'left',
        })}
              ${fluidScale({
          minPx: 105, maxPx: 118, minMqPx: TABLET_BP, maxMqPx: DESKTOP_BP, cssPropertyName: 'width',
        })}
              ${fluidScale({
          minPx: 89, maxPx: 100, minMqPx: TABLET_BP, maxMqPx: DESKTOP_BP, cssPropertyName: 'height',
        })}
            }

            ${theme.bp.wide} {
              ${fluidScale({
          minPx: 25, maxPx: 25 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP, cssPropertyName: '--left-offset',
        })}
              left: calc(-1 * var(--left-offset));
              ${fluidScale({
          minPx: 118, maxPx: 118 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP, cssPropertyName: 'width',
        })}
              ${fluidScale({
          minPx: 100, maxPx: 100 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP, cssPropertyName: 'height',
        })}
            }
          }
        `,
      ]}
    >
      <WysiwygText
        {...{ text }}
        css={[
          css`
            position: relative;
            z-index: 2;
            color: ${theme.colors.black};
          `,
          fluidHeading36(theme),
        ]}
      />
      {source.length > 0 && (
        <footer
          css={[
            css`
              margin-top: ${17 / 13}em;

              & p {
                margin-bottom: ${5 / 13}em;
              }
            `,
            fluidText13(theme),
          ]}
        >
          {source.map((content, i) => (
            <p key={`source${i}`}>{content}</p>
          ))}
        </footer>
      )}
      {decoration !== decorationDefaultValue && (
        <DecorativeDots
          css={css`
            position: absolute;
            z-index: -1;
            ${decorationStyles[decoration] || ''}
            display: none;

            ${theme.bp.tablet} {
              display: block;
            }
          `}
        />
      )}
    </blockquote>
  );
};

QuoteBlock.propTypes = {
  text: PropTypes.string,
  source: PropTypes.arrayOf(PropTypes.string),
  componentTheme: PropTypes.oneOf(componentThemeOptions),
  decoration: decorationPropTypes,
  spaceBelow: PropTypes.string,
};

QuoteBlock.defaultProps = {
  text: '',
  source: [],
  componentTheme: componentThemeOptions[0],
  decoration: decorationDefaultValue,
  spaceBelow: spaceBelowDefaultValue,
};
