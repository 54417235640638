import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { FeatureText } from '../components/FeatureText';
import { mapDecorationValues } from '../utilities/mapDecorationValues';
import { htmlSerializer } from '../utilities/htmlSerializer';

const getLayoutProp = alignment => {
  const fnIsValid = value => {
    const alignLower = alignment.toLowerCase();

    return value === alignLower;
  };
  const layoutProp = ['left', 'center', 'right'].find(fnIsValid);

  return layoutProp || 'left';
};

export const FeatureTextSlice = ({ slice }) => {
  const {
    primary: {
      background_colour: bgColour,
      space_below: spaceBelow,
      dotted_background: dottedBackground,
      tagline_text: { richText },
      alignment,
    },
  } = slice;
  const componentTheme = bgColour.toLowerCase();
  const layout = getLayoutProp(alignment);
  const decoration = mapDecorationValues(dottedBackground);

  return (
    <FeatureText
      text={<RichText render={richText} {...{ htmlSerializer }} />}
      {...{
        componentTheme, layout, decoration, spaceBelow,
      }}
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyFeatureTextBlock on PrismicLandingPageDataBodyFeatureText {
    primary {
      alignment
      background_colour
      dotted_background
      space_below
      tagline_text {
        richText
      }
    }
  }
  fragment CaseStudyDataBodyFeatureTextBlock on PrismicCaseStudyDataBodyFeatureText {
    primary {
      alignment
      background_colour
      dotted_background
      space_below
      tagline_text {
        richText
      }
    }
  }
  fragment NewsPostDataBodyFeatureTextBlock on PrismicNewsPostDataBodyFeatureText {
    primary {
      alignment
      background_colour
      dotted_background
      space_below
      tagline_text {
        richText
      }
    }
  }
  fragment HomepageDataBodyFeatureTextBlock on PrismicHomepageDataBodyFeatureText {
    primary {
      alignment
      background_colour
      dotted_background
      space_below
      tagline_text {
        richText
      }
    }
  }
  fragment UpdatesPageDataBodyFeatureTextBlock on PrismicUpdatesPageDataBodyFeatureText {
    primary {
      alignment
      background_colour
      dotted_background
      space_below
      tagline_text {
        richText
      }
    }
  }
`;

FeatureTextSlice.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      tagline_text: PropTypes.shape({
        richText: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
          }),
        ),
      }),
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
      dotted_background: PropTypes.string,
      alignment: PropTypes.string,
    }),
  }).isRequired,
};
