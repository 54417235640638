/** @jsx jsx */
import {
  css, jsx, keyframes, useTheme,
} from '@emotion/react';

const prompt = keyframes`
  from, 20%, to {
    transform: scaleY(1);
    opacity: 1;
  }

  70% {
    transform: scaleY(5.5);
    opacity: 1
  }
  
  80% {
    transform: scaleY(5.5);
    opacity: 0
  }

  99% {
    transform: scaleY(1);
    opacity: 0
  }
`;

export const ScrollPromptSVG = () => {
  const theme = useTheme();

  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 16 27"
      css={css`
        display: block;
        fill: ${theme.colors.greyLight};
        width: 16px;
        height: 27px;
      `}
    >
      <path d="M8 0C3.581722 0 0 3.581722 0 8v11c0 4.418278 3.581722 8 8 8s8-3.581722 8-8V8c0-4.418278-3.581722-8-8-8zm0 2c3.3137085 0 6 2.6862915 6 6v11c0 3.3137085-2.6862915 6-6 6s-6-2.6862915-6-6V8c0-3.3137085 2.6862915-6 6-6z" />
      <polygon
        points="7 8 9 8 9 10 7 10"
        css={css`
          opacity: 1;
          transform-origin: 50% ${(8 / 27) * 100}%;
          animation: ${prompt} 3s ease-in-out infinite;
        `}
      />
    </svg>
  );
};
