/** @jsx jsx */
import {
  jsx, css, keyframes, useTheme,
} from '@emotion/react';
import PropTypes from 'prop-types';
import { fluidText18 } from '../composableStyles/text';
import { INTRO_ENTER_STEP } from '../utilities/constants';
import { easeOutQuart } from '../composableStyles/easings';

const initialOffset = '40px';

const introVertical = keyframes`
  from {
    transform: translateY(${initialOffset});
  }

  to {
    transform: translateY(0);
  }
`;

const introOpacity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity:1;
  }
`;

export const HomePageHeroIntro = ({
  intro, step, ...otherProps
}) => {
  const theme = useTheme();

  const animation = step >= INTRO_ENTER_STEP
    ? css`
      animation: ${introVertical} var(--intro-duration) ${easeOutQuart} 0ms 1 both,
        ${introOpacity} var(--intro-duration) linear 0ms 1 both;
    `
    : null;

  return (
    <div
      css={[
        fluidText18(theme),
        css`
          color: ${theme.colors.greyLightest};
          max-width: ${570 / 18}em;
          margin-top: ${20 / 18}em;
          opacity: 0;
          transform: translateY(0);

          & > p:last-of-type {
            margin-bottom: 0;
          }
        `,
        animation,
      ]}
      {...otherProps}
    >
      {intro}
    </div>
  );
};

HomePageHeroIntro.propTypes = {
  intro: PropTypes.node.isRequired,
  step: PropTypes.number.isRequired,
};
