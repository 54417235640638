/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AccordionPanel, useAccordionItemContext } from '@reach/accordion';
import anime from 'animejs/lib/anime.es';
import { fluidText14 } from '../composableStyles/text';
import { textEditor } from '../composableStyles/textEditor';
import { usePrevious } from '../hooks/usePrevious';

export const ExpanderListPanel = ({
  children,
  removeAfterAnimation,
  isCollapsing,
}) => {
  const theme = useTheme();
  const { isExpanded, index } = useAccordionItemContext();
  const panelRef = useRef(null);
  const prevIsExpanded = usePrevious(isExpanded);

  const animateThePanel = isExpanding => {
    // cancel any running animation
    anime.remove(panelRef.current);

    if (isExpanding) {
      anime({
        targets: panelRef.current,
        height: panelRef.current.scrollHeight,
        easing: 'easeOutCubic',
        duration: 300,
      });
    } else {
      anime({
        targets: panelRef.current,
        height: 0,
        easing: 'easeInOutCubic',
        duration: 300,
      }).finished.then(() => removeAfterAnimation(index));
    }
  };

  useEffect(() => {
    if (isCollapsing) {
      animateThePanel(false);
    }
  }, [isCollapsing]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // avoid first render
    if (
      index >= 0
      && prevIsExpanded !== undefined
      && isExpanded !== prevIsExpanded
      && isExpanded
    ) {
      animateThePanel(true);
    }
  }, [isExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AccordionPanel
      ref={panelRef}
      css={[
        textEditor(theme),
        fluidText14(theme),
        css`
          overflow: hidden;
          height: 0;
        `,
      ]}
    >
      <div
        css={css`
          padding-top: ${theme.spacer()};
        `}
      >
        {children}
      </div>
    </AccordionPanel>
  );
};

ExpanderListPanel.propTypes = {
  children: PropTypes.node,
  removeAfterAnimation: PropTypes.func.isRequired,
  isCollapsing: PropTypes.bool.isRequired,
};

ExpanderListPanel.defaultProps = {
  children: '',
};
