/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { BulletButton } from './BulletButton';
import { vwLinkPropTypes } from './VWLink';
import { WysiwygText } from './WysiwygText';
import { fluidHeading34, fluidText16 } from '../composableStyles/text';
import { container } from '../composableStyles/layout';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { marginBottom } from '../composableStyles/marginBottom';

export const CaseStudyIntro = ({
  intro, text, buttons, spaceBelow,
}) => {
  const theme = useTheme();
  const mqLayoutSwitch = theme.bp.desktop;

  return (
    <div
      css={[
        container(theme, 22),
        marginBottom(theme, spaceBelow),
        css`
          ${mqLayoutSwitch} {
            display: flex;
            justify-content: space-between;
          }
        `,
      ]}
    >
      <div
        css={css`
          margin-bottom: ${theme.pxToRem(30)}rem;

          ${mqLayoutSwitch} {
            flex: 0 0 30%;
            margin-bottom: 0;
          }
        `}
      >
        <h2
          css={css`
            ${fluidHeading34(theme)}
            color: ${theme.colors.black};
            margin-bottom: ${20 / 28}em;
            max-width: max(65%, ${theme.pxToRem(740)}rem);

            ${mqLayoutSwitch} {
              margin-bottom: ${40 / 34}em;
            }
          `}
        >
          {intro}
        </h2>
        {
          buttons.length > 0 && buttons.map(
            (button, i) => <BulletButton key={i} {...button} />,
          )
        }
      </div>
      <WysiwygText
        {...{ text }}
        css={css`
          ${mqLayoutSwitch} {
            flex: 0 0 63%;
          }

          & ul li {
            ${fluidText16(theme)}
          }
        `}
      />
    </div>
  );
};

CaseStudyIntro.propTypes = {
  intro: PropTypes.string,
  text: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.shape(vwLinkPropTypes)),
  spaceBelow: PropTypes.string,
};

CaseStudyIntro.defaultProps = {
  intro: '',
  text: '',
  buttons: [],
  spaceBelow: spaceBelowDefaultValue,
};
