/* eslint react/prop-types: off */
import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { RichText } from 'prismic-reactjs';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { SliceZone } from '../components/SliceZone';
import { HeroFullImage } from '../components/HeroFullImage';
import { CaseStudyIntro } from '../components/CaseStudyIntro';
import { htmlSerializer } from '../utilities/htmlSerializer';

const CaseStudyTemplate = ({ data }) => {
  if (!data) return null;

  const {
    display_title: { text },
    intro_title: { text: intro },
    intro_description: { richText },
    main_hero_image: image,
    buttons: prismicButtons,
    hero_text_color: textColor,
    body,
  } = data.prismicCaseStudy.data;
  const title = 'Case Study';
  const buttons = prismicButtons
    .map(
      ({
        button_label: label,
        button_link: { url: link, target },
        button_link_override: linkOverride,
      }) => ({
        url: linkOverride || link,
        label,
        type: target === '_blank' ? 'external' : 'internal',
      }),
    )
    .filter(({ url }) => !!url);

  return (
    <Layout>
      <SEO title={`${text} | ${title}`} />
      <HeroFullImage
        {...{
          title, text, image, textColor,
        }}
        spaceBelow="110px"
      />
      <CaseStudyIntro
        {...{ intro, buttons }}
        text={<RichText render={richText} {...{ htmlSerializer }} />}
        spaceBelow="120px"
      />
      <SliceZone sliceZone={body} />
    </Layout>
  );
};

export const query = graphql`
  query CaseStudyQuery($id: String) {
    prismicCaseStudy(id: { eq: $id }) {
      _previewable
      data {
        display_title {
          text
        }
        intro_title {
          text
        }
        intro_description {
          richText
        }
        buttons {
          button_label
          button_link {
            url
            target
          }
          button_link_override
        }
        main_hero_image {
          gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          thumbnails {
            mobile {
              gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
            }
          }
          alt
        }
        hero_text_color
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...CaseStudyDataBodyQuote
          ...CaseStudyDataBodyFiftyFiftyPanels
          ...CaseStudyDataBodyCaseStudyList
          ...CaseStudyDataBodyServicesBlock
          ...CaseStudyDataBodyTeamListBlock
          ...CaseStudyDataBodyExpandablesListBlock
          ...CaseStudyDataBodyFeatureTextBlock
          ...CaseStudyDataBodyInlineImagesBlock
          ...CaseStudyDataBodyRelatedPosts
          ...CaseStudyDataBodyRichText
          ...CaseStudyDataBodyLogos
        }
      }
    }
  }
`;

CaseStudyTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default withPrismicPreview(CaseStudyTemplate);
