/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { HeaderLogo } from './HeaderLogo';
import { HeaderMenuButton } from './HeaderMenuButton';
import { container } from '../composableStyles/layout';

export const HeaderBanner = forwardRef(({
  isCloseButton, handleMenuClick, isNav, ...otherProps
}, ref) => {
  const theme = useTheme();
  const WrapperElement = isNav ? 'nav' : 'div';

  return (
    <WrapperElement
      {...{ ref }}
      aria-label={isNav ? 'primary navigation' : null}
      css={[
        container(theme),
        css`
          margin-top: ${theme.spacer(0.75)};
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;

          ${theme.bp.tablet} {
            margin-top: ${theme.spacer(1.5)};
          }
        `,
      ]}
      {...otherProps}
    >
      <HeaderLogo
        css={css`
            pointer-events: all;
          `}
      />
      <HeaderMenuButton
        {...{ isCloseButton, handleMenuClick }}
        css={css`
          pointer-events: all;
        `}
      />
    </WrapperElement>
  );
});

HeaderBanner.propTypes = {
  isCloseButton: PropTypes.bool,
  isNav: PropTypes.bool,
  handleMenuClick: PropTypes.func.isRequired,
};

HeaderBanner.defaultProps = {
  isCloseButton: false,
  isNav: false,
};
