/** @jsx jsx */
import { jsx, useTheme, css } from '@emotion/react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { container } from '../composableStyles/layout';
import { fluidHeading22 } from '../composableStyles/text';
import { VWImage } from './VWImage';
import {
  componentThemeOptions,
  componentBackground,
} from '../composableStyles/backgrounds';
import { marginBottom } from '../composableStyles/marginBottom';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';

const StyledHeading = styled.h2`
  ${props => fluidHeading22(props.theme)}
  margin-bottom: ${45 / 22}em;
  color: ${props => props.theme.colors.black};
`;

const StyledUl = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${42 / 18}em;
  justify-items: center;
  align-items: center;

  ${props => props.theme.bp.tablet} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const StyledLi = styled.li`
  aspect-ratio: 160 / 100;
  max-width: 30vw;
  position: relative;

  ${props => props.theme.bp.tablet} {
    max-width: ${160 / 18}em;
  }


  & img {
    object-fit: contain !important;
  }
`;

export const LogoList = ({
  title, images, componentTheme, spaceBelow,
}) => {
  const theme = useTheme();

  if (!images.length) {
    return null;
  }

  return (
    <section
      {...addNavColorAttribute(componentTheme)}
      css={[
        container(theme, 18),
        componentBackground(theme, componentTheme),
        marginBottom(theme, spaceBelow),
      ]}
    >
      {title && <StyledHeading>{title}</StyledHeading>}
      <StyledUl>
        {images.map((image, i) => (
          <StyledLi key={i}>
            <VWImage
              {...{ image }}
              css={css`
                width: 100%;
                height: 100%;
                opacity: 60%;
              `}
            />
          </StyledLi>
        ))}
      </StyledUl>
    </section>
  );
};

LogoList.propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({})),
  componentTheme: PropTypes.oneOf(componentThemeOptions),
  spaceBelow: PropTypes.string,
};

LogoList.defaultProps = {
  title: '',
  images: [],
  componentTheme: componentThemeOptions[0],
  spaceBelow: spaceBelowDefaultValue,
};
