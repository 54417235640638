import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { QuoteBlock } from '../components/QuoteBlock';
import { mapDecorationValues } from '../utilities/mapDecorationValues';
import { mapBackgroundValues } from '../utilities/mapBackgroundValues';

export const QuoteSlice = ({ slice }) => {
  const {
    quote_text: quote,
    quote_client_name: name,
    quote_client_bio: bio,
    background_colour: backgroundColor,
    space_below: spaceBelow,
    dotted_background: decoration,
  } = slice.primary;

  return (
    <QuoteBlock
      text={RichText.asText(quote.richText)}
      source={[
        RichText.asText(name.richText),
        RichText.asText(bio.richText),
      ].filter(text => text)}
      decoration={mapDecorationValues(decoration)}
      componentTheme={mapBackgroundValues(backgroundColor)}
      {...{ spaceBelow }}
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyQuote on PrismicLandingPageDataBodyQuote {
    primary {
      quote_text {
        richText
      }
      quote_client_name {
        richText
      }
      quote_client_bio {
        richText
      }
      space_below
      background_colour
      dotted_background
    }
  }
  fragment CaseStudyDataBodyQuote on PrismicCaseStudyDataBodyQuote {
    primary {
      quote_text {
        richText
      }
      quote_client_name {
        richText
      }
      quote_client_bio {
        richText
      }
      space_below
      background_colour
      dotted_background
    }
  }
  fragment NewsPostDataBodyQuote on PrismicNewsPostDataBodyQuote {
    primary {
      quote_text {
        richText
      }
      quote_client_name {
        richText
      }
      quote_client_bio {
        richText
      }
      space_below
      background_colour
      dotted_background
    }
  }
  fragment HomepageDataBodyQuote on PrismicHomepageDataBodyQuote {
    primary {
      quote_text {
        richText
      }
      quote_client_name {
        richText
      }
      quote_client_bio {
        richText
      }
      space_below
      background_colour
      dotted_background
    }
  }
`;

QuoteSlice.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      quote_text: PropTypes.shape({
        richText: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
          }),
        ),
      }),
      quote_client_name: PropTypes.shape({
        richText: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
          }),
        ),
      }),
      quote_client_bio: PropTypes.shape({
        richText: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
          }),
        ),
      }),
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
      dotted_background: PropTypes.string,
    }),
  }).isRequired,
};
