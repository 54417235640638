/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { VWLink } from './VWLink';
import { ThemedVWLogoSVG } from './VWLogoSVG';
import { logoScaleFactor } from '../composableStyles/text';
import { WIDEST_BP, WIDE_BP } from '../composableStyles/theme';
import { fluidScale } from '../composableStyles/fluidScale';

export const HeaderLogo = () => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  return (
    <VWLink
      url="/"
      aria-label="Home"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      css={css`
        display: block;
        pointer-events: all;
        line-height: 0;

        &:focus-visible {
          --vw-logo-background: ${theme.colors.pink};
        }
      `}
    >
      <ThemedVWLogoSVG
        {...{ isHovered }}
        css={css`
          width: 69px;
          height: 67px;
          margin: -6px;

          ${theme.bp.tablet} {
            ${fluidScale({
          minPx: 80,
          maxPx: 80 * logoScaleFactor,
          minMqPx: WIDE_BP,
          maxMqPx: WIDEST_BP,
          cssPropertyName: 'width',
        })}
                  ${fluidScale({
          minPx: 78,
          maxPx: 78 * logoScaleFactor,
          minMqPx: WIDE_BP,
          maxMqPx: WIDEST_BP,
          cssPropertyName: 'height',
        })}
                  ${fluidScale({
          minPx: -8,
          maxPx: -8 * logoScaleFactor,
          minMqPx: WIDE_BP,
          maxMqPx: WIDEST_BP,
          cssPropertyName: 'margin',
        })}
          }
        `}
      />
    </VWLink>
  );
};
