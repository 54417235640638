/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { fluidText13 } from '../composableStyles/text';
import { pseudoDecoration } from '../composableStyles/pseudoDecoration';

export const SectionHeading = ({
  children, level, color, ...otherProps
}) => {
  const theme = useTheme();
  const TagName = level === 'span' ? 'span' : `h${level}`;
  const textColor = color || theme.colors.greyDark;

  const widePaddingPercentage = (100 - (
    (theme.layout.maxWidthColsPx(22) / theme.layout.maxWidthColsPx(24)) * 100
  )) / 2;

  return (
    <TagName
      {...otherProps}
      css={[
        css`
          color: ${textColor};
          display: block;
          position: relative;
          padding-left: ${35 / 13}em;
          
          ${theme.bp.tablet} {
            padding-left: max(${theme.pxToRem(56)}rem, ${widePaddingPercentage}%);
          }

          &::before {
            ${pseudoDecoration({
          left: '0',
          top: '50%',
          width: `${20 / 13}em`,
          height: '2px',
        })}
            transform: translateY(-50%);

            ${theme.bp.tablet} {
              width: ${30 / 13}em;
            }
          }
        `,
        fluidText13(theme),
      ]}
    >
      {children}
    </TagName>
  );
};

SectionHeading.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 'span']),
  color: PropTypes.string,
};

SectionHeading.defaultProps = {
  level: 2,
  color: '',
};
