/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { RichText, Elements } from 'prismic-reactjs';
import { WysiwygText } from './WysiwygText';
import { SectionHeader, sectionHeaderPropTypes } from './SectionHeader';
import {
  componentThemeOptions,
  componentBackground,
  textColor,
} from '../composableStyles/backgrounds';
import { container } from '../composableStyles/layout';
import { fluidHeading22, fluidText17 } from '../composableStyles/text';
import { bulletStyles } from '../composableStyles/bulletList';
import { marginBottom } from '../composableStyles/marginBottom';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';

export const ulHtmlSerializer = (theme, componentTheme) => {
  return (type, element, content, children, key) => {
    switch (type) {
      case Elements.list:
        return (
          <ul
            key={key}
            css={css`
              color: ${textColor(theme, componentTheme)};
              margin-top: ${theme.spacer(1.25)};

              ${theme.bp.tablet} {
                flex: 0 1 31%;
                margin-top: 0;
              }
            `}
          >
            {children}
          </ul>
        );

      case Elements.listItem:
        return (
          <li key={key} css={bulletStyles(theme)}>
            {children}
          </li>
        );

      default:
        return null;
    }
  };
};

export const FeatureList = ({
  header,
  componentTheme,
  features,
  spaceBelow,
}) => {
  const theme = useTheme();

  return (
    <section
      {...addNavColorAttribute(componentTheme)}
      css={[
        componentBackground(theme, componentTheme),
        marginBottom(theme, spaceBelow),
      ]}
    >
      <SectionHeader {...header} {...{ componentTheme }} />
      {features.map((feature, i) => {
        const { title, text, items } = feature;
        return (
          <section
            key={i}
            css={[
              container(theme, 22),
              css`
                margin-bottom: ${theme.spacer(2.5)};

                &:last-of-type {
                  margin-bottom: 0;
                }

                ${theme.bp.tablet} {
                  margin-bottom: ${theme.spacer(4)};
                }
              `,
            ]}
          >
            {title && (
              <h3
                css={css`
                  ${fluidHeading22(theme)}
                  color: ${theme.colors.white};
                  margin-bottom: ${20 / 22}em;
                `}
              >
                {title}
              </h3>
            )}
            <div
              css={css`
                padding-bottom: ${theme.spacer(2.5)};
                border-bottom: 1px solid ${theme.colors.greyDark};

                ${theme.bp.tablet} {
                  padding-bottom: ${theme.spacer(4)};
                  display: flex;
                  justify-content: space-between;
                }
              `}
            >
              {text && (
                <WysiwygText
                  {...{ text }}
                  css={[
                    css`
                      color: ${textColor(theme, componentTheme)};

                      ${theme.bp.tablet} {
                        flex: 0 1 62%;
                      }

                      ${theme.bp.desktop} {
                        flex-basis: 55%;
                      }

                      ${theme.bp.wide} {
                        flex-basis: 49%;
                      }
                    `,
                    fluidText17(theme),
                  ]}
                />
              )}
              <RichText
                render={items}
                htmlSerializer={ulHtmlSerializer(theme, componentTheme)}
              />
            </div>
          </section>
        );
      })}
    </section>
  );
};

FeatureList.propTypes = {
  header: PropTypes.shape(sectionHeaderPropTypes),
  componentTheme: PropTypes.oneOf(componentThemeOptions),
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
        }),
      ),
    }),
  ),
  spaceBelow: PropTypes.string,
};

FeatureList.defaultProps = {
  header: {},
  componentTheme: componentThemeOptions[0],
  features: [],
  spaceBelow: spaceBelowDefaultValue,
};
