import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { mapBackgroundValues } from '../utilities/mapBackgroundValues';
import { mapDecorationValues } from '../utilities/mapDecorationValues';
import { ContentList } from '../components/ContentList';

export const CaseStudyListSlice = ({ slice }) => {
  const {
    block_title: section,
    title,
    card_layout: cardLayout,
    button_label: label,
    button_link: { url },
    background_colour: backgroundColor,
    space_below: spaceBelow,
  } = slice.primary;

  const items = slice.items.map(
    ({
      related_case_study: {
        document: {
          data: {
            display_title: { text: surtitle },
            intro_title: { text: cardTitle },
            main_hero_image: {
              thumbnails: { card50, card100 },
              alt,
            },
          },
          url: cardUrl,
        },
      },
      card_width: width,
      dotted_background: decoration,
    }) => {
      const isFullWidth = width === '100%';

      return {
        image: {
          ...(isFullWidth ? card100 : card50),
          alt,
        },
        imageMobile: isFullWidth ? card50 : null,
        title: cardTitle,
        surtitle,
        url: cardUrl,
        isFullWidth,
        decoration: mapDecorationValues(decoration),
      };
    },
  );

  // @TODO need to move decoration from component to cards, or vice versa
  return (
    <ContentList
      header={{ section, title }}
      layout={cardLayout === 'Straight' ? 'twoRow' : 'twoStagger'}
      button={url ? { url, label } : false}
      componentTheme={mapBackgroundValues(backgroundColor)}
      {...{ spaceBelow, items }}
      decoration="none" // @TODO unravel this, think decoration should be on cards
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyCaseStudyList on PrismicLandingPageDataBodyCaseStudyList {
    primary {
      block_title
      title
      card_layout
      button_label
      button_link {
        url
      }
      space_below
      background_colour
    }
    items {
      related_case_study {
        document {
          ... on PrismicCaseStudy {
            data {
              display_title {
                text
              }
              intro_title {
                text
              }
              main_hero_image {
                thumbnails {
                  card50 {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                  card100 {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
            }
            url
          }
        }
      }
      card_width
      dotted_background
    }
  }
  fragment CaseStudyDataBodyCaseStudyList on PrismicCaseStudyDataBodyCaseStudyList {
    primary {
      block_title
      title
      card_layout
      button_label
      button_link {
        url
      }
      space_below
      background_colour
    }
    items {
      related_case_study {
        document {
          ... on PrismicCaseStudy {
            data {
              display_title {
                text
              }
              intro_title {
                text
              }
              main_hero_image {
                thumbnails {
                  card50 {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                  card100 {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
            }
            url
          }
        }
      }
      card_width
      dotted_background
    }
  }
  fragment NewsPostDataBodyCaseStudyList on PrismicNewsPostDataBodyCaseStudyList {
    primary {
      block_title
      title
      card_layout
      button_label
      button_link {
        url
      }
      space_below
      background_colour
    }
    items {
      related_case_study {
        document {
          ... on PrismicCaseStudy {
            data {
              display_title {
                text
              }
              intro_title {
                text
              }
              main_hero_image {
                thumbnails {
                  card50 {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                  card100 {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
            }
            url
          }
        }
      }
      card_width
      dotted_background
    }
  }
  fragment HomepageDataBodyCaseStudyList on PrismicHomepageDataBodyCaseStudyList {
    primary {
      block_title
      title
      card_layout
      button_label
      button_link {
        url
      }
      space_below
      background_colour
    }
    items {
      related_case_study {
        document {
          ... on PrismicCaseStudy {
            data {
              display_title {
                text
              }
              intro_title {
                text
              }
              main_hero_image {
                thumbnails {
                  card50 {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                  card100 {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                  }
                }
                alt
              }
            }
            url
          }
        }
      }
      card_width
      dotted_background
    }
  }
`;

CaseStudyListSlice.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      block_title: PropTypes.string,
      title: PropTypes.string,
      card_layout: PropTypes.oneOf(['Straight', 'Staggered']),
      button_label: PropTypes.string,
      button_link: PropTypes.shape({
        url: PropTypes.string,
      }),
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        card_width: PropTypes.oneOf(['50%', '100%']),
      }),
    ),
  }).isRequired,
};
