/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import {
  useState, useContext, useEffect, useRef,
} from 'react';
import styled from '@emotion/styled';
import anime from 'animejs/lib/anime.es';
import debounce from 'lodash.debounce';
import { VWLink } from './VWLink';
import { usePrevious } from '../hooks/usePrevious';
import { MediaQueryContext } from '../contexts/MediaQueryProvider';
import { logoScaleFactor } from '../composableStyles/text';
import { WIDEST_BP, WIDE_BP } from '../composableStyles/theme';
import { fluidScale } from '../composableStyles/fluidScale';

const calcStartOfPath = h => {
  // calculate the inital point based on height
  return {
    x: (30 / 50) * h,
    y: (28 / 50) * h,
  };
};

const pathInitial = h => {
  const { x, y } = calcStartOfPath(h);
  return `M${x} ${y}c1.4 0 2 -1.3 2 -2.7s-1.1 -2 -2.5 -2s-2.5 1 -2.5 2.3s1.6 2.4 3 2.4z`;
};

const pathHover = (w, h) => {
  const { x } = calcStartOfPath(h);
  // original width of hover path is 26
  // need to multiply all coordinates by a scale factor
  const f = (w / 26) * 1.6; // magic fudge factor
  return `M${x} ${(f / 2) * 28}c${f * 7.2} ${f * 0} ${f * 14.6} ${f * -5} ${
    f * 14.6
  } ${f * -12.1}s${f * -7.4} ${f * -13.7} ${f * -14.6} ${f * -13.7}s${
    f * -11.4
  } ${f * 6.6} ${f * -11.4} ${f * 13.7}s${f * 4.2} ${f * 12.1} ${f * 11.4} ${
    f * 12.1
  }z`;
};

// eslint-disable-next-line max-len
// "m13.4,27.9c7.2,0,14.6,-5,14.6,-12.1s-7.4,-13.7,-14.6,-13.7s-11.4,6.6,-11.4,13.7s4.2,12.1,11.4,12.1z";

/* eslint-disable no-undef */
const StyledButton = styled(VWLink)`
  display: inline-block;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.black};
  padding: ${15 / 14}em ${40 / 14}em ${15 / 14}em ${45 / 14}em;
  margin: 0;
  font-family: ${props => props.theme.font.family.alternate};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: 0;
  line-height: ${20 / 14};
  text-align: center;
  border-radius: ${25 / 14}em;
  position: relative;
  overflow: hidden;
  transition: outline-color 200ms ease-out 0ms, outline-offset 200ms ease-out 0ms;
  outline: 1px solid transparent;
  ${fluidScale({
    minPx: 14, maxPx: 14 * logoScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  
  @media (hover: hover) {
    &:hover {
      color: ${props => props.theme.colors.white};
    }
  }
  
  &:focus-visible {
    outline-offset: 3px;
    outline-color:  ${props => props.theme.colors.black};
  }
`;

const StyledSVG = styled.svg`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  fill: ${props => props.theme.colors.pink};
  opacity: 0;
`;

const StyledLabel = styled.span`
  position: relative;
  z-index: 5;
`;
/* eslint-enable no-undef */

export const PillButton = ({ label, ...otherProps }) => {
  const { isHover: isHoverEnabled } = useContext(MediaQueryContext);
  const [isHovered, setIsHovered] = useState(false);
  const pathRef = useRef(null);
  const svgRef = useRef(null);
  const prevIsHovered = usePrevious(isHovered);
  const isFirstRender = useRef(true);

  const animateTheShape = () => {
    if (svgRef.current && pathRef.current) {
      // cancel any running animation
      anime.remove(pathRef.current);

      // measure the button, this needs to be done here rather than on mount
      // due to font loading changing the width of button after mount
      // (at least I think that's what causes the change in width)
      const { width, height } = svgRef.current.getBoundingClientRect();
      svgRef.current.setAttribute('viewbox', `0 0 ${width} ${height}`);

      // animate the path from dot to button shape
      anime({
        targets: pathRef.current,
        d: [{ value: isHovered ? pathHover(width, height) : pathInitial(height) }],
        easing: isHovered ? 'easeOutQuad' : 'easeInOutQuad',
        duration: isHovered ? 320 : 320,
      });
    }
  };

  const initialiseTheShape = () => {
    if (svgRef.current && pathRef.current) {
      // measure the button for initial positioning
      const { width, height } = svgRef.current.getBoundingClientRect();
      svgRef.current.setAttribute('viewbox', `0 0 ${width} ${height}`);
      pathRef.current.setAttribute('d', pathInitial(height));
      svgRef.current.style.opacity = 1;
    }
  };

  const handleResize = debounce(
    initialiseTheShape,
    100,
    {
      leading: true,
      trailing: true,
    },
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      initialiseTheShape();

      window.addEventListener('resize', handleResize); // eslint-disable-line no-undef
    } else if (prevIsHovered !== isHovered && isHoverEnabled) {
      animateTheShape();
    }

    return () => {
      window.removeEventListener('resize', handleResize); // eslint-disable-line no-undef
    };
  }, [isHovered, isHoverEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledButton
      {...otherProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
    >
      <StyledSVG
        ref={svgRef}
        viewbox="0 0 100 50"
        focusable="false"
        aria-hidden="true"
      >
        <path ref={pathRef} d={pathInitial(50)} />
      </StyledSVG>
      <StyledLabel>{label}</StyledLabel>
    </StyledButton>
  );
};

PillButton.propTypes = {
  label: PropTypes.node.isRequired,
};
