import { css } from '@emotion/react';
import { easeOutQuart } from './easings';
import { fadeIn, heroSlideUp } from './keyframes';

const imageDelay = 1200;
const surtitleDelay = 400;
const titleDelay = 800;

const imageDuration = 400;
const surtitleDuration = 300;
const titleDuration = 400;

export const heroRevealAnimation = {
  imageReveal: css`
    opacity: 0;
    transform: translateY(40px);
    animation: ${heroSlideUp} ${imageDuration}ms ${easeOutQuart} ${imageDelay}ms 1 both,
      ${fadeIn} ${imageDuration}ms linear ${imageDelay}ms 1 both;
  `,
  surtitleReveal: css`
    opacity: 0;
    animation: ${fadeIn} ${surtitleDuration}ms linear ${surtitleDelay}ms 1 both;
  `,
  titleReveal: css`
    opacity: 0;
    transform: translateY(40px);
    animation: ${heroSlideUp} ${titleDuration}ms ${easeOutQuart} ${titleDelay}ms 1 both,
      ${fadeIn} ${titleDuration}ms linear ${titleDelay}ms 1 both;
  `,
};
