/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { container } from '../composableStyles/layout';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { marginBottom } from '../composableStyles/marginBottom';
import { BlogListCard } from './BlogListCard';

// @TODO likely Filters and Pagination not essential for launch?
// depends on how much blog content we have

export const BlogList = ({ items, spaceBelow }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      {items.length > 0 && (
        <ul css={[container(theme, 22), marginBottom(theme, spaceBelow)]}>
          {items.map((cardProps, i) => (
            <BlogListCard key={i} {...cardProps} />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

BlogList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({}),
      title: PropTypes.arrayOf(PropTypes.shape({})),
      surtitle: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  spaceBelow: PropTypes.string,
};

BlogList.defaultProps = {
  items: [],
  spaceBelow: spaceBelowDefaultValue,
};
