import React from 'react';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { HeroText } from '../components/HeroText';
import { PageNotFound } from '../components/PageNotFound';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page Not Found" />
    <HeroText
      title="Page Not Found"
      text="Oops, the trail has ended."
      size="large"
    />
    <PageNotFound />
  </Layout>
);

export default withPrismicUnpublishedPreview(NotFoundPage);
