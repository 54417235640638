/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { fluidHeading16, fluidText13 } from '../composableStyles/text';
import { VWImage } from './VWImage';
import { useComponentReveal } from '../hooks/useComponentReveal';
import { cardRevealAnimation } from '../composableStyles/cardRevealAnimation';

export const TeamListCard = ({ name, role, image }) => {
  const theme = useTheme();
  const { ref, isRevealed } = useComponentReveal();

  const {
    imageReveal,
    surtitleReveal,
    titleReveal,
  } = cardRevealAnimation(isRevealed);

  return (
    <li
      {...{ ref }}
    >
      <div
        css={imageReveal}
      >
        <VWImage {...{ image }} alt={name} />
      </div>
      <span
        css={[
          css`
            ${fluidHeading16(theme)}
            color: ${theme.colors.white};
            display: block;
            margin-top: ${20 / 16}em;
          `,
          surtitleReveal,
        ]}
      >
        {name}
      </span>
      <span
        css={[
          css`
            ${fluidText13(theme)}
            display: block;
            margin-top: ${8 / 13}em;
            color: ${theme.colors.grey};
          `,
          titleReveal,
        ]}
      >
        {role}
      </span>
    </li>
  );
};

TeamListCard.propTypes = {
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  image: PropTypes.shape({
    gatsbyImageData: PropTypes.shape({
      images: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        fallback: PropTypes.shape({
          src: PropTypes.string,
          srcSet: PropTypes.string,
        }),
      }),
    }),
    alt: PropTypes.string,
  }).isRequired,
};
