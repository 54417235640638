import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { ExpanderList } from '../components/ExpanderList';
import { htmlSerializer } from '../utilities/htmlSerializer';

export const ExpandablesListSlice = ({ slice }) => {
  const {
    primary: {
      background_colour: bgColour,
      space_below: spaceBelow,
      block_title: section,
      intro_text: { richText: introText },
      title,
    },
    items: itemsInitial,
  } = slice;
  const text = RichText.asText(introText);
  const componentTheme = bgColour.toLowerCase();

  const items = itemsInitial.map(
    ({ title: itemTitle, content: { richText } }) => ({
      title: itemTitle,
      content: <RichText render={richText} {...{ htmlSerializer }} />,
    }),
  );

  return (
    <ExpanderList
      header={{ title, section, text }}
      {...{ componentTheme, spaceBelow, items }}
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyExpandablesListBlock on PrismicLandingPageDataBodyExpandablesList {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      background_colour
      space_below
    }
    items {
      title
      content {
        richText
      }
    }
  }
  fragment CaseStudyDataBodyExpandablesListBlock on PrismicCaseStudyDataBodyExpandablesList {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      background_colour
      space_below
    }
    items {
      title
      content {
        richText
      }
    }
  }
  fragment NewsPostDataBodyExpandablesListBlock on PrismicNewsPostDataBodyExpandablesList {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      background_colour
      space_below
    }
    items {
      title
      content {
        richText
      }
    }
  }
  fragment HomepageDataBodyExpandablesListBlock on PrismicHomepageDataBodyExpandablesList {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      background_colour
      space_below
    }
    items {
      title
      content {
        richText
      }
    }
  }
`;

ExpandablesListSlice.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string,
    primary: PropTypes.shape({
      block_title: PropTypes.string,
      title: PropTypes.string,
      intro_text: PropTypes.shape({
        richText: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
          }),
        ),
      }),
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
