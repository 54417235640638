import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { Text } from '../components/Text';
import { mapDecorationValues } from '../utilities/mapDecorationValues';
import { mapBackgroundValues } from '../utilities/mapBackgroundValues';
import { htmlSerializer } from '../utilities/htmlSerializer';

export const RichTextSlice = ({ slice }) => {
  const {
    content: { richText },
    background_colour: backgroundColor,
    width,
    space_below: spaceBelow,
    dotted_background: decoration,
  } = slice.primary;

  return (
    <Text
      content={<RichText render={richText} {...{ htmlSerializer }} />}
      decoration={mapDecorationValues(decoration)}
      componentTheme={mapBackgroundValues(backgroundColor)}
      width={width === '16 Columns' ? 16 : 18}
      {...{ spaceBelow }}
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyRichText on PrismicLandingPageDataBodyRichText {
    primary {
      content {
        richText
      }
      width
      space_below
      background_colour
      dotted_background
    }
  }
  fragment CaseStudyDataBodyRichText on PrismicCaseStudyDataBodyRichText {
    primary {
      content {
        richText
      }
      width
      space_below
      background_colour
      dotted_background
    }
  }
  fragment NewsPostDataBodyRichText on PrismicNewsPostDataBodyRichText {
    primary {
      content {
        richText
      }
      width
      space_below
      background_colour
      dotted_background
    }
  }
  fragment HomepageDataBodyRichText on PrismicHomepageDataBodyRichText {
    primary {
      content {
        richText
      }
      width
      space_below
      background_colour
      dotted_background
    }
  }
  fragment UpdatesPageDataBodyRichText on PrismicUpdatesPageDataBodyRichText {
    primary {
      content {
        richText
      }
      width
      space_below
      background_colour
      dotted_background
    }
  }
`;

RichTextSlice.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      content: PropTypes.shape({
        richText: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
          }),
        ),
      }),
      width: PropTypes.string,
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
      dotted_background: PropTypes.string,
    }),
  }).isRequired,
};
