/** @jsx jsx */
import {
  jsx, css, keyframes, useTheme,
} from '@emotion/react';
import PropTypes from 'prop-types';
import { INTRO_ENTER_STEP } from '../utilities/constants';
import { ScrollPromptSVG } from './ScrollPromptSVG';

const clipPathOpen = keyframes`
  from {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }

  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
`;

export const HomePageHeroScrollPrompt = ({ step }) => {
  const theme = useTheme();

  const animation = step >= INTRO_ENTER_STEP
    ? css`
      animation: ${clipPathOpen} var( --scroll-prompt-duration) ease-out var(--scroll-prompt-delay) 1 both;
    `
    : null;

  return (
    <div
      css={[
        css`
          margin-top: ${theme.pxToRem(48)}rem;
          clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        `,
        animation,
      ]}
    >
      <ScrollPromptSVG />
    </div>
  );
};

HomePageHeroScrollPrompt.propTypes = {
  step: PropTypes.number.isRequired,
};
