import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { LogoList } from '../components/LogoList';
import { mapBackgroundValues } from '../utilities/mapBackgroundValues';

export const LogosSlice = ({ slice }) => {
  const {
    intro_title: title,
    background_colour: backgroundColor,
    space_below: spaceBelow,
  } = slice.primary;

  return (
    <LogoList
      title={RichText.asText(title.richText)}
      images={slice.items.map(item => item.logo_image)}
      componentTheme={mapBackgroundValues(backgroundColor)}
      {...{ spaceBelow }}
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyLogos on PrismicLandingPageDataBodyLogos {
    primary {
      intro_title {
        richText
      }
      space_below
      background_colour
    }
    items {
      logo_image {
        gatsbyImageData(placeholder: NONE)
        alt
      }
    }
  }
  fragment CaseStudyDataBodyLogos on PrismicCaseStudyDataBodyLogos {
    primary {
      intro_title {
        richText
      }
      space_below
      background_colour
    }
    items {
      logo_image {
        gatsbyImageData(placeholder: NONE)
        alt
      }
    }
  }
  fragment NewsPostDataBodyLogos on PrismicNewsPostDataBodyLogos {
    primary {
      intro_title {
        richText
      }
      space_below
      background_colour
    }
    items {
      logo_image {
        gatsbyImageData(placeholder: NONE)
        alt
      }
    }
  }
  fragment HomepageDataBodyLogos on PrismicHomepageDataBodyLogos {
    primary {
      intro_title {
        richText
      }
      space_below
      background_colour
    }
    items {
      logo_image {
        gatsbyImageData(placeholder: NONE)
        alt
      }
    }
  }
`;

LogosSlice.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      intro_title: PropTypes.shape({
        richText: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
          }),
        ),
      }),
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        logo_image: PropTypes.shape({}),
      }),
    ),
  }).isRequired,
};
