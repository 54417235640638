import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { LinksList } from './LinksList';

export const SocialLinks = props => {
  const queryData = useStaticQuery(graphql`
    {
      prismicSocialMedia {
        _previewable
        data {
          social_media_link {
            url
            label
          }
        }
      }
    }
  `);

  const { data } = useMergePrismicPreviewData(queryData);

  const linksData = data.prismicSocialMedia.data.social_media_link;

  const links = linksData.map(link => ({ ...link, type: 'external' }));

  return <LinksList heading="Social" {...{ links }} {...props} />;
};
