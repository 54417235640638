import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export const useComponentReveal = ({
  threshold = 0.5,
  ...otherOptions
} = {}) => {
  const [isRevealed, setIsRevealed] = useState(false);
  const { ref, inView } = useInView({ threshold, ...otherOptions });

  useEffect(() => {
    if (inView && !isRevealed) {
      setIsRevealed(true);
    }
  }, [inView]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ref, isRevealed };
};
