/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import { useState } from 'react';
import { VWLink } from './VWLink';
import { ThemedVWLogoSVG } from './VWLogoSVG';
import { fluidScale } from '../composableStyles/fluidScale';
import { logoScaleFactor, widestScaleFactor } from '../composableStyles/text';
import { WIDEST_BP, WIDE_BP } from '../composableStyles/theme';

const logoText = css`
  display: block;
`;

const logoFocus = css`
  text-decoration: underline;
  text-underline-offset: 0.4em;
  text-decoration-color: var(--logo-focus-underline-color);
`;

export const FooterLogo = () => {
  const theme = useTheme();
  const [vwLogoHovered, setVwLogoHovered] = useState(false);

  return (
    <VWLink
      url="/"
      aria-label="Home"
      css={css`
            --logo-focus-underline-color: transparent;

            flex-grow: 0;
            flex-shrink: 0;
            ${fluidScale({
        minPx: 194,
        maxPx: 194 * widestScaleFactor,
        minMqPx: WIDE_BP,
        maxMqPx: WIDEST_BP,
        cssPropertyName: 'flex-basis',
      })}
            display: flex;
            align-items: center;
            color: ${theme.colors.white};
            font-family: ${theme.font.family.alternate};
            font-weight: ${theme.font.weight.extraBold}; /* @TODO should be bold */
            letter-spacing: 0;
            line-height: ${16 / 12};
            margin-bottom: ${theme.pxToRem(30)}rem;
            ${fluidScale({
        minPx: 12, maxPx: 12 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
      })}

            ${theme.bp.tablet} {
              margin-bottom: 0;
            }

            @media (hover: hover) {
              &:hover {
                color: ${theme.colors.white};
              }
            }

            &:focus-visible {
              --logo-focus-underline-color: ${theme.colors.white};
            }

            ${fluidScale({
        minPx: 40,
        maxPx: 258,
        minMqPx: 768,
        maxMqPx: 1300,
        cssPropertyName: 'margin-right',
      })}
          `}
      onMouseEnter={() => setVwLogoHovered(true)}
      onMouseLeave={() => setVwLogoHovered(false)}
      onFocus={() => setVwLogoHovered(true)}
      onBlur={() => setVwLogoHovered(false)}
    >
      <ThemedVWLogoSVG
        isHovered={vwLogoHovered}
        css={css`
              ${fluidScale({
          minPx: 73,
          maxPx: 73 * logoScaleFactor,
          minMqPx: WIDE_BP,
          maxMqPx: WIDEST_BP,
          cssPropertyName: 'width',
        })}
          ${fluidScale({
          minPx: 71,
          maxPx: 71 * logoScaleFactor,
          minMqPx: WIDE_BP,
          maxMqPx: WIDEST_BP,
          cssPropertyName: 'height',
        })}
          ${fluidScale({
          minPx: -7,
          maxPx: -7 * logoScaleFactor,
          minMqPx: WIDE_BP,
          maxMqPx: WIDEST_BP,
          cssPropertyName: 'margin',
        })}
            `}
      />
      <span
        css={css`
              margin-left: ${theme.pxToRem(17)}rem;
              pointer-events: none;
            `}
      >
        <span css={logoText}>Digital First.</span>
        <span css={(logoText, logoFocus)}>Adventure Always.</span>
      </span>
    </VWLink>
  );
};
