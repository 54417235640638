import { css } from '@emotion/react';
import { fluidScale } from './fluidScale';
import {
  WIDEST_BP, WIDE_BP, TABLET_BP, MOBILE_BP, DESKTOP_BP,
} from './theme';

export const tabletScaleFactor = 24 / 15;
export const widestScaleFactor = 75 / 45;
export const logoScaleFactor = 1.3;

const headingBase = theme => css`
  font-family: ${theme.font.family.alternate};
  font-weight: ${theme.font.weight.extraBold};
`;

export const headingLarge = theme => css`
  font-family: ${theme.font.family.alternate};
  font-weight: ${theme.font.weight.black};
`;

// home page hero text -desktop
export const heading130 = theme => css`
  ${headingLarge(theme)}
  font-size: ${theme.pxToRem(130)}rem;
  line-height: ${130 / 120};
  letter-spacing: -1.5px;
`;

export const fluidHeading130 = theme => css`
  ${headingLarge(theme)}
  letter-spacing:${-0.25 / 54}em;
  font-size: ${theme.pxToRem(54)}rem;
  line-height: 1.3;
  ${fluidScale({
    minPx: 48,
    maxPx: 100,
    minMqPx: 320,
    maxMqPx: TABLET_BP,
  })}

  ${theme.bp.tablet} {
    letter-spacing:${-1.5 / 130}em;
    ${fluidScale({
    minPx: 71,
    maxPx: 130,
    minMqPx: TABLET_BP,
    maxMqPx: WIDE_BP,
  })}
  }

  ${theme.bp.wide} {
    ${fluidScale({
    minPx: 130, maxPx: 130 * 1.4, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }
`;

// Large hero text
export const heading110 = theme => css`
  ${headingLarge(theme)}
  font-size: ${theme.pxToRem(110)}rem;
  line-height: ${110 / 110};
  letter-spacing: -1.25px;
`;

export const fluidHeading110 = theme => css`
  ${headingLarge(theme)}
  letter-spacing:${-0.25 / 54}em;
  line-height: ${54 / 54};
  ${fluidScale({
    minPx: 42, maxPx: 90, minMqPx: 320, maxMqPx: TABLET_BP,
  })}

  ${theme.bp.tablet} {
    letter-spacing:${1.25 / 110}em;
    line-height: ${110 / 110};
    ${fluidScale({
    minPx: 80, maxPx: 110, minMqPx: TABLET_BP, maxMqPx: WIDE_BP,
  })}

  ${theme.bp.wide} {
    ${fluidScale({
    minPx: 110, maxPx: 174, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }
`;

export const heading90 = theme => css`
  ${headingLarge(theme)}
  font-size: ${theme.pxToRem(90)}rem;
  line-height: ${95 / 90};
  letter-spacing: -1px;
`;

export const fluidHeading90 = theme => css`
  ${headingLarge(theme)}
  letter-spacing:${-0.25 / 54}em;
  font-size: ${theme.pxToRem(54)}rem;
  line-height: ${54 / 54};

  ${theme.bp.tablet} {
    letter-spacing:${-1 / 90}em;
    line-height: ${95 / 90};
    ${fluidScale({
    minPx: 70, maxPx: 90, minMqPx: TABLET_BP, maxMqPx: WIDE_BP,
  })}
  }
  
  ${theme.bp.wide} {
    ${fluidScale({
    minPx: 90, maxPx: 90 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }
`;

// Nav text on Desktop (only)
export const heading80 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -1px;
  font-size: ${theme.pxToRem(80)}rem;
  line-height: ${96 / 80};
`;

export const fluidHeading80 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.5px;
  font-size: ${theme.pxToRem(50)}rem;
  line-height: ${50 / 50};

  ${theme.bp.tablet} {
    letter-spacing:${-1 / 80}em;
    line-height: ${96 / 80};
    ${fluidScale({
    minPx: 64, maxPx: 80, minMqPx: TABLET_BP, maxMqPx: DESKTOP_BP,
  })}

  ${theme.bp.wide} {
    ${fluidScale({
    minPx: 80, maxPx: 80 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }
`;

// blog hero text - desktop
export const heading55 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.5px;
  font-size: ${theme.pxToRem(55)}rem;
  line-height: ${68 / 55};
`;

export const fluidHeading55 = theme => css`
  ${headingBase(theme)}
  letter-spacing: ${-0.2 / 30}em;
  line-height: ${40 / 30};
  ${fluidScale({
    minPx: 30, maxPx: 55, minMqPx: MOBILE_BP, maxMqPx: TABLET_BP,
  })}

  ${theme.bp.tablet} {
    letter-spacing:${-0.5 / 55}em;
    line-height: ${68 / 55};
    ${fluidScale({
    minPx: 55, maxPx: 55 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }
`;

// home page hero text -mobile
export const heading54 = theme => css`
  ${headingLarge(theme)}
  letter-spacing: -0.25px;
  font-size: ${theme.pxToRem(54)}rem;
  line-height: ${54 / 54};
`;

// Nav text on Mobile
export const heading50 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.5px;
  font-size: ${theme.pxToRem(50)}rem;
  line-height: ${50 / 50};
`;

export const heading45 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.4px;
  font-size: ${theme.pxToRem(45)}rem;
  line-height: ${62 / 45};
`;
// font size 75 at WIDEST_BPpx

export const fluidHeading45 = theme => css`
  ${headingBase(theme)}
  letter-spacing: ${-0.2 / 30}em;
  font-size: ${theme.pxToRem(30)}rem;
  line-height: ${40 / 30};

  ${theme.bp.tablet} {
    letter-spacing:${-0.4 / 45}em;
    line-height: ${62 / 45};
    ${fluidScale({
    minPx: 45, maxPx: 75, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }
`;

// QuoteBlock
export const heading36 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.25px;
  font-size: ${theme.pxToRem(36)}rem;
  line-height: ${50 / 36};
`;

// QuoteBlock
export const fluidHeading36 = theme => css`
  ${headingBase(theme)}
  letter-spacing: ${-0.2 / 30}em;
  line-height: ${40 / 30};
  ${fluidScale({
    minPx: 26, maxPx: 30 * tabletScaleFactor, minMqPx: 320, maxMqPx: TABLET_BP,
  })}

  
  ${theme.bp.tablet} {
    letter-spacing: ${-0.25 / 36}em;
    line-height: ${50 / 36};
    ${
  fluidScale({
    minPx: 36, maxPx: 36 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })
}
  }
`;

export const heading34 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.5px;
  font-size: ${theme.pxToRem(34)}rem;
  line-height: ${46 / 34};
`;

export const fluidHeading34 = theme => css`
  ${headingBase(theme)}
  letter-spacing: ${-0.4 / 28}em;
  line-height: ${36 / 28};
  ${fluidScale({
    minPx: 28, maxPx: 28 * tabletScaleFactor, minMqPx: MOBILE_BP, maxMqPx: TABLET_BP,
  })}
  
  ${theme.bp.tablet} {
    letter-spacing: ${-0.5 / 34}em;
    line-height: ${46 / 34};
    ${fluidScale({
    minPx: 34, maxPx: 34 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }
`;

// feature text mobile
export const heading30 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.2px;
  font-size: ${theme.pxToRem(30)}rem;
  line-height: ${40 / 30};
`;

// Text editor H2 (taken from blog page design)
// fiftyfifty heading
// bloglist headings
export const heading28 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.4px;
  font-size: ${theme.pxToRem(28)}rem;
  line-height: ${36 / 28};
`;

export const fluidHeading28 = theme => css`
  ${headingBase(theme)}
  letter-spacing: ${-0.25 / 22}em;
  line-height: ${30 / 22};
  ${fluidScale({
    minPx: 22, maxPx: 22 * tabletScaleFactor, minMqPx: MOBILE_BP, maxMqPx: TABLET_BP,
  })}

  ${theme.bp.tablet} {
    letter-spacing: ${-0.4 / 28}em;
    line-height: ${36 / 28};
      ${fluidScale({
    minPx: 28, maxPx: 28 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }
`;

// 2-up card heading
export const heading22 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.25px;
  font-size: ${theme.pxToRem(22)}rem;
  line-height: ${30 / 22};
`;

export const fluidHeading22 = theme => css`
  ${headingBase(theme)}
  letter-spacing: ${-0.2 / 20}em;
  font-size: ${theme.pxToRem(20)}rem;
  line-height: ${28 / 20};

  ${theme.bp.tablet} {
    letter-spacing: ${-0.25 / 22}em;
    line-height: ${30 / 22};
      ${fluidScale({
    minPx: 22, maxPx: 22 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }
`;

// Text editor H3 (taken from blog page design)
export const heading20 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.2px;
  font-size: ${theme.pxToRem(20)}rem;
  line-height: ${28 / 20};
`;

export const fluidHeading20 = theme => css`
  ${headingBase(theme)}
  letter-spacing: ${-0.2 / 20}em;
  line-height: ${28 / 20};
  ${fluidScale({
    minPx: 20, maxPx: 20 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

export const heading18 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.1px;
  font-size: ${theme.pxToRem(18)}rem;
  line-height: ${24 / 18};
`;

export const fluidHeading18 = theme => css`
  ${headingBase(theme)}
  letter-spacing: ${-0.1 / 18}em;
  line-height: ${24 / 18};
  ${fluidScale({
    minPx: 18, maxPx: 18 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

// Team list card titles
export const heading16 = theme => css`
  ${headingBase(theme)}
  letter-spacing: 0px;
  font-size: ${theme.pxToRem(16)}rem;
  line-height: ${19 / 16};
`;

export const fluidHeading16 = theme => css`
  ${headingBase(theme)}
  letter-spacing: 0;
  line-height: ${19 / 16};
  ${fluidScale({
    minPx: 16, maxPx: 16 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

// three up card heading - desktop
export const cardHeading16 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.15px;
  font-size: ${theme.pxToRem(16)}rem;
  line-height: ${22 / 16};
`;

// three up card heading - desktop - single use so quite specific
export const fluidCardHeading16 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.2px;
  line-height: ${20 / 15};
  ${fluidScale({
    minPx: 15, maxPx: 15 * tabletScaleFactor, minMqPx: MOBILE_BP, maxMqPx: TABLET_BP,
  })}

  ${theme.bp.tablet} {
    letter-spacing: ${-0.15 / 16}em;
    line-height: ${22 / 16};
    ${fluidScale({
    minPx: 16, maxPx: 16 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
  }
`;

// three up card heading - mobile
export const heading15 = theme => css`
  ${headingBase(theme)}
  letter-spacing: -0.2px;
  font-size: ${theme.pxToRem(15)}rem;
  line-height: ${20 / 15};
`;

// contact links in header, i.e. 'Say hello', 'Social'
export const heading14 = theme => css`
  ${headingBase(theme)}
  letter-spacing: 0;
  font-size: ${theme.pxToRem(14)}rem;
  line-height: ${24 / 14};
`;

// contact links in header, i.e. 'Say hello', 'Social'
export const fluidHeading14 = theme => css`
  ${headingBase(theme)}
  letter-spacing: 0;
  line-height: ${24 / 14};
  ${fluidScale({
    minPx: 14, maxPx: 14 * widestScaleFactor, minMqPx: 1440, maxMqPx: 2400,
  })}
`;

const textBase = theme => css`
  font-family: ${theme.font.family.base};
  font-weight: ${theme.font.weight.base};
`;

export const text18 = theme => css`
  ${textBase(theme)}
  letter-spacing: 0.2px;
  font-size: ${theme.pxToRem(18)}rem;
  line-height: ${32 / 18};
`;

export const fluidText18 = theme => css`
  ${textBase(theme)}
  letter-spacing: ${0.2 / 17}em;
  line-height: ${30 / 17};
  ${fluidScale({
    minPx: 17, maxPx: 18, minMqPx: MOBILE_BP, maxMqPx: TABLET_BP,
  })}

  ${theme.bp.tablet} {
    letter-spacing: ${0.2 / 18}em;
    line-height: ${32 / 18};
      ${fluidScale({
    minPx: 18, maxPx: 18 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

export const text17 = theme => css`
  ${textBase(theme)}
  letter-spacing: 0.2px;
  font-size: ${theme.pxToRem(17)}rem;
  line-height: ${30 / 17};
`;

export const fluidText17 = theme => css`
  ${textBase(theme)}
  letter-spacing: ${0.2 / 17}em;
  line-height: ${30 / 17};
  ${fluidScale({
    minPx: 17, maxPx: 17 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

// case study intro bullet list
export const text16 = theme => css`
  ${textBase(theme)}
  letter-spacing: 0.2px;
  font-size: ${theme.pxToRem(16)}rem;
  line-height: ${26 / 16};
`;

export const fluidText16 = theme => css`
  ${textBase(theme)}
  letter-spacing: ${0.2 / 16}em;
  line-height: ${26 / 16};
  ${fluidScale({
    minPx: 16, maxPx: 16 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

// bullet list
export const text15 = theme => css`
  ${textBase(theme)}
  letter-spacing: 0.2px;
  font-size: ${theme.pxToRem(15)}rem;
  line-height: ${26 / 15};
`;

export const fluidText15 = theme => css`
  ${textBase(theme)}
  letter-spacing: ${0.2 / 15}em;
  line-height: ${26 / 15};
  ${fluidScale({
    minPx: 15, maxPx: 15 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

// expander list
export const text14 = theme => css`
  ${textBase(theme)}
  letter-spacing: 0.2px;
  font-size: ${theme.pxToRem(14)}rem;
  line-height: ${22 / 14};
`;

export const fluidText14 = theme => css`
  ${textBase(theme)}
  letter-spacing: ${0.2 / 14}em;
  line-height: ${22 / 14};
  ${fluidScale({
    minPx: 14, maxPx: 14 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

export const text13 = theme => css`
  ${textBase(theme)}
  letter-spacing: 0.2px;
  font-size: ${theme.pxToRem(13)}rem;
  line-height: ${15 / 13};
`;

export const fluidText13 = theme => css`
  ${textBase(theme)}
  letter-spacing: ${0.2 / 13}em;
  line-height: ${15 / 13};
  ${fluidScale({
    minPx: 13, maxPx: 13 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

// menu button text
export const text12 = theme => css`
  ${textBase(theme)}
  letter-spacing: 0.18px;
  font-size: ${theme.pxToRem(12)}rem;
  line-height: ${14 / 12};
`;

// menu button text
export const fluidText12 = theme => css`
  ${textBase(theme)}
  letter-spacing: ${0.18 / 12}em;
  line-height: ${14 / 12};
  ${fluidScale({
    minPx: 12, maxPx: 12 * logoScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}
`;

export const textInherit = css`
  font-family: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  line-height: inherit;
`;
