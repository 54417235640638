/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { TABLET_BP, DESKTOP_BP } from '../composableStyles/theme';

export const MediaQueryContext = createContext();

export const MediaQueryProvider = ({ children }) => {
  const isBrowser = typeof window !== 'undefined'; // https://www.gatsbyjs.com/docs/debugging-html-builds/

  const mqTablet = isBrowser
    ? window.matchMedia(`(min-width: ${TABLET_BP}px)`) // eslint-disable-line no-undef
    : {};
  const mqDesktop = isBrowser
    ? window.matchMedia(`(min-width: ${DESKTOP_BP}px)`) // eslint-disable-line no-undef
    : {};
  const mqHover = isBrowser ? window.matchMedia('(hover: hover)') : {}; // eslint-disable-line no-undef

  const [isTablet, setIsTablet] = useState(mqTablet.matches);
  const [isDesktop, setIsDesktop] = useState(mqDesktop.matches);
  const [isHover, setIsHover] = useState(mqHover.matches);

  const handleMqTabletChange = () => {
    setIsTablet(mqTablet.matches);
  };

  const handleMqDesktopChange = () => {
    setIsDesktop(mqDesktop.matches);
  };

  const handleMqHoverChange = () => {
    setIsHover(mqHover.matches);
  };

  // add change listener (deal with legacy implementation)
  if (isBrowser) {
    if (
      mqTablet.addEventListener
      && typeof mqTablet.addEventListener === 'function'
    ) {
      mqTablet.addEventListener('change', handleMqTabletChange);
      mqDesktop.addEventListener('change', handleMqDesktopChange);
      mqDesktop.addEventListener('change', handleMqHoverChange);
    } else {
      mqTablet.addListener(handleMqTabletChange);
      mqDesktop.addListener(handleMqDesktopChange);
      mqDesktop.addListener(handleMqHoverChange);
    }
  }

  return (
    <MediaQueryContext.Provider
      value={{
        isTablet,
        isDesktop,
        isHover,
      }}
    >
      {children}
    </MediaQueryContext.Provider>
  );
};

MediaQueryProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

MediaQueryProvider.defaultProps = {
  children: '',
};
