/** @jsx jsx */
import { jsx, css, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { fluidHeading45 } from '../composableStyles/text';
import { container } from '../composableStyles/layout';
import { WysiwygText } from './WysiwygText';
import { DecorativeDots } from './DecorativeDots';
import {
  decorationDefaultValue,
  decorationPropTypes,
  spaceBelowDefaultValue,
} from '../utilities/componentPropTypes';
import { marginBottom } from '../composableStyles/marginBottom';

// layout to be confirmed, likely

// 0: 16 col max width, left aligned to 22 column width, maybe tweak padding

const layoutOptions = {
  left: theme => css`
    ${container(theme, 22)}

    & > * {
      max-width: ${theme.layout.maxWidthCols(15)};

      ${theme.bp.wide} {
        max-width: ${(theme.layout.maxWidthColsPx(15) / theme.layout.maxWidthColsPx(24)) * 100}%;
      }
    }
  `,
  center: theme => css`
    ${container(theme, 18)}
  `,
  right: theme => css`
    ${container(theme, 18)}
  `,
};

const decorationStyles = {
  topLeft: css`
    top: -80px;
    left: -90px;
  `,
  bottomLeft: css`
    bottom: -80px;
    left: -90px;
  `,
  topRight: css`
    top: -80px;
    right: -90px;
  `,
  bottomRight: css`
    bottom: -80px;
    right: -90px;
  `,
};

export const FeatureText = ({
  text, layout, decoration, spaceBelow,
}) => {
  const theme = useTheme();

  return (
    <div
      css={[
        layoutOptions[layout](theme),
        marginBottom(theme, spaceBelow),
      ]}
    >
      <WysiwygText
        css={[
          css`
            position: relative;
            z-index: 2;
            color: ${theme.colors.black};

            & a {
              background-position: 0 1.09em;
            }
          `,
          fluidHeading45(theme),
        ]}
        {...{ text }}
      />
      {decoration !== decorationDefaultValue && (
        <DecorativeDots
          css={css`
            position: absolute;
            z-index: 1;
            ${decorationStyles[decoration] || ''}
            display: none;

            ${theme.bp.tablet} {
              display: block;
            }
          `}
        />
      )}
    </div>
  );
};

FeatureText.propTypes = {
  text: PropTypes.node.isRequired,
  layout: PropTypes.oneOf(Object.keys(layoutOptions)),
  decoration: decorationPropTypes,
  spaceBelow: PropTypes.string,
};

FeatureText.defaultProps = {
  layout: Object.keys(layoutOptions)[0],
  decoration: decorationDefaultValue,
  spaceBelow: spaceBelowDefaultValue,
};
