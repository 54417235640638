/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import {
  GatsbyImage,
  getImage,
  withArtDirection,
} from 'gatsby-plugin-image';

// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#withartdirection

export const VWArtDirectedImage = ({
  images, alt, ...otherProps
}) => {
  const [{ image: base }, ...variants] = images;

  const image = withArtDirection(
    getImage({ gatsbyImageData: base }),
    variants.map(({ image: variant, media }) => ({
      media,
      image: getImage({ gatsbyImageData: variant }),
    })),
  );

  const artDirectionStyles = css`
    width: 100%;
    aspect-ratio: ${base.aspectRatio};

    ${variants.reduce((acc, { media, aspectRatio }) => `${acc} @media screen and ${media} { aspect-ratio: ${aspectRatio}; }`, '')}
  `;

  if (image) {
    const { alt: altFromImage } = base;
    return (
      <GatsbyImage
        {...{ image }}
        alt={altFromImage || alt}
        {...otherProps}
        css={artDirectionStyles}
      />
    );
  }

  return null;
};

export const vwImagePropTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    aspectRatio: PropTypes.string,
    image: PropTypes.shape({}),
    media: PropTypes.string,
  })),
  alt: PropTypes.string,
};

VWArtDirectedImage.propTypes = vwImagePropTypes;

VWArtDirectedImage.defaultProps = {
  images: [],
  alt: '',
};
