/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import {
  useContext, useRef,
} from 'react';
import styled from '@emotion/styled';
import anime from 'animejs/lib/anime.es';
import { VWLink } from './VWLink';
import { MediaQueryContext } from '../contexts/MediaQueryProvider';
import { widestScaleFactor } from '../composableStyles/text';
import { WIDEST_BP, WIDE_BP } from '../composableStyles/theme';
import { fluidScale } from '../composableStyles/fluidScale';

const PATH_INITIAL = 'm5,17.4c1.4,0,2,-1.3,2,-2.7s-1.1,-2,-2.5,-2s-2.5,1,-2.5,2.3s1.6,2.4,3,2.4z';
const PATH_MORPHED = 'm13.4,27.9c7.2,0,14.6,-5,14.6,-12.1s-7.4,-13.7,-14.6,-13.7s-11.4,6.6,-11.4,13.7s4.2,12.1,11.4,12.1z';

/* eslint-disable no-undef */
const getStyledButton = element => styled(element)`
  display: inline-block;
  padding: ${7 / 13}em 0 ${7 / 13}em ${15 / 13}em;
  font-family: ${props => props.theme.font.family.alternate};
  font-weight: ${props => props.theme.font.weight.bold};
  letter-spacing: 0;
  line-height: ${16 / 13};
  position: relative;
  height: ${30 / 13}em;
  text-underline-offset: 0.35em;
  ${fluidScale({
    minPx: 13, maxPx: 13 * widestScaleFactor, minMqPx: WIDE_BP, maxMqPx: WIDEST_BP,
  })}

  &,
  &:hover {
    color: ${props => props.theme.colors.black};
  }

  &:focus-visible {
    text-decoration: underline;
  }
`;

const StyledSVG = styled.svg`
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: ${40 / 13}em;
  height: ${30 / 13}em;
  pointer-events: none;
  fill: ${props => props.theme.colors.pink};
`;

const StyledLabel = styled.span`
  position: relative;
  z-index: 5;
`;
/* eslint-enable no-undef */

export const BulletButton = ({ label, isFaux, ...otherProps }) => {
  const { isHover: isHoverEnabled } = useContext(MediaQueryContext);
  const pathRef = useRef(null);
  const StyledButton = getStyledButton(isFaux ? 'div' : VWLink);

  const animateTheShape = isMorphed => {
    // cancel running animation on this element
    anime.remove(pathRef.current);

    anime({
      targets: pathRef.current,
      d: [{ value: isMorphed ? PATH_MORPHED : PATH_INITIAL }],
      duration: 1000,
    });
  };

  return (
    <StyledButton
      {...otherProps}
      onMouseEnter={() => animateTheShape(isHoverEnabled)}
      onMouseLeave={() => animateTheShape(false)}
      onFocus={() => animateTheShape(true)}
      onBlur={() => animateTheShape(false)}
    >
      <StyledSVG viewBox="0 0 40 30" focusable="false" aria-hidden="true">
        <path ref={pathRef} d={PATH_INITIAL} />
      </StyledSVG>
      <StyledLabel>{label}</StyledLabel>
    </StyledButton>
  );
};

BulletButton.propTypes = {
  label: PropTypes.node.isRequired,
  isFaux: PropTypes.bool,
};

BulletButton.defaultProps = {
  isFaux: false,
};
