/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import {
  useContext, useMemo, useRef, useState,
} from 'react';
import { vwImagePropTypes } from './VWImage';
import { ImageSlider } from './ImageSlider';
import { ImageSliderDragPrompt } from './ImageSliderDragPrompt';
import { getRelativeCoordinates } from '../utilities/mousePosition';
import { marginBottom } from '../composableStyles/marginBottom';
import { MediaQueryContext } from '../contexts/MediaQueryProvider';

export const ImageSliderWrapper = ({
  frame,
  images,
  spaceBelow,
  loading,
}) => {
  const theme = useTheme();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isDragPromptVisible, setIsDragPromptVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { isHover } = useContext(MediaQueryContext);
  const wrapperRef = useRef();
  const isInset = frame === 'inset';

  const handleMouseMove = e => {
    setMousePosition(
      getRelativeCoordinates(
        e,
        wrapperRef.current.getBoundingClientRect(),
      ),
    );
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseOver = isHover
    ? ({ clientX, clientY }) => {
      if (isHover) {
        const wrapperClientRect = wrapperRef.current.getBoundingClientRect();
        const { bottom } = wrapperClientRect;

        // set initial x, y of the drag prompt to try and reduce the jank
        setMousePosition(getRelativeCoordinates({ clientX, clientY }, wrapperClientRect));

        // check the y coordinate on screen of the mouse is above or equal the bottom of the wrapper
        // i.e. avoid being triggered by the dots
        if (clientY <= bottom) {
          setIsDragPromptVisible(true);
        }
      }
    }
    : null;

  const handleMouseLeave = () => {
    setIsDragPromptVisible(false);
  };

  // Memoize the slider to prevent re-renders on mouse movement
  const memoizedSlider = useMemo(
    () => (
      <ImageSlider
        handleMouseMove={isHover ? handleMouseMove : null}
        handleMouseDown={isHover ? handleMouseDown : null}
        handleMouseUp={isHover ? handleMouseUp : null}
        {...{
          images,
          loading,
          isInset,
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [images, isHover],
  );

  if (!images.length) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div // eslint-disable-line jsx-a11y/mouse-events-have-key-events
      ref={wrapperRef}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      css={[
        css`
          position: relative;
        `,
        isDragPromptVisible ? css`cursor: none;` : null,
        marginBottom(theme, spaceBelow),
      ]}
    >
      {isHover && (
        <ImageSliderDragPrompt
          {...mousePosition}
          isVisible={isDragPromptVisible}
          {...{ isDragging }}
        />
      )}
      {memoizedSlider}
    </div>
  );
};

ImageSliderWrapper.propTypes = {
  frame: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape(vwImagePropTypes)).isRequired,
  spaceBelow: PropTypes.string.isRequired,
  loading: PropTypes.string.isRequired,
};
