/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { WysiwygText } from './WysiwygText';
import { SectionHeading } from './SectionHeading';
import { DecorativeDots } from './DecorativeDots';
import { VWImage } from './VWImage';
import { container, subContainer } from '../composableStyles/layout';
import { fluidHeading110, widestScaleFactor } from '../composableStyles/text';
import {
  decorationDefaultValue,
  decorationPropTypes,
  spaceBelowDefaultValue,
} from '../utilities/componentPropTypes';
import { marginBottom } from '../composableStyles/marginBottom';
import {
  TABLET_BP,
  WIDEST_BP, WIDE_BP, desktopDesignWidthPx, gridPX,
} from '../composableStyles/theme';
import { aspectRatio } from '../composableStyles/aspectRatio';
import { pseudoDecoration } from '../composableStyles/pseudoDecoration';
import { fluidScale } from '../composableStyles/fluidScale';
import { VWArtDirectedImage } from './VWArtDirectedImage';
import { heroRevealAnimation } from '../composableStyles/heroRevealAnimation';

const {
  imageReveal,
  surtitleReveal,
  titleReveal,
} = heroRevealAnimation;

/*
  Similar to Home Page Hero layout
*/

const cssCustomProperties = css`
  --image-z-index: 6;
  --image-overlay-z-index: 7;
  --dots-z-index: 5;
  --content-z-index: 10;
`;

export const HeroHalfImage = ({
  title,
  text,
  image,
  spaceBelow,
  decoration,
}) => {
  const theme = useTheme();

  const main = image;
  const mobile = image && image.thumbnails && image.thumbnails.mobile;

  const images = !!mobile && [
    {
      aspectRatio: `${mobile.gatsbyImageData.width} / ${mobile.gatsbyImageData.height}`,
      image: mobile && mobile.gatsbyImageData,
      media: null,
    },
    {
      aspectRatio: `${main.gatsbyImageData.width} / ${main.gatsbyImageData.height}`,
      image: main && main.gatsbyImageData,
      media: `(min-width: ${TABLET_BP}px)`,
    },
  ];

  const imageProps = {
    loading: 'eager',
    css: css`
      z-index: var(--image-z-index);
      height: 100%;
    `,
    alt: title,
  };

  return (
    <header
      css={[
        marginBottom(theme, spaceBelow),
        css`
          position: relative;
          width: 100%;
          padding-left: var(--container-grid-padding);
          padding-top: ${theme.pxToRem(90)}rem;
          padding-bottom: ${theme.pxToRem(90)}rem;

          ${theme.bp.tablet} {
            display: flex;
            justify-content: flex-end;
            padding-left: 0;
            ${fluidScale({
          minPx: 112,
          maxPx: 112 * widestScaleFactor,
          minMqPx: WIDE_BP,
          maxMqPx: WIDEST_BP,
          cssPropertyName: 'padding-top',
        })}
            ${fluidScale({
          minPx: 90,
          maxPx: 120,
          minMqPx: TABLET_BP,
          maxMqPx: WIDE_BP,
          cssPropertyName: 'padding-bottom',
        })}
          }

          ${theme.bp.wide} {
                ${fluidScale({
          minPx: 120,
          maxPx: 120 * widestScaleFactor,
          minMqPx: WIDE_BP,
          maxMqPx: WIDEST_BP,
          cssPropertyName: 'padding-bottom',
        })}
          }
        `,
        cssCustomProperties,
      ]}
    >
      <div
        css={[
          aspectRatio(1440, 1260),
          css`
            position: relative;
            margin-bottom: ${theme.scaledVerticalSpacingM(40)};
            
            ${theme.bp.tablet} {
              flex: 0 0 65%;
              padding-left: 0;
              margin-bottom: 0;
            }

            ${theme.bp.wide} {
              flex-basis: 56%;
              margin-right: 14%;
            }
            
            ${theme.bp.widest} {
              flex-basis: ${theme.pxToRem(WIDEST_BP * 0.56)}rem;
              margin-right: calc((100% - ${theme.pxToRem(WIDEST_BP * (1 - (0.14 * 2)))}rem) / 2);
            }

            &::before {
              ${theme.bp.tablet} {
                padding-top: ${(1680 / 2400) * 100}%;
              }
            }
          
            &::after {
              ${theme.bp.tablet} {
                ${pseudoDecoration({
            left: '0',
            top: '0',
            width: '76%',
            height: '100%',
          })}
                z-index: var(--image-overlay-z-index);
                background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
              }
            }
          `,
          imageReveal,
        ]}
      >
        {
          images
            ? <VWArtDirectedImage {...{ images }} {...imageProps} />
            : <VWImage image={main} {...imageProps} />
        }
      </div>
      <h1
        css={[
          container(theme),
          css`
            margin-left: calc(-1 * var(--container-grid-padding));
            
            ${theme.bp.tablet} {
              position: absolute;
              z-index: var(--content-z-index);
              width: 100%;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              margin-left: auto;
            }

            ${theme.bp.wide} {
              left: ${(50 / 1440) * 100}%;
            }

            ${theme.bp.widest} {
              left: calc((100% - ${WIDEST_BP * (1 - ((gridPX.padding.desktop * 2) / desktopDesignWidthPx))}px) / 2);
            }
          `,
        ]}
      >
        <SectionHeading
          level="span"
          css={[
            css`
              margin-bottom: ${theme.scaledVerticalSpacingM(24)};

              ${theme.bp.tablet} {
                ${fluidScale({
              minPx: 30,
              maxPx: 50,
              minMqPx: TABLET_BP,
              maxMqPx: WIDE_BP,
              cssPropertyName: 'margin-bottom',
            })}
              }
              
              ${theme.bp.wide} {
                ${fluidScale({
              minPx: 50,
              maxPx: 50 * widestScaleFactor,
              minMqPx: WIDE_BP,
              maxMqPx: WIDEST_BP,
              cssPropertyName: 'margin-bottom',
            })}
              }
            `,
            surtitleReveal,
          ]}
        >
          {title}
        </SectionHeading>
        <WysiwygText
          {...{ text }}
          css={[
            subContainer(theme, 22),
            fluidHeading110(theme),
            css`
              color: ${theme.colors.black};

              & > * {
                margin-bottom: 0;
                overflow-wrap: break-word;

                ${theme.bp.tablet} {
                  max-width: 78%;
                }
              }
            `,
            titleReveal,
          ]}
        />
      </h1>
      {decoration !== decorationDefaultValue && (
        <DecorativeDots
          isVisible
          css={css`
            position: absolute;
            z-index: var(--dots-z-index);
            display: none;

            ${theme.bp.tablet} {
              display: block;
              bottom: 0;
              left: calc(${100 - 65}% - 75px);
              ${fluidScale({
            minPx: 90 - 75,
            maxPx: 120 - 75,
            minMqPx: TABLET_BP,
            maxMqPx: WIDE_BP,
            cssPropertyName: 'bottom',
          })}
            }

            ${theme.bp.wide} {
              left: calc(${100 - 56 - 14}% - 75px);
              ${theme.bp.wide} {
                ${fluidScale({
            minPx: 120 - 75,
            maxPx: (120 * widestScaleFactor) - 75,
            minMqPx: WIDE_BP,
            maxMqPx: WIDEST_BP,
            cssPropertyName: 'bottom',
          })}
          }
            }
            
            ${theme.bp.widest} {
              left: calc(((100% - ${theme.pxToRem(WIDEST_BP * (1 - (0.3 * 2)))}rem) / 2) - 75px);
            }
          `}
        />
      )}
    </header>
  );
};

HeroHalfImage.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  image: PropTypes.shape({
    thumbnails: PropTypes.shape({
      mobile: PropTypes.shape({}),
    }),
  }).isRequired,
  spaceBelow: PropTypes.string,
  decoration: decorationPropTypes,
};

HeroHalfImage.defaultProps = {
  spaceBelow: spaceBelowDefaultValue,
  decoration: decorationDefaultValue,
};
