/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { WysiwygText } from './WysiwygText';
import { container } from '../composableStyles/layout';
import {
  componentThemeOptions,
  componentBackground,
} from '../composableStyles/backgrounds';
import { marginBottom } from '../composableStyles/marginBottom';
import { DecorativeDots } from './DecorativeDots';
import {
  decorationDefaultValue,
  decorationPropTypes,
  spaceBelowDefaultValue,
} from '../utilities/componentPropTypes';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';

const decorationStyles = {
  topLeft: css`
    top: -80px;
    left: -90px;
  `,
  bottomLeft: css`
    bottom: -80px;
    left: -90px;
  `,
  topRight: css`
    top: -80px;
    right: -90px;
  `,
  bottomRight: css`
    bottom: -80px;
    right: -90px;
  `,
};

// Weird bug passing container styles down to WysiwygText
// using extra wrapper div to patch for now
export const Text = ({
  content,
  componentTheme,
  decoration,
  spaceBelow,
  width,
}) => {
  const theme = useTheme();
  return (
    <div
      {...addNavColorAttribute(componentTheme)}
      css={[
        container(theme, width),
        componentBackground(theme, componentTheme),
        marginBottom(theme, spaceBelow),
      ]}
    >
      <WysiwygText text={content} />
      {decoration !== decorationDefaultValue && (
        <DecorativeDots
          css={css`
            position: absolute;
            z-index: -1;
            ${decorationStyles[decoration] || ''}
            display: none;

            ${theme.bp.tablet} {
              display: block;
            }
          `}
        />
      )}
    </div>
  );
};

Text.propTypes = {
  content: PropTypes.node,
  componentTheme: PropTypes.oneOf(componentThemeOptions),
  decoration: decorationPropTypes,
  width: PropTypes.number,
  spaceBelow: PropTypes.string,
};

Text.defaultProps = {
  content: '',
  componentTheme: componentThemeOptions[0],
  decoration: decorationDefaultValue,
  width: 18,
  spaceBelow: spaceBelowDefaultValue,
};
