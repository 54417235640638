export const EXTERNAL_LINK_TYPE = 'external';
export const INTERNAL_LINK_TYPE = 'internal';
export const OTHER_LINK_TYPE = 'other';

// Home Page Hero steps
export const HEADLINE_ONE_ENTER_STEP = 1;
export const HEADLINE_ONE_EXIT_STEP = 2;
export const VIDEO_ENTER_STEP = 3;
export const DOTS_ENTER_STEP = 5;
export const INTRO_ENTER_STEP = 6;

// Animation states
export const INITIAL_STATE = 'initial';
export const ENTER_STATE = 'enter';
export const EXIT_STATE = 'exit';

// Primary nav colour options
export const DARK_NAV_COLOR = 'Dark';
export const LIGHT_NAV_COLOR = 'Light';
export const NAV_COLOR_ATTRIBUTE_NAME = 'data-nav-color';

// Component Themes
export const DARK_COMPONENT_THEME = 'dark';
export const LIGHT_COMPONENT_THEME = 'light';
export const WHITE_COMPONENT_THEME = 'white';
