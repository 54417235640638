/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { FiftyFifty } from './FiftyFifty';
import {
  componentThemeOptions,
  componentBackground,
} from '../composableStyles/backgrounds';
import { marginBottom } from '../composableStyles/marginBottom';
import { spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';

export const FiftyFiftyPanels = ({
  items,
  spaceBelow,
  componentTheme,
  isAlternating,
  imgStartsOnRight,
}) => {
  const theme = useTheme();

  const imgOnRight = i => {
    if (!isAlternating) {
      return imgStartsOnRight;
    }

    return i % 2 === 0 ? imgStartsOnRight : !imgStartsOnRight;
  };

  return (
    <div
      {...addNavColorAttribute(componentTheme)}
      css={[
        componentBackground(theme, componentTheme),
        marginBottom(theme, spaceBelow),
      ]}
    >
      {items.map((props, i) => (
        <FiftyFifty key={`panel${i}`} imgOnRight={imgOnRight(i)} {...props} />
      ))}
    </div>
  );
};

FiftyFiftyPanels.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  isAlternating: PropTypes.bool,
  imgStartsOnRight: PropTypes.bool,
  spaceBelow: PropTypes.string,
  componentTheme: PropTypes.oneOf(componentThemeOptions),
};

FiftyFiftyPanels.defaultProps = {
  items: [],
  isAlternating: true,
  imgStartsOnRight: true,
  componentTheme: componentThemeOptions[0],
  spaceBelow: spaceBelowDefaultValue,
};
