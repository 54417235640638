/** @jsx jsx */
import * as React from 'react';
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { container } from '../composableStyles/layout';
import { PillButton } from './PillButton';
import { vwLinkPropTypes } from './VWLink';
import {
  componentThemeOptions,
  componentBackground,
} from '../composableStyles/backgrounds';
import { SectionHeader, sectionHeaderPropTypes } from './SectionHeader';
import { ContentListTwoRow } from './ContentListTwoRow';
import { ContentListThreeRow } from './ContentListThreeRow';
import { DecorativeDots } from './DecorativeDots';
import { marginBottom } from '../composableStyles/marginBottom';
import {
  contentListCardPropTypes,
  decorationDefaultValue,
  decorationPropTypes,
  spaceBelowDefaultValue,
} from '../utilities/componentPropTypes';
import { fluidScale } from '../composableStyles/fluidScale';
import {
  TABLET_BP,
  WIDE_BP,
  WIDEST_BP,
} from '../composableStyles/theme';
import { widestScaleFactor } from '../composableStyles/text';
import { addNavColorAttribute } from '../utilities/addNavColorAttribute';

const layoutOptions = {
  twoRow: ContentListTwoRow,
  twoStagger: ContentListTwoRow,
  threeRow: ContentListThreeRow,
};

const decorationStyles = {
  topLeft: css`
    top: -80px;
    left: -90px;
  `,
  bottomLeft: css`
    bottom: -80px;
    left: -90px;
  `,
  topRight: css`
    top: -80px;
    right: -90px;
  `,
  bottomRight: css`
    bottom: -80px;
    right: -90px;
  `,
};

export const ContentList = ({
  header,
  layout,
  items,
  button,
  componentTheme,
  decoration,
  spaceBelow,
}) => {
  const theme = useTheme();
  const TagName = header && header.section ? 'section' : 'div';
  const numCards = items.length;
  const ListComponent = layoutOptions[layout];
  const isStaggered = layout === 'twoStagger';

  const staggerWrapperStyles = css`
    ${theme.bp.tablet} {
      padding-bottom: var(--stagger-offset);
      position: relative;
      ${
  fluidScale({
    minPx: 100,
    maxPx: 150,
    minMqPx: TABLET_BP,
    maxMqPx: WIDE_BP,
    cssPropertyName: '--stagger-offset',
  })
}
    }

    ${theme.bp.wide} {
      ${
  fluidScale({
    minPx: 150,
    maxPx: 150 * widestScaleFactor,
    minMqPx: WIDE_BP,
    maxMqPx: WIDEST_BP,
    cssPropertyName: '--stagger-offset',
  })
}
    }
  `;

  const staggerButtonStyles = isStaggered
    ? css`
      ${theme.bp.tablet} {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    `
    : null;

  const innerContent = (
    <React.Fragment>
      {numCards > 0 && <ListComponent {...{ items, isStaggered }} />}
      {button && (
        <PillButton
          {...button}
          css={[
            css`
                margin-top: ${theme.spacer(2.5)};

                ${theme.bp.tablet} {
                  margin-top: ${theme.spacer(isStaggered ? 0 : 3)};
                }
              `,
            staggerButtonStyles,
          ]}
        />
      )}
    </React.Fragment>
  );

  return (
    <TagName
      {...addNavColorAttribute(componentTheme)}
      css={[
        componentBackground(theme, componentTheme),
        marginBottom(theme, spaceBelow),
        css`
          ${decoration !== decorationDefaultValue
          && 'background-color: transparent;'}
        `,
      ]}
    >
      {header && header.section && (
        <SectionHeader {...header} {...{ componentTheme }} />
      )}
      <div
        css={[
          container(theme, 22),
          css`
            position: relative;
          `,
        ]}
      >
        {
          isStaggered
            ? (
              <div
                css={staggerWrapperStyles}
              >
                {innerContent}
              </div>
            )
            : innerContent
        }
        {decoration !== decorationDefaultValue && (
          <DecorativeDots
            css={css`
              position: absolute;
              z-index: -1;
              ${decorationStyles[decoration] || ''}
              display: none;

              ${theme.bp.tablet} {
                display: block;
              }
            `}
          />
        )}
      </div>
    </TagName>
  );
};

ContentList.propTypes = {
  header: PropTypes.shape(sectionHeaderPropTypes),
  componentTheme: PropTypes.oneOf(componentThemeOptions),
  layout: PropTypes.oneOf(Object.keys(layoutOptions)),
  items: PropTypes.arrayOf(contentListCardPropTypes),
  button: PropTypes.oneOfType([
    PropTypes.shape(vwLinkPropTypes),
    PropTypes.bool,
  ]),
  decoration: decorationPropTypes,
  spaceBelow: PropTypes.string,
};

ContentList.defaultProps = {
  header: {},
  componentTheme: componentThemeOptions[0],
  layout: Object.keys(layoutOptions)[0],
  items: [],
  button: false,
  decoration: decorationDefaultValue,
  spaceBelow: spaceBelowDefaultValue,
};
