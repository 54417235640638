import { css } from '@emotion/react';
import { fluidText15 } from './text';

// sad repetition of styles in this file due to not being able to figure out
// why the emotion composition isn't working, didn't want to waste any more
// time so moved on. Should be refactored if figure it out

export const bulletStyles = theme => css`
  position: relative;
  padding-left: ${16 / 15}em;
  margin-bottom: ${8 / 15}em;
  ${fluidText15(theme)}

  &:last-of-type {
    margin-bottom: 0;
  }

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: ${12 / 15}em;
    width: ${6 / 15}em;
    height: 2px;
    background-color: ${theme.colors.pink};
  }
`;

// This is a repetition of the above bulletStyles
export const bulletItem = theme => css`
  ul li {
    position: relative;
    padding-left: ${16 / 15}em;
    margin-bottom: ${8 / 15}em;
    ${fluidText15(theme)}

    &:last-of-type {
      margin-bottom: 0;
    }

    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: ${12 / 15}em;
      width: ${6 / 15}em;
      height: ${2 / 15}em;
      background-color: ${theme.colors.pink};
    }
  }
`;

export const bulletList = () => css`
  ul {
  }
`;
