import { css } from '@emotion/react';

export const defaultLinkStyles = (theme, hasUnderlineOnHover = false) => css`
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-offset: 0.3em;
  transition: color ${theme.transitions.link},
    text-decoration-color ${theme.transitions.link};

  @media (hover: hover) {
    &:hover {
      color: ${theme.colors.pink};
      text-decoration-color: ${hasUnderlineOnHover ? theme.colors.pink : 'transparent'};
    }
  }

  &:focus-visible {
    color: ${theme.colors.pink};
    text-decoration-color: ${theme.colors.pink};
    
    &:active {
      text-decoration-color: ${theme.colors.pinkDark};
    }
  }

  &:active {
    color: ${theme.colors.pinkDark};
  }
`;
