import { css } from '@emotion/react';
import {
  WIDEST_BP,
  desktopDesignWidthPx,
  gridPX,
} from './theme';

const containerBase = css`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const proportionOfGrid = (theme, col) => col === gridPX.max_col
  ? 1
  : theme.layout.maxWidthColsPx(col) / theme.layout.maxWidthColsPx(gridPX.max_col);

export const fullGridAsAProportionOfScreen = 1 - (
  (gridPX.padding.desktop / desktopDesignWidthPx) * 2
);

// centered width container with padding
export const container = (theme, col = gridPX.max_col) => {
  const colGridAsAProportionOfScreen = fullGridAsAProportionOfScreen * proportionOfGrid(theme, col);
  const colGridAsAPercentageOfScreen = colGridAsAProportionOfScreen * 100;

  return css`
    ${containerBase}
    padding-left: var(--container-grid-padding);
    padding-right: var(--container-grid-padding);
    max-width: ${theme.layout.maxWidth(col)};

    ${theme.bp.wide} {
      padding-left: 0;
      padding-right: 0;
      max-width: ${colGridAsAPercentageOfScreen}%;
    }
    
    ${theme.bp.widest} {
      max-width: ${theme.pxToRem(WIDEST_BP * colGridAsAProportionOfScreen)}rem;
    }
  `;
};

// centered width container without outside padding
export const subContainer = (theme, col = gridPX.max_col) => {
  const percentageOfGrid = col === gridPX.max_col
    ? 100
    : (theme.layout.maxWidthColsPx(col) / theme.layout.maxWidthColsPx(gridPX.max_col)) * 100;

  return css`
    ${containerBase}
    max-width: ${theme.layout.maxWidthCols(col)};

    ${theme.bp.wide} {
      max-width: ${percentageOfGrid}%;
    }
  `;
};
