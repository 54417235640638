/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { fluidText12 } from '../composableStyles/text';

// wil use scale to get 26, 22 & 18

const StyledBurger = styled.span`
  position: relative;
  pointer-events: none;
  display: inline-block;
  margin-left: 8px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 4px;
  transform: translateY(-6px);

  &,
  &::before,
  &::after {
    top: 0;
    width: 26px;
    height: 2px;
    background-color: var(--menu-button-toggle-color);
    transition: all ${props => props.theme.transitions.link} ease-out,
      background-color ${props => props.theme.transitions.link} linear;
    transform-origin: left center;
  }

  &::before,
  &::after {
    position: absolute;
    content: " ";
    left: 0;
  }

  &::before {
    background-color: var(--menu-button-color);
    transform: translateY(6px) scaleX(0.8461538462);
  }

  &::after {
    transform: translateY(12px) scaleX(0.6923076923);
  }
`;

export const HeaderMenuButton = ({
  isCloseButton, handleMenuClick, ...otherProps
}) => {
  const theme = useTheme();

  return (
    <button
      type="button"
      aria-label={`${isCloseButton ? 'Close' : 'Open'} Menu`}
      onClick={handleMenuClick}
      css={[
        fluidText12(theme),
        css`
          --menu-button-color: var(--vw-logo-background);
          --menu-button-toggle-color: color-mix(in srgb, var(--vw-logo-background), transparent ${isCloseButton ? 100 : 0}%);

          display: flex;
          align-items: center;
          pointer-events: all;
          color: var(--menu-button-color);
          margin-right: var(--scrollbar-width-for-offset);
          transition: none;
          height: ${20 / 12}em;

          @media (hover: hover) {
            &:hover {
              --menu-button-color: ${theme.colors.pink};
              --menu-button-toggle-color: ${theme.colors.pink}${
          isCloseButton ? '00' : 'FF'
        };

              ${StyledBurger} {
                & {
                  transform: translateY(-6px);
                }

                &::before {
                  transform: translateY(6px) scaleX(1);
                }

                &::after {
                  transform: translateY(12px) scaleX(1);
                }

              }
            }
          }

          &:focus-visible {
            --menu-button-color: ${theme.colors.pink};
            --menu-button-toggle-color: ${theme.colors.pink}${
          isCloseButton ? '00' : 'FF'};

            ${StyledBurger} {
              & {
                transform: translateY(-6px);
              }

              &::before {
                transform: translateY(6px) scaleX(1);
              }

              &::after {
                transform: translateY(12px) scaleX(1);
              }
            }
          }
        `,
      ]}
      {...otherProps}
    >
      <span
        css={css`
          display: inline-block;
          pointer-events: none;
          transition: color ${theme.transitions.link} linear;
        `}
      >
        {isCloseButton ? 'Close' : 'Menu'}
      </span>
      <StyledBurger />
    </button>
  );
};

HeaderMenuButton.propTypes = {
  handleMenuClick: PropTypes.func.isRequired,
  isCloseButton: PropTypes.bool,
};

HeaderMenuButton.defaultProps = {
  isCloseButton: false,
};
