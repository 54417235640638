import { css } from '@emotion/react';

export const aspectRatio = (width, height) => css`
  position: relative;
  overflow: hidden;
  width: 100%;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: " ";
    display: block;
    width: 1px;
    margin-left: -1px;
    height: 0;
    padding-top: ${(height / width) * 100}%;
  }
`;
