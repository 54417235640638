/** @jsx jsx */
import { jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { textEditor } from '../composableStyles/textEditor';
import { bulletList, bulletItem } from '../composableStyles/bulletList';

// NOTE: I've been noticing some weirdness with trying to combine emotion styles
// it seems like if your chain of functions is too long it just breaks
// i.e. textEditor -> bulletList -> bulletItem -> text15 -> textBase
// I don't currently understand why.

export const WysiwygText = ({ tag, text, ...otherProps }) => {
  const theme = useTheme();
  const TagName = tag;
  const textstyles = [textEditor(theme), bulletList(theme), bulletItem(theme)];

  if (typeof text === 'string') {
    return (
      <TagName
        css={textstyles}
        {...otherProps}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  }

  return (
    <TagName css={textstyles} {...otherProps}>
      {text}
    </TagName>
  );
};

WysiwygText.propTypes = {
  tag: PropTypes.string,
  text: PropTypes.node,
};

WysiwygText.defaultProps = {
  tag: 'div',
  text: '',
};
