/* eslint react/prop-types: off */
import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { SliceZone } from '../components/SliceZone';
import { HomePageHero } from '../components/HomePageHero';
import { htmlSerializer } from '../utilities/htmlSerializer';

const HomeTemplate = ({ data }) => {
  if (!data) return null;

  const {
    headline_one: headlineOne,
    headline_two: headlineTwo,
    intro: { richText },
    button_label: label,
    button_link: { url: link },
    button_link_override: linkOverride,
    space_below: spaceBelow,
    mobile_hero_image: mobileImage,
    body,
  } = data.prismicHomepage.data;

  const url = linkOverride || link;

  return (
    <Layout>
      <SEO title="Home" />
      <HomePageHero
        intro={<RichText render={richText} {...{ htmlSerializer }} />}
        button={url ? { url, label } : false}
        {...{
          headlineOne, headlineTwo, spaceBelow, mobileImage,
        }}
      />
      <SliceZone sliceZone={body} />
    </Layout>
  );
};

export const query = graphql`
  query Homepage {
    prismicHomepage {
      data {
        headline_one
        headline_two
        intro {
          richText
        }
        button_label
        button_link {
          url
        }
        button_link_override
        space_below
        mobile_hero_image {
          gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          alt
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HomepageDataBodyQuote
          ...HomepageDataBodyFiftyFiftyPanels
          ...HomepageDataBodyCaseStudyList
          ...HomepageDataBodyServicesBlock
          ...HomepageDataBodyTeamListBlock
          ...HomepageDataBodyExpandablesListBlock
          ...HomepageDataBodyFeatureTextBlock
          ...HomepageDataBodyInlineImagesBlock
          ...HomepageDataBodyRelatedPosts
          ...HomepageDataBodyRichText
          ...HomepageDataBodyLogos
        }
      }
    }
  }
`;

HomeTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default withPrismicPreview(HomeTemplate);
