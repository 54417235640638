import { css } from '@emotion/react';

export const pseudoDecoration = ({
  left = '0',
  top = '0',
  width = '100%',
  height = '100%',
} = {}) => css`
  content: " ";
  position: absolute;
  left: ${left};
  top: ${top};
  width: ${width};
  height: ${height};
  background-color: currentColor;
`;
