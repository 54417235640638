/** @jsx jsx */
import {
  css, jsx, useTheme,
} from '@emotion/react';
import PropTypes from 'prop-types';

export const PlaySVG = ({ height, ...otherProps }) => {
  const theme = useTheme();

  // viewbox
  const view = {
    minX: 0,
    minY: 0,
    width: 21,
    height: 21,
  };

  return (
    <svg
      css={css`
        pointer-events: none;
        width: ${theme.pxToRem(height * (view.width / view.height))}rem;
        height: ${theme.pxToRem(height)}rem;
        fill: none;
      `}
      viewBox={`${view.minX} ${view.minY} ${view.width} ${view.height}`}
      focusable="false"
      aria-hidden="true"
      {...otherProps}
    >
      <circle cx="10.5" cy="10.5" r="9.5" stroke="currentColor" strokeWidth="2" />
      <path d="M14 10.5L9 14L9 7L14 10.5Z" fill="currentColor" />
    </svg>
  );
};

PlaySVG.propTypes = {
  height: PropTypes.number,
};

PlaySVG.defaultProps = {
  height: 21,
};
