import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { ServicesList } from '../components/ServicesList';
import { FeatureList } from '../components/FeatureList';

const serviceComponents = {
  Rows: FeatureList,
  Columns: ServicesList,
};

const getServiceComponent = displayMode => {
  return Object.prototype.hasOwnProperty.call(serviceComponents, displayMode)
    ? serviceComponents[displayMode]
    : ServicesList;
};

const getServiceListItems = items => {
  return items.map(item => {
    const {
      row_title: { richText: heading },
      row_list: { richText: itemList },
    } = item;

    return {
      label: RichText.asText(heading),
      items: itemList,
    };
  });
};

const getFeatureListItems = items => {
  return items.map(item => {
    const {
      row_title: { richText: heading },
      row_description: { richText: rowDescription },
      row_list: { richText: itemList },
    } = item;

    return {
      title: RichText.asText(heading),
      text: RichText.asText(rowDescription),
      items: itemList,
    };
  });
};

const getServiceItems = (displayMode, items) => {
  switch (displayMode) {
    case 'Rows':
      return {
        features: getFeatureListItems(items),
      };

    default:
      return {
        services: getServiceListItems(items),
      };
  }
};

export const ServicesBlockSlice = ({ slice }) => {
  const {
    primary: {
      background_colour: bgColour,
      space_below: spaceBelow,
      list_display_mode: displayMode,
      block_title: section,
      intro_text: { richText: introText },
      title,
    },
    items,
  } = slice;
  const text = RichText.asText(introText);
  const componentTheme = bgColour.toLowerCase();
  const serviceItems = getServiceItems(displayMode, items);
  const ServiceComponent = getServiceComponent(displayMode);

  return (
    <ServiceComponent
      header={{ title, section, text }}
      {...{ componentTheme, spaceBelow }}
      {...serviceItems}
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyServicesBlock on PrismicLandingPageDataBodyServicesBlock {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      list_display_mode
      space_below
      background_colour
    }
    items {
      row_title {
        richText
      }
      row_list {
        richText
      }
      row_description {
        richText
      }
    }
  }
  fragment CaseStudyDataBodyServicesBlock on PrismicCaseStudyDataBodyServicesBlock {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      list_display_mode
      space_below
      background_colour
    }
    items {
      row_title {
        richText
      }
      row_list {
        richText
      }
      row_description {
        richText
      }
    }
  }
  fragment NewsPostDataBodyServicesBlock on PrismicNewsPostDataBodyServicesBlock {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      list_display_mode
      space_below
      background_colour
    }
    items {
      row_title {
        richText
      }
      row_list {
        richText
      }
      row_description {
        richText
      }
    }
  }
  fragment HomepageDataBodyServicesBlock on PrismicHomepageDataBodyServicesBlock {
    primary {
      block_title
      title
      intro_text {
        richText
      }
      list_display_mode
      space_below
      background_colour
    }
    items {
      row_title {
        richText
      }
      row_list {
        richText
      }
      row_description {
        richText
      }
    }
  }
`;

ServicesBlockSlice.propTypes = {
  slice: PropTypes.shape({
    slice_type: PropTypes.string,
    primary: PropTypes.shape({
      block_title: PropTypes.string,
      title: PropTypes.string,
      intro_text: PropTypes.shape({
        richText: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
          }),
        ),
      }),
      list_display_mode: PropTypes.oneOf(Object.keys(serviceComponents)),
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        row_title: PropTypes.shape({
          richText: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
            }),
          ),
        }),
        row_description: PropTypes.shape({
          richText: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
            }),
          ),
        }),
        row_list: PropTypes.shape({
          richText: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
            }),
          ),
        }),
      }),
    ),
  }).isRequired,
};
