/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import anime from 'animejs/lib/anime.es';
import PropTypes from 'prop-types';
import { useComponentReveal } from '../hooks/useComponentReveal';

// whole default decoration is 15 dots by 15 dots
// each dot is 4px by 4px when displayed
// 21px between each dot, so a 25px repeating unit.
// hence the whole svg will be a 375px square

// path for a single dot is drawn on a 50 x 50px square viewbox
// the first point on path is 21.875, 50
// repeating unit using this scale is 312.5 x 312.5 (which is 50 / 4 * 25)
// to place the dot in center of repeating unit start point = x + 156.25 - 25, y + 156.25 - 25

const SVG_DOT_WIDTH = 50;
const SVG_DOT_HEIGHT = 50;
const FIRST_POINT_X = 21.875;
const FIRST_POINT_Y = 50;
const SVG_REPEATING_UNIT_WIDTH = 312.5;
const SVG_REPEATING_UNIT_HEIGHT = 312.5;
const CSS_REPEATING_UNIT_WIDTH = 25;
const CSS_REPEATING_UNIT_HEIGHT = 25;

const startPointX = x => FIRST_POINT_X
  + SVG_REPEATING_UNIT_WIDTH / 2
  - SVG_DOT_WIDTH / 2
  + x * SVG_REPEATING_UNIT_WIDTH;
const startPointY = y => FIRST_POINT_Y
  + SVG_REPEATING_UNIT_HEIGHT / 2
  - SVG_DOT_HEIGHT / 2
  + y * SVG_REPEATING_UNIT_HEIGHT;

const dotPath = (x, y) => `M${startPointX(x)},${startPointY(
  y,
)}c13.807,0,28.125,-9.63,28.125,-23.438c0,-13.807,-14.318,-26.562,-28.125,-26.562c-13.807,0,-21.875,12.755,-21.875,26.563c0,13.807,8.068,23.437,21.875,23.437z`;

const dotPaths = (dotsX, dotsY) => {
  const dots = [];
  for (let i = 0; i < dotsX; i++) {
    for (let j = 0; j < dotsY; j++) {
      dots.push(dotPath(i, j));
    }
  }
  return dots;
};

const StyledSvg = styled.svg`
  width: ${props => CSS_REPEATING_UNIT_WIDTH * props.dotsX}px;
  height: ${props => CSS_REPEATING_UNIT_HEIGHT * props.dotsY}px;
  fill: ${props => props.theme.colors.black};
`;

const StyledPath = styled.path`
  opacity: 0;
`;

export const DecorativeDots = ({
  dotsX, dotsY, isVisible, ...otherProps
}) => {
  const { ref, isRevealed } = useComponentReveal();
  const svgRef = useRef();

  const animateDots = () => {
    anime({
      targets: svgRef.current.childNodes,
      opacity: ['0', '.15'],
      duration: 3000,
      delay: anime.stagger(200, { grid: [dotsX, dotsY], from: 'first' }),
      autoplay: true,
    });
  };

  useEffect(() => {
    if (isVisible && isRevealed) {
      animateDots();
    }
  }, [isRevealed]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isVisible) {
    return null;
  }

  return (
    <div
      {...{ ref }}
      {...otherProps}
    >
      <StyledSvg
        ref={svgRef}
        viewBox={`0 0 ${dotsX * SVG_REPEATING_UNIT_WIDTH} ${
          dotsY * SVG_REPEATING_UNIT_HEIGHT
        }`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        aria-hidden="true"
        {...{ dotsX }}
        {...{ dotsY }}
      >
        {dotPaths(dotsX, dotsY).map((dot, i) => (
          <StyledPath key={i} d={dot} />
        ))}
      </StyledSvg>
    </div>
  );
};

DecorativeDots.propTypes = {
  dotsX: PropTypes.number,
  dotsY: PropTypes.number,
  isVisible: PropTypes.bool, // temporary during dev
};

DecorativeDots.defaultProps = {
  dotsX: 15,
  dotsY: 15,
  isVisible: false,
};
