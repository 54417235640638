/** @jsx jsx */
import {
  css, jsx, useTheme,
} from '@emotion/react';
import PropTypes from 'prop-types';

const rotation = {
  right: '180deg',
  left: '0deg',
};

export const ChevronSVG = ({ height, direction, ...otherProps }) => {
  const theme = useTheme();

  // viewbox
  const view = {
    minX: 0,
    minY: 0,
    width: 10, // Viewbox is square so that Chevron isn't cropped when it's rotated
    height: 10,
  };

  /*
    Rotation is applied to path rather than SVG to allow the transform to
    center the SVG to be applied to SVG element, i.e. translate(-50%, -50%)
  */
  return (
    <svg
      css={css`
        pointer-events: none;
        width: ${theme.pxToRem(height * (view.width / view.height))}rem;
        height: ${theme.pxToRem(height)}rem;
        fill: currentColor;
      `}
      viewBox={`${view.minX} ${view.minY} ${view.width} ${view.height}`}
      focusable="false"
      aria-hidden="true"
      {...otherProps}
    >
      <path
        css={{
          transformOrigin: 'center',
          transform: `rotate(${rotation[direction]})`,
        }}
        d="M1.86675,5l4.9-4.9,1.3665,1.3665L4.59976,5,8.13325,8.5335,6.76675,9.9Z"
        fillRule="evenodd"
      />
    </svg>
  );
};

ChevronSVG.propTypes = {
  height: PropTypes.number,
  direction: PropTypes.oneOf(Object.keys(rotation)),
};

ChevronSVG.defaultProps = {
  height: 6,
  direction: Object.keys(rotation)[0],
};
