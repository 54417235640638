/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { VWImage, vwImagePropTypes } from './VWImage';

const flexBasis = {
  1: '100%',
  2: '43.5%',
  3: '30%',
};

export const ImageGrid = ({ images, withShadow, loading }) => {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      {images.map((image, i) => (
        <div
          key={i}
          css={css`
            flex: 0 0 ${flexBasis[images.length] || 'auto'};
          `}
        >
          <VWImage
            {...{ image, loading }}
            css={css`
              ${withShadow ? theme.shadow.image.default : ''}
            `}
          />
        </div>
      ))}
    </div>
  );
};

ImageGrid.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape(vwImagePropTypes)),
  withShadow: PropTypes.bool,
  loading: PropTypes.string.isRequired,
};

ImageGrid.defaultProps = {
  images: [],
  withShadow: false,
};
