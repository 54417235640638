/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { VWImage, vwImagePropTypes } from './VWImage';
import { VWArtDirectedImage } from './VWArtDirectedImage';
import { container } from '../composableStyles/layout';
import { marginBottom } from '../composableStyles/marginBottom';
import { DecorativeDots } from './DecorativeDots';
import { decorationDefaultValue, decorationPropTypes, spaceBelowDefaultValue } from '../utilities/componentPropTypes';
import { TABLET_BP } from '../composableStyles/theme';
import { heroRevealAnimation } from '../composableStyles/heroRevealAnimation';

const {
  imageReveal,
} = heroRevealAnimation;

const decorationStyles = {
  topLeft: css`
    top: -75px;
    left: -55px;
  `,
  bottomLeft: css`
    bottom: -80px;
    left: -90px;
  `,
  topRight: css`
    top: -80px;
    right: -90px;
  `,
  bottomRight: css`
    bottom: -50px;
    right: -130px;
  `,
};

export const BlogHeroImage = ({ image, spaceBelow, decoration }) => {
  const theme = useTheme();

  const main = image;
  const mobile = image && image.thumbnails && image.thumbnails.mobile;

  const images = !!mobile && [
    {
      aspectRatio: `${mobile.gatsbyImageData.width} / ${mobile.gatsbyImageData.height}`,
      image: mobile && mobile.gatsbyImageData,
      media: null,
    },
    {
      aspectRatio: `${main.gatsbyImageData.width} / ${main.gatsbyImageData.height}`,
      image: main && main.gatsbyImageData,
      media: `(min-width: ${TABLET_BP}px)`,
    },
  ];

  return (
    <div
      css={[
        container(theme),
        marginBottom(theme, spaceBelow),
        css`
          position: relative;
        `,
        imageReveal,
      ]}
    >
      { images
        ? <VWArtDirectedImage {...{ images }} loading="eager" />
        : <VWImage image={main} loading="eager" />}
      {decoration !== decorationDefaultValue && (
        <DecorativeDots
          css={css`
            position: absolute;
            z-index: 1;
            ${decorationStyles[decoration] || ''}
            display: none;

            ${theme.bp.tablet} {
              display: block;
            }
          `}
        />
      )}
    </div>
  );
};

BlogHeroImage.propTypes = {
  image: PropTypes.shape(vwImagePropTypes),
  decoration: decorationPropTypes,
  spaceBelow: PropTypes.string,
};

BlogHeroImage.defaultProps = {
  image: null,
  decoration: decorationDefaultValue,
  spaceBelow: spaceBelowDefaultValue,
};
