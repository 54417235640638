/** @jsx jsx */
import { css, jsx, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import {
  contentListCardPropTypes,
} from '../utilities/componentPropTypes';
import { TABLET_BP, WIDE_BP } from '../composableStyles/theme';
import { fluidScale } from '../composableStyles/fluidScale';
import { ContentListTwoRowCard } from './ContentListTwoRowCard';

export const ContentListTwoRow = ({ isStaggered, items }) => {
  const theme = useTheme();

  const staggeredLastCardStyles = isStaggered
    ? css`
    &:last-of-type {
      ${theme.bp.tablet} {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 45%;
      }
    }
  `
    : null;

  return (
    <ul
      css={css`
        ${theme.bp.tablet} {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          ${fluidScale({
        minPx: 56,
        maxPx: 110,
        minMqPx: TABLET_BP,
        maxMqPx: WIDE_BP,
        cssPropertyName: 'row-gap',
      })}
        }
        
        ${theme.bp.wide} {
          row-gap: ${110 / 18}em;
        }
      `}
    >
      {items.map(
        (cardProps, i) => (
          <ContentListTwoRowCard
            key={i}
            css={[
              staggeredLastCardStyles,
            ]}
            {...cardProps}
          />
        ),
      )}
    </ul>
  );
};

ContentListTwoRow.propTypes = {
  isStaggered: PropTypes.bool,
  items: PropTypes.arrayOf(contentListCardPropTypes),
};

ContentListTwoRow.defaultProps = {
  isStaggered: false,
  items: [],
};
