import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import { FiftyFiftyPanels } from '../components/FiftyFiftyPanels';
import { linkResolver } from '../utilities/linkResolver';
import { mapDecorationValues } from '../utilities/mapDecorationValues';
import { mapBackgroundValues } from '../utilities/mapBackgroundValues';

export const FiftyFiftyPanelsSlice = ({ slice }) => {
  const {
    panels_display_mode: display,
    first_panel_image_alignment: imageAlignment,
    background_colour: backgroundColor,
    space_below: spaceBelow,
  } = slice.primary;

  const isAlternating = display === 'Alternating';
  const imgStartsOnRight = imageAlignment === 'Right';
  const items = slice.items.map(
    ({
      panel_image: image,
      panel_title: title,
      panel_description: { richText: description },
      panel_button_label: label,
      panel_button_link: { url },
      dotted_background: decoration,
    }) => ({
      title,
      image,
      text: RichText.render(description, linkResolver),
      button: {
        label,
        url,
      },
      decoration: mapDecorationValues(decoration),
    }),
  );

  return (
    <FiftyFiftyPanels
      componentTheme={mapBackgroundValues(backgroundColor)}
      {...{
        items, isAlternating, imgStartsOnRight, spaceBelow,
      }}
    />
  );
};

export const query = graphql`
  fragment LandingPageDataBodyFiftyFiftyPanels on PrismicLandingPageDataBodyFiftyFiftyPanels {
    primary {
      panels_display_mode
      first_panel_image_alignment
      space_below
      background_colour
    }
    items {
      panel_image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
      panel_title
      panel_description {
        richText
      }
      panel_button_label
      panel_button_link {
        url
      }
      dotted_background
    }
  }
  fragment CaseStudyDataBodyFiftyFiftyPanels on PrismicCaseStudyDataBodyFiftyFiftyPanels {
    primary {
      panels_display_mode
      first_panel_image_alignment
      space_below
      background_colour
    }
    items {
      panel_image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
      panel_title
      panel_description {
        richText
      }
      panel_button_label
      panel_button_link {
        url
      }
      dotted_background
    }
  }
  fragment NewsPostDataBodyFiftyFiftyPanels on PrismicNewsPostDataBodyFiftyFiftyPanels {
    primary {
      panels_display_mode
      first_panel_image_alignment
      space_below
      background_colour
    }
    items {
      panel_image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
      panel_title
      panel_description {
        richText
      }
      panel_button_label
      panel_button_link {
        url
      }
      dotted_background
    }
  }
  fragment HomepageDataBodyFiftyFiftyPanels on PrismicHomepageDataBodyFiftyFiftyPanels {
    primary {
      panels_display_mode
      first_panel_image_alignment
      space_below
      background_colour
    }
    items {
      panel_image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
        alt
      }
      panel_title
      panel_description {
        richText
      }
      panel_button_label
      panel_button_link {
        url
      }
      dotted_background
    }
  }
`;

FiftyFiftyPanelsSlice.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.shape({
      panels_display_mode: PropTypes.string,
      first_panel_image_alignment: PropTypes.string,
      space_below: PropTypes.string,
      background_colour: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
