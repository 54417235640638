const REM = 16;

const pxToRem = px => px / REM;

const colors = {
  blackDark: '#1d1d1d',
  black: '#272528',
  white: '#fff',
  greyDark: '#404040',
  grey: '#9b9890',
  greyLight: '#565454',
  greyLighter: '#bfbdb7',
  greyMurray: '#d7d6d2', // form field borders
  greyLightest: '#f6f6f6',
  pink: '#f37169',
  pinkDark: '#f0544c',
};

export const mobileDesignWidthPx = 375;
export const desktopDesignWidthPx = 1440;

export const MOBILE_BP = mobileDesignWidthPx;
export const TABLET_BP = 768;
export const DESKTOP_BP = 1024;
export const WIDE_BP = desktopDesignWidthPx;
export const WIDEST_BP = 2400;

// BPs are defined in em
const mqMin = bp => `@media (min-width: ${pxToRem(bp)}em)`;
const mqMax = bp => `@media (max-width: ${pxToRem(bp)}em)`;

export const gridPX = {
  max_col: 24,
  col_max_width: 38,
  gutter_width: 19,
  padding: {
    mobile: 15,
    desktop: 50,
  },
};

const maxWidthColsPx = col => col * gridPX.col_max_width + (col - 1) * gridPX.gutter_width;
const maxWidth = (col = 24) => `calc(${pxToRem(maxWidthColsPx(col))}rem + (2 * var(--container-grid-padding)))`;

const SPACER_PX = 20;
const spacer = (num = 1) => `${num * pxToRem(SPACER_PX)}rem`;

const scaledVerticalSpacing = spacingPX => `calc(${spacingPX} * var(--vertical-space-unit))`;
const scaledVerticalSpacingM = spacingPX => `calc(${spacingPX / 0.6} * var(--vertical-space-unit))`;

// expose theme variables as rem where appropriate
export const theme = {
  colors,
  layout: {
    maxWidth,
    maxWidthCols: col => `${pxToRem(maxWidthColsPx(col))}rem`,
    maxWidthColsPx,
    gridPadding: {
      mobile: `${pxToRem(gridPX.padding.mobile)}rem`,
      desktop: `${pxToRem(gridPX.padding.desktop)}rem`,
    },
  },
  bp: {
    tablet: mqMin(TABLET_BP),
    desktop: mqMin(DESKTOP_BP),
    wide: mqMin(WIDE_BP),
    widest: mqMin(WIDEST_BP),
    min: mqMin,
    dangerousMax: mqMax,
  },
  zIndex: {
    header: {
      banner: 100,
      dialog: 1000,
    },
  },
  spacer,
  font: {
    family: {
      base: 'Chivo, sans-serif',
      alternate: 'Neometric, sans-serif',
    },
    weight: {
      base: '400',
      bold: '600',
      extraBold: '700',
      black: '800',
    },
    size: {
      base: `${pxToRem(18)}rem`,
    },
    lineHeight: {
      base: `${pxToRem(32)}rem`,
    },
    letterSpacing: {
      base: '0.2px',
    },
  },
  transitions: {
    link: '150ms',
  },
  pxToRem,
  shadow: {
    image: {
      default: 'box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);',
    },
  },
  scaledVerticalSpacing,
  scaledVerticalSpacingM,
};
